const azureInstance = `https://login.microsoftonline.com`;
const adminCallbackUrl = 'admin';
const studentCallbackUrl = 'student';


export const commonMsalConfig = {
  azureInstance,
  adminCallbackUrl,
  studentCallbackUrl,
};

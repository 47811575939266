import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  DTContact,
  DTCustomValidators,
  DegreeTransferService,
} from 'src/app/services/degree-transfer.service';

@Component({
  selector: 'app-dt-institution-contacts',
  templateUrl: './dt-institution-contacts.component.html',
  styleUrls: ['./dt-institution-contacts.component.scss'],
})
export class DtInstitutionContactsComponent implements OnInit {
  public elseTemplateCDiv: any;
  public TemplateCDiv: any;
  formGroup: FormGroup;
  action: string;
  local_data: any;
  instId: any;
  userName = sessionStorage.getItem('user');

  constructor(
    public dialogRef: MatDialogRef<DtInstitutionContactsComponent>,
    private cdr: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DTContact,
    private router: Router,
    private _formBuilder: FormBuilder,
    private customValidators: DTCustomValidators,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private degreeTransferService: DegreeTransferService
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
    this.instId = this.local_data.instId;
    this.formGroup = this._formBuilder.group({
      contact_title: [null, Validators.compose([])],
      phone_number: [
        null,
        {
          validators: [
       //     Validators.pattern('^[0-9]*$'),
            Validators.maxLength(30),
          ],
          updateOn: 'change',
        },
      ],
      first_name: [null, Validators.compose([])],
      last_name: [null, Validators.compose([])],
      department: [null, Validators.compose([])],
      email: [null, { validators: [Validators.email], updateOn: 'change' }],
      job_title: [null, Validators.compose([])],
    });
  }

  ngOnInit() {}

  ngAFterViewInit() {
    this.cdr.detectChanges();
  }

  doAction() {
    const contactTmp = {
      contact_id: this.local_data.contact_id,
      title: this.local_data.title,
      first_name: this.local_data.first_name,
      last_name: this.local_data.last_name,
      department: this.local_data.department,
      phone_number: this.local_data.phone_number,
      email: this.local_data.email,
      job_title: this.local_data.job_title,
      institution_id: this.instId,
      modified_by: this.userName,
      modified_date: new Date(),
      contact_publish: this.local_data.contact_publish? true: false,
    };

    if (this.action == 'Delete') {
    } else {
      // try to add a new contact
      if (!this.local_data.contact_id) {
        console.log(JSON.stringify(contactTmp));
      } else {
        // update contact
      }
    }
    setTimeout(() => {
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }, 0);
  }
  delete() {
    this.dialogRef.close({ event: 'Delete' });
  }


  setPublish(flag: boolean) {
    this.local_data.contact_publish = flag;
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}

<!-- <div #NoaccessDiv class="div-padding-left" *ngIf="!isAdmin">You don't have admin access to this portal</div> -->
<div *ngIf="userName" class="div-padding-left">
  <div id="block_container_inst_search">
    <div id="bloc1_search" class="col-md-8">
      <h3>PARTNER INSTITUTIONS</h3>
    </div>
    <div id="bloc2_search" class="col-md-4">
      <!-- <button mat-button (click)="openDialog('Add',{})" mat-flat-button color="warn"> + Add New</button> -->
      <button mat-button (click)="addInstition()" mat-flat-button color="warn">
        + New Institution
      </button>
    </div>
  </div>
  <div id="block_container_inst_search">
    <div id="bloc1_search" class="col-md-6">
      <mat-tab-group (selectedIndexChange)="selectedIndexChange($event)">
        <mat-tab label="All"> </mat-tab>
        <mat-tab label="Active"> </mat-tab>
        <mat-tab label="Archived"> </mat-tab>
      </mat-tab-group>
    </div>
    <div id="bloc1_search" class="col-md-2">
      <button mat-stroked-button (click)="addInstitionList()" color="accent">
        Keep table view
      </button>
    </div>
    <div id="bloc2_search" class="col-md-4">
      <mat-form-field>
        <input
          #searchValue
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search"
        />
      </mat-form-field>
    </div>
  </div>
  <!-- <div>
      <mat-chip-list aria-label="selection">
        <mat-chip>All</mat-chip>
        <mat-chip color="primary" selected>Active</mat-chip>
        <mat-chip color="accent" >Archived</mat-chip>
    </mat-chip-list> -->

  <!-- </div> -->

  <div class="list-container mat-elevation-z8">
    <mat-table #table [dataSource]="dataSource" matSort>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Institution Name
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Status
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.status == "0" ? "Inactive" : "Active" }}
        </mat-cell>
      </ng-container>

      <!-- <ng-container cdkColumnDef="edit">
            <mat-header-cell *matHeaderCellDef> Edit </mat-header-cell>
            <mat-cell *cdkCellDef="let element" >(click)="openDialog('Update',element)"-->
      <!-- <button mat-fab color="primary" aria-label=" icon button with a edit icon" (click)="updateInstitution(element)">
                    <mat-icon>edit</mat-icon>
                  </button>
            </mat-cell>
        </ng-container>  -->

      <ng-container cdkColumnDef="archive">
        <mat-header-cell *matHeaderCellDef> Archive </mat-header-cell>
        <mat-cell *cdkCellDef="let element">
          <!-- <button   aria-label=" icon button with a Archive icon" >  -->
          <mat-icon
            (click)="openDialog('Archive', element, $event)"
            class="cursor_point"
            >archive</mat-icon
          >

          <!-- </button>  -->
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="delete">
        <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
        <mat-cell *cdkCellDef="let element">
          <!-- <button  aria-label=" icon button with a delete icon" >  -->
          <mat-icon
            (click)="openDialog('Delete', element, $event)"
            class="cursor_point"
            >delete_outline</mat-icon
          >
          <!-- <span class="material-icons">
                    delete_outline
                    </span> -->
          <!-- </button>  -->
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="updateInstitution($event, row)"
      ></mat-row>
    </mat-table>

    <mat-paginator
      [length]="dataSource.data.length"
      [pageSize]="5"
      [pageSizeOptions]="[5, 10, 25, 100]"
    ></mat-paginator>
  </div>
</div>

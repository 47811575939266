<div class="div-padding-left">
  <div
    #TemplateDiv
    *ngIf="action != 'Delete' && action != 'Archive'; else elseTemplateDiv"
  >
    <div id="block_container">
      <div id="bloc1" class="col-md-4">
        <span class="mat-dialog-title"
          ><mat-icon (click)="cancelAction()" class="cursor_point"
            >keyboard_backspace</mat-icon
          >
          &nbsp; &nbsp;{{ local_data && local_data.name }}</span
        >
      </div>
      <div id="bloc1" class="col-md-2"></div>
      <div id="block_container">
        <div
          id="bloc1"
          class="col-md-3"
          *ngIf="!(local_data && local_data.status == '0')"
        >
          <button
            mat-button
            (click)="openDialogDelete_Archive('Archive', $event)"
            [disabled]="local_data && local_data.status == '0'"
            color="archive"
          >
            <mat-icon color="archive">archive</mat-icon> Archive
          </button>
          <!-- <span class="mat-archive cursor_point" (click)="openDialogDelete_Archive('Archive',$event)"><mat-icon  color="archive" >archive</mat-icon> Archive</span> -->
        </div>
        <div
          id="bloc1"
          class="col-md-3"
          *ngIf="local_data && local_data.status == '0'"
        >
          <button
            mat-button
            (click)="openDialogDelete_Archive('reactivate', $event)"
            color="archive"
          >
            <mat-icon color="archive">archive</mat-icon> Re-activate
          </button>
          <!-- <span class="mat-archive cursor_point" (click)="openDialogDelete_Archive('Archive',$event)"><mat-icon  color="archive" >archive</mat-icon> Archive</span> -->
        </div>
        <div id="bloc1" class="col-md-3">
          <!-- <span class="mat-archive cursor_point" (click)="openDialogDelete_Archive('Delete',$event)"><mat-icon  color="archive">delete_outline</mat-icon> Delete</span> -->
          <button
            mat-button
            (click)="openDialogDelete_Archive('Delete', $event)"
            color="archive"
          >
            <mat-icon color="archive">delete_outline</mat-icon> Delete
          </button>
        </div>
        <div id="bloc1" class="col-md-3">
          <button mat-stroked-button (click)="cancelAction()" color="accent">
            Cancel
          </button>
        </div>
        <div id="bloc1" class="col-md-3">
          <button
            mat-button
            (click)="doAction()"
            [disabled]="!formGroup.valid"
            mat-flat-button
            color="warn"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="formGroup" class="institution-form">
    <div
      id="block_container_contact"
      *ngIf="action != 'Delete' && action != 'Archive'"
    >
      <div id="bloc_contact_1" class="col-md-3">
        <h1 mat-dialog-title><strong> Institution Details</strong></h1>
      </div>
    </div>

    <!-- <mat-form-field class="example-full-width" *ngIf ="showInstituionDDl" {{action}}>
          <mat-label>Select Institution</mat-label>
          <input type="text"
             placeholder="Pick one"
             aria-label="Select Institution"
             matInput
             [formControl]="institutionControl"
             [(ngModel)]="selectedOpt"
             
             [matAutocomplete]="auto">
          <mat-autocomplete  #auto="matAutocomplete" (optionSelected)="updateInstitutionSelection($event.option.value)" >
              <mat-option *ngFor="let option of options" [value]="option">
                  {{option}}
              </mat-option>
          </mat-autocomplete>
          
      </mat-form-field> -->

    <div>
      <div *ngIf="action != 'Delete' && action != 'Archive'; else elseTemplate">
        <mat-form-field appearance="fill" class="col-md-6">
          <mat-label>Institution Name</mat-label>
          <!-- <mat-hint align="begin" >Institution Name</mat-hint> -->
          <input
            [(ngModel)]="local_data.name"
            name="name"
            matInput
            placeholder="{{ local_data?.name }}"
            formControlName="name"
            [required]="true"
          />
          <mat-error *ngIf="formGroup.get('name')?.hasError('required')">
            name is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="formGroup.get('name')?.hasError('invalidName')">
            Special characters not allowed!
          </mat-error>
        </mat-form-field>

        <!--(selectionChange)="changeCountry($event)"-->
        <mat-form-field appearance="fill" class="col-md-3">
          <mat-label>Country</mat-label>
          <!-- <mat-hint align="begin">Country</mat-hint> -->
          <mat-select
            name="country"
            [(ngModel)]="local_data.country"
            formControlName="country"
          >
            <mat-option
              *ngFor="let c of contries"
              [value]="c.Country"
              (click)="CountrySelectChange()"
            >
              {{ c.Descr }}
            </mat-option>
          </mat-select>
          <!-- <input name='country' matInput placeholder="{{local_data?.country}}" [(ngModel)]="local_data.country" formControlName="country"> -->
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-md-3">
          <mat-label>Province / State</mat-label>
          <!-- <mat-hint align="begin">Province / State</mat-hint> -->
          <mat-select
            name="province_state"
            [(ngModel)]="local_data.province_state"
            formControlName="province_state"
          >
            <mat-option *ngFor="let p of provinces" [value]="p.State">
              {{ p.Descr }}
            </mat-option>
          </mat-select>
          <!-- <input name='province_state' matInput placeholder="{{local_data?.province_state}}" [(ngModel)]="local_data.province_state" formControlName="province_state"> -->
        </mat-form-field>
      </div>
      <div style="text-align: center">
        <ng-template #elseTemplate>
          Sure to {{ action }} <b>{{ local_data.name }}</b
          >?
        </ng-template>
      </div>

      <!-- contacts section -->
      <div *ngIf="action != 'Delete' && action != 'Archive'">
        <!-- <p>&nbsp; </p>-->
        <div id="block_container_contact">
          <div id="bloc_contact_1" class="col-md-2">
            <h1 mat-dialog-title><strong> Contacts </strong></h1>
          </div>
          <div id="bloc_contact_2" class="col-md-2">
            <button
              mat-button
              (click)="openDialog('Add', { action: 'Add' }, $event)"
              mat-flat-button
              color="warn"
            >
              + New Contact
            </button>
          </div>
          <div id="bloc_contact_2" class="col-md-6"></div>
          <div id="bloc_contact_2" class="col-md-2">
            <button
              mat-button
              (click)="openDialog_Email($event)"
              color="archive"
            >
              <mat-icon color="archive">email</mat-icon>Email
            </button>
          </div>
        </div>

        <!-- <div class = "right-align"> -->
        <!-- </div> -->

        <div class="list-container mat-elevation-z8">
          <mat-table
            #tableContacts
            #matSortC="matSort"
            [dataSource]="dataSource"
            matSort
          >
            <!-- Contact Name Column -->
            <ng-container matColumnDef="contact_first_name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                First Name
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.contact_first_name }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="contact_last_name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Last Name
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.contact_last_name }}
              </mat-cell>
            </ng-container>

            <!-- Contact Email Column -->
            <ng-container matColumnDef="contact_email">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Email
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.contact_email }}
              </mat-cell>
            </ng-container>

            <!-- Contact Phone Column -->
            <ng-container matColumnDef="contact_phone">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Phone
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.contact_phone }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="contact_phone_ext">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Extension</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                {{ element.contact_phone_ext }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="contact_notes">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Notes
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.contact_notes }}
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="delete">
              <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
              <mat-cell *cdkCellDef="let element">
                <!-- <button mat-fab color="accent" aria-label=" icon button with a delete icon" > -->
                <mat-icon
                  (click)="openDialog('Delete', element, $event)"
                  class="cursor_point"
                  >delete_outline</mat-icon
                >
                <!-- </button> -->
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              (click)="openDialog('Update', row, $event)"
            ></mat-row>
          </mat-table>
        </div>
        <!-- end of contacts section-->
      </div>

      <!-- program section -->
      <div *ngIf="action != 'Delete' && action != 'Archive'">
        <div id="block_container_contact">
          <div id="bloc_contact_1" class="col-md-2">
            <h1 mat-dialog-title><strong> Programs </strong></h1>
          </div>
          <div id="bloc_contact_2" class="col-md-2">
            <button
              mat-button
              (click)="openDialogProgram('Add', { action: 'Add' }, $event)"
              mat-flat-button
              color="warn"
            >
              + New Program
            </button>
          </div>
        </div>

        <div class="list-container mat-elevation-z8">
          <mat-table
            #table
            [dataSource]="dataSourceP"
            matSort
          >
            <!-- Contact Name Column -->
            <ng-container matColumnDef="program_name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Program Name
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.program_name }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="program_code">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Program Code
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.program_code }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="mcu_code">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                MCU Code
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.mcu_code }}
              </mat-cell>
            </ng-container>

            <!-- Contact Email Column -->
            <ng-container matColumnDef="program_cred">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Credential Level
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.program_cred }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="program_status">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Status
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.program_status == "1" ? "Active" : "Inactive" }}
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="archive">
              <mat-header-cell *matHeaderCellDef> Archive </mat-header-cell>
              <mat-cell *cdkCellDef="let element">
                <mat-icon
                  (click)="openDialogProgram('Archive', element, $event)"
                  class="cursor_point"
                  >archive</mat-icon
                >
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="delete">
              <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
              <mat-cell *cdkCellDef="let element">
                <mat-icon (click)="openDialogProgram('Delete', element, $event)"
                  >delete_outline</mat-icon
                >
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsP"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumnsP"
              (click)="openDialogProgram('Update', row, $event)"
            ></mat-row>
          </mat-table>
          <mat-paginator
            #paginator
            [length]="dataSourceP.data.length"
            [pageSize]="5"
            [pageSizeOptions]="[5, 10, 25, 100]"
          ></mat-paginator>
        </div>
        <!-- end of program section-->
      </div>

      <!-- Notes section -->
      <div *ngIf="action != 'Delete' && action != 'Archive'">
        <!-- <p>&nbsp; </p>-->
        <div id="block_container_contact">
          <div id="bloc_contact_1" class="col-md-1">
            <h1 mat-dialog-title><strong> Notes </strong></h1>
          </div>
          <div id="bloc_contact_2" class="col-md-1"></div>
        </div>
        <div id="block_container_contactDetails">
          <div id="bloc_contact_1" class="col-md-12">
            <mat-form-field appearance="fill">
              <mat-label>Internal Notes </mat-label>
              <input
                name="notes"
                matInput
                placeholder="{{ local_data?.notes }}"
                [(ngModel)]="local_data.notes"
                formControlName="notes"
              />
              <mat-error
                *ngIf="formGroup.get('notes')?.hasError('invalidName')"
              >
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <p></p>
        <p></p>
        <p></p>
        <!-- <div class="lastMod" *ngIf="action != 'Delete' && action != 'Archive'"> Create by:  {{local_data?.modified_date | date: 'shortDate' }}</div> -->
        <div class="lastMod" *ngIf="action != 'Delete' && action != 'Archive'">
          Last modified by: {{ local_data?.modified_by }} on
          {{ lastModifiedDate | date }}
        </div>
        <div class="lastMod" *ngIf="action != 'Delete' && action != 'Archive'">
          Status: {{ local_data?.status == "0" ? "Inactive" : "Active" }}
        </div>
        <div class="lastMod" *ngIf="action != 'Delete' && action != 'Archive'">
          Institution ID: {{ local_data?.id }}
        </div>
        <p></p>
      </div>

      <div
        mat-dialog-actions
        class="right-align-btn"
        #elseTemplateDiv
        *ngIf="action == 'Delete' || action == 'Archive'; else TemplateDiv"
      >
        <button mat-button (click)="doAction()" mat-flat-button color="warn">
          {{ action }}
        </button>
        <button mat-stroked-button (click)="closeDialog()" color="accent">
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>

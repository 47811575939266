<form [formGroup]="formGroup">
  <h1 mat-dialog-title>
    <strong> {{ action }} Degree Transfer Programs</strong>
  </h1>
  <div *ngIf="action != 'Delete'; else elseTemplateC">
    <div
      mat-dialog-actions
      id="block_container_contactDetails3"
      #TemplateCDiv
      *ngIf="action != 'Delete'; else elseTemplateCDiv"
    >
      <div id="bloc_contact_detail_3" class="col-md-2">
        <button
          mat-button
          (click)="delete()"
          [disabled]="action != 'Update'"
          color="archive"
        >
          <mat-icon color="archive">delete_outline</mat-icon> Delete
        </button>
      </div>
      <div id="bloc_contact_detail_3" class="col-md-2">
        <button mat-stroked-button (click)="closeDialog()" color="accent">
          Cancel
        </button>
      </div>
      <div id="bloc_contact_detail_3" class="col-md-2">
        <button
          mat-button
          (click)="doAction()"
          [disabled]="!formGroup.valid"
          mat-flat-button
          color="warn"
        >
          Save
        </button>
      </div>
    </div>

    <div id="block_container_contact">
      <div id="bloc_contact_1" class="col-md-8">
        <mat-form-field appearance="fill">
          <mat-label>Program Code </mat-label>
          <input
            name="program_code"
            matInput
            placeholder="{{ local_data?.program_code }}"
            [(ngModel)]="local_data.program_code"
            formControlName="program_code"
            [required]="false"
          />
        </mat-form-field>
      </div>
    </div>
    <div id="block_container_contact">
      <div id="bloc_contact_1" class="col-md-8">
        <mat-form-field appearance="fill">
          <mat-label>Program Name </mat-label>
          <input
            name="program_name"
            matInput
            placeholder="{{ local_data?.program_name }}"
            [(ngModel)]="local_data.program_name"
            formControlName="program_name"
            [required]="false"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div style="text-align: center">
    <ng-template #elseTemplateC>
      Sure to {{ action }}
      <b>{{ local_data.program_code }} - {{ local_data.program_name }}</b
      >?
    </ng-template>
  </div>

  <div
    mat-dialog-actions
    class="d-flex justify-content-center"
    #elseTemplateCDiv
    *ngIf="action == 'Delete'; else TemplateCDiv"
  >
    <button mat-button (click)="doAction()" mat-flat-button color="warn">
      {{ action }}
    </button>
    &nbsp; &nbsp;
    <button mat-stroked-button (click)="closeDialog()" color="accent">
      Cancel
    </button>
  </div>
</form>

import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from './auth_generic/auth.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { environment } from 'src/environments/environment';
import { InstitutionService } from './services/institution-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Seneca Academic Pathways';
  userName = sessionStorage.getItem('user'); // localStorage
  userLogin = sessionStorage.getItem('userLogin');
  userEmail = '';
  isIframe = false;
  loginDisplay = false;

  constructor(
    private institutionService: InstitutionService,
    private authService: AuthService,
    private bnIdle: BnNgIdleService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    if (!this.userName || this.userName.length < 1) {
      if (!this.userLogin) {
        this.authService.login();
      }
      sessionStorage.setItem('userLogin', 'true');
    }
  }

  ngOnInit(): void {
    this.isIframe = false;
    this.loginDisplay = false;
    this.bnIdle
      .startWatching(environment.sessionExpiry)
      .subscribe((isTimedOut: boolean) => {
        if (isTimedOut) {
          if (window.confirm('session expired, would you like to logout?')) {
            this.logout();
          }
        }
      });
    this.msalBroadcastService.msalSubject$.subscribe((event: EventMessage) => {
      const authenticationResult = event.payload as AuthenticationResult;
      if (authenticationResult) {
        this.userName = authenticationResult.account.idTokenClaims?.name
          ? authenticationResult.account.idTokenClaims.name
          : '';
        this.userEmail = authenticationResult.account.idTokenClaims
          ?.preferred_username
          ? authenticationResult.account.idTokenClaims.preferred_username
          : '';
      }
    });
    this.institutionService.getListAdminAccess().subscribe((res) => {
      //console.log(res);
      if (res) {
        const persons = res.filter((x) => x.loginId === this.userEmail);
        //console.log(persons);
        if (persons.length) {
          if (this.userName) {
            sessionStorage.setItem('user', this.userName);
          }
        } else {
          this.authService.login();
        }
      }
    });
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
    sessionStorage.removeItem('user');
  }
}

import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, AuthenticationResult } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class HeaderService implements HttpInterceptor {
  private token: string | null = null;
  authService: any;
  constructor(private msalBroadcastService: MsalBroadcastService) {}

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$.subscribe((event: EventMessage) => {
      const authenticationResult = event.payload as AuthenticationResult;
      this.token = authenticationResult.accessToken;
      console.log(this.token);
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const headerSettings: { [name: string]: string | string[] } = {};

    try {
      // assign token to Authorization header
      if (this.token) {
        headerSettings['Authorization'] = 'Bearer ' + this.token;
      }
    } catch (ex) {
      // just to prevent an exception, when there is no token, as we do have some open API calls
    }

    headerSettings[environment.apiSubscriptionKeyName] =
      environment.apiSubscriptionKey;

    const newRequest = req.clone({
      setHeaders: headerSettings,
    });

    return next.handle(newRequest).pipe(
      tap(
        (succ) => {} //can implement follow up actions or debugging here
        //err => console.log(err)
      )
    );
  }
}

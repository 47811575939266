<form [formGroup]="formGroup">
  <div id="block_container">
    <div id="bloc1" class="col-md-4">
      <h1 mat-dialog-title><strong> Pathway Details</strong></h1>
    </div>
    <div id="bloc1" class="col-md-4"></div>
    <div id="block_container">
      <div id="bloc1" class="col-md-6">
        <button mat-stroked-button (click)="closeDialog()" color="accent">
          Cancel
        </button>
      </div>
      <div id="bloc1" class="col-md-6">
        <button mat-button (click)="doAction()" mat-flat-button color="warn">
          Save
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="col-md-12 pathway_description">
      <mat-label>Eligibility for the Pathway </mat-label>
      <angular-editor
        [(ngModel)]="local_data.eligibility"
        formControlName="eligibility"
        [config]="editorConfig"
      ></angular-editor>
    </div>     
  </div>
  <br/>
  <div class="col-md-12">
    <div class="col-md-12 pathway_description">
      <mat-label>Transfer credit to be granted  </mat-label>
      <angular-editor
        [(ngModel)]="local_data.transfer_credit"
        formControlName="transfer_credit"
        [config]="editorConfig"
      ></angular-editor>
    </div> 
  </div>
  <br/>
  <div class="col-md-12">
    <div class="col-md-12 pathway_description">
      <mat-label>Time to complete credential  </mat-label>
      <angular-editor
        [(ngModel)]="local_data.time_to_complete"
        formControlName="time_to_complete"
        [config]="editorConfig"
      ></angular-editor>
    </div> 
  </div>
  <br/>
  <div class="col-md-12">
    <div class="col-md-12 pathway_description">
      <mat-label>Amount of credit to be completed at Seneca  </mat-label>
      <angular-editor
        [(ngModel)]="local_data.seneca_credits_to_complete"
        formControlName="seneca_credits_to_complete"
        [config]="editorConfig"
      ></angular-editor>
    </div> 
  </div>
  <br/>
  <div class="col-md-12">
    <div class="col-md-12 pathway_description">
      <mat-label>How do I apply?</mat-label>
      <angular-editor
        [(ngModel)]="local_data.how_to_apply"
        formControlName="how_to_apply"
        [config]="editorConfig"
      ></angular-editor>
    </div> 
  </div>
  <br/>
  <div class="col-md-12">
    <div class="col-md-12 pathway_description">
      <mat-label>Additional Information</mat-label>
      <angular-editor
        [(ngModel)]="local_data.additionalInfo"
        formControlName="additionalInfo"
        [config]="editorConfig"
      ></angular-editor>
    </div> 
  </div>
  <!-- <div class="lastMod" >Last modified by:</div> -->
  <div class="lastMod">
    Last modified by: {{ local_data?.modified_by }} on
    {{ local_data.modified_date | date : "shortDate" }}
  </div>
</form>

<div *ngIf="data.fileType != 'pdf'; else pdfDiv">
  <p>File ID: {{ data.fileId }}, File Type: {{ data.fileType }}</p>
  <img [src]="data.fileContent" height="65%" />
</div>
<ng-template #pdfDiv>
  <div class="pdf-container" height="65%">
    <ngx-extended-pdf-viewer
      [src]="data.fileContentBase64"
      backgroundColor="#ffffff"
      [height]="'90vh'"
      [useBrowserLocale]="true"
      [handTool]="false"
      [showHandToolButton]="true"
      [(scrollMode)]="scrollMode"
    ></ngx-extended-pdf-viewer>
  </div>
</ng-template>

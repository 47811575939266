<!-- <div #NoaccessDiv class="div-padding-left" *ngIf="!isAdmin">You don't have admin access to this portal</div> -->
<div *ngIf="userName" class="div-padding-left">
  <div id="block_container_inst_search">
    <div id="bloc1_search" class="col-md-8">
      <h3>SENECA PATHWAYS</h3>
    </div>

    <div id="block_container_inst_search" class="col-md-4">
      <div id="bloc2_search" class="col-md-4">
        <button mat-stroked-button (click)="reportToExcel()" color="accent">
          Report
        </button>
      </div>
      <div id="bloc2_search" class="col-md-8">
        <button mat-button (click)="addPathway()" mat-flat-button color="warn">
          + New Pathway
        </button>
      </div>
    </div>
  </div>
  <div id="block_container_inst_search">
    <div id="bloc1_search" class="col-md-6">
      <mat-tab-group (selectedIndexChange)="selectedIndexChange($event)">
        <mat-tab label="All"> </mat-tab>
        <mat-tab label="Active"> </mat-tab>
        <mat-tab label="Archived"> </mat-tab>
      </mat-tab-group>
    </div>
    <div id="bloc1_search" class="col-md-2">
      <button mat-stroked-button (click)="addPathwayList()" color="accent">
        Keep table view
      </button>
    </div>
    <div id="bloc2_search" class="col-md-4">
      <mat-form-field>
        <input
        #searchValue
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="list-container mat-elevation-z8">
    <mat-table #tablePathways [dataSource]="dataSource" matSort>
      <!-- Program Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Program
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
      </ng-container>
      <!-- title Column -->
      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Pathway Title
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.title }} </mat-cell>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Status
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.status == "0" ? "Inactive" : "Active" }}
        </mat-cell>
      </ng-container>

      <!-- Inbound Column -->
      <ng-container matColumnDef="credits_granted">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Total Inbound
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.total_inbound }}
        </mat-cell>
      </ng-container>
      <!-- Visible Column -->
      <ng-container matColumnDef="isVisible">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Visible
        </mat-header-cell>
        <mat-cell *matCellDef="let element"
          ><mat-icon color="circle" *ngIf="element.isVisible == '1'"
            >circle</mat-icon
          ><mat-icon color="lens" *ngIf="element.isVisible != '1'"
            >lens</mat-icon
          >
          {{ element.isVisible == "1" ? "Y" : "N" }}
        </mat-cell>
      </ng-container>
      <!-- Publish Column -->
      <ng-container matColumnDef="isPublished">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Publish
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon color="brightness_1" *ngIf="element.isPublished == '1'"
            >brightness_1</mat-icon
          ><mat-icon color="lens" *ngIf="element.isPublished != '1'"
            >lens</mat-icon
          >{{ element.isPublished == "1" ? "Y" : "N" }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="updatePathway($event, row)"
      ></mat-row>
    </mat-table>

    <mat-paginator
      [length]="dataSource.data.length"
      [pageSize]="5"
      [pageSizeOptions]="[5, 10, 25, 100]"
    ></mat-paginator>
  </div>
</div>

<form [formGroup]="formGroup" class="institution-form">
  <h1 mat-dialog-title><strong>Email Institution</strong></h1>
  <!-- <mat-dialog-content class="mat-typography">	 -->

  <div>
    <div id="block_container_contactDetails">
      <div id="bloc_contact_detail_1" class="col-md-6"></div>
      <div id="bloc_contact_detail_1" class="col-md-6 right-align">
        <mat-checkbox
          name="From_Bcc"
          [checked]="local_data?.From_Bcc"
          (change)="setFromBccChanges($event.checked)"
          >Include FROM on BCC
        </mat-checkbox>
      </div>
    </div>
    <div
      class="col-md-12"
      [hidden]="
        local_data.From_Bcc == '1' || local_data.From_Bcc == 'undefined'
      "
    >
      <mat-form-field appearance="fill">
        <mat-label>From </mat-label>
        <input
          name="email_from"
          matInput
          placeholder="{{ local_data?.email_from }}"
          [(ngModel)]="local_data.email_from"
          formControlName="email_from"
          readonly
          [required]="true"
        />
        <mat-error *ngIf="formGroup.get('email_from')?.hasError('required')">
          email from is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div
      class="col-md-12"
      [hidden]="
        !(local_data.From_Bcc == '1' || local_data.From_Bcc == 'undefined')
      "
    >
      <mat-form-field appearance="fill">
        <mat-label>From *</mat-label>
        <input
          name="email_from_bcc"
          matInput
          placeholder="{{ local_data?.email_from }}"
          [(ngModel)]="local_data.email_from"
          formControlName="email_from_bcc"
          readonly
        />
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-form-field appearance="fill">
        <mat-label>To </mat-label>
        <input
          name="email_to"
          matInput
          placeholder="{{ local_data?.email_to }}"
          [(ngModel)]="local_data.email_to"
          formControlName="email_to"
          [required]="true"
        />
        <mat-error *ngIf="formGroup.get('email_to')?.hasError('required')">
          email to is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-form-field appearance="fill">
        <mat-label>Subject </mat-label>
        <input
          name="email_subject"
          matInput
          placeholder="{{ local_data?.email_subject }}"
          [(ngModel)]="local_data.email_subject"
          formControlName="email_subject"
          [required]="true"
        />
        <mat-error *ngIf="formGroup.get('email_subject')?.hasError('required')">
          email subject is <strong>required</strong>
        </mat-error>
        <mat-error
          *ngIf="formGroup.get('email_subject')?.hasError('invalidName')"
        >
          Special characters not allowed!
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-form-field appearance="fill">
        <mat-label>Message </mat-label>
        <input
          name="email_message"
          matInput
          placeholder="{{ local_data?.email_message }}"
          [(ngModel)]="local_data.email_message"
          formControlName="email_message"
        />
        <mat-error
          *ngIf="formGroup.get('email_message')?.hasError('invalidName')"
        >
          Special characters not allowed!
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div id="block_container_contactDetails">
    <div id="bloc_contact_detail_1" class="col-md-8"></div>
    <div id="bloc_contact_detail_1" class="col-md-2">
      <button mat-stroked-button (click)="closeDialog()" color="accent">
        Cancel
      </button>
    </div>
    <div id="bloc_contact_detail_1" class="col-md-2">
      <button
        mat-button
        (click)="doAction()"
        [disabled]="!formGroup.valid"
        mat-flat-button
        color="warn"
      >
        <mat-icon color="send">send</mat-icon>Send
      </button>
    </div>
  </div>
  <!-- </<mat-dialog-content> -->
</form>

import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  private isPrintingSource = new BehaviorSubject<boolean>(false);
  isPrinting$ = this.isPrintingSource.asObservable();
  
  private institutionIdSource = new BehaviorSubject<number | null>(null);

  currentInstitutionId = this.institutionIdSource.asObservable();
  constructor(private router: Router) { }

  setIsPrinting(value: boolean) {
    this.isPrintingSource.next(value);
  }
  changeInstitutionId(id: number) {
    this.institutionIdSource.next(id);
  }
  
  getPrint() {
    return this.isPrintingSource.value;
  }
}
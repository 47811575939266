import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../auth_generic/auth.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  DTContact,
  DTProgram,
  DegreeTransferService,
  DTInstitution,
  DTInstitutionDetails,
  DTPathway,
} from '../../services/degree-transfer.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DtInstitutionDetailsComponent } from '../dt-institution-details/dt-institution-details.component';
import {
  InstitutionService,
  Institution,
  InstitutionContact,
} from '../../services/institution-service';
import { DtProgramDetailsComponent } from '../dt-program-details/dt-program-details.component';

@Component({
  selector: 'app-dt-program',
  templateUrl: './dt-program.component.html',
  styleUrls: ['./dt-program.component.scss'],
})
export class DtProgramComponent implements OnInit {
  local_data: any;
  action = '';
  dataSource = new MatTableDataSource(initialData1);
  displayedColumns = ['program_code', 'program_name', 'delete'];
  contents!: DTPathway[];
  private sort: MatSort | null = null;
  private paginator: MatPaginator | null = null;
  userName = sessionStorage.getItem('user');
  userLogin = sessionStorage.getItem('userLogin');

  @ViewChild(MatTable, { static: true }) table: MatTable<any> | undefined;
  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    setTimeout(() => (this.dataSource.sort = this.sort));
  }

  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    mp: MatPaginator
  ) {
    this.paginator = mp;
    setTimeout(() => (this.dataSource.paginator = this.paginator));
  }

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router,
    private institutionService: InstitutionService,
    private authService: AuthService,
    private dtService: DegreeTransferService
  ) {
    if (!this.userName || this.userName.length < 1) {
      if (!this.userLogin) {
        this.authService.login();
      }
      //this.setUserName();
    }
  }

  ngOnInit() {
    this.fetchProgramData();
  }

  addDTProgram() {
    const navigationDetails: string = '/Degree-Transfer-Programs-Details';
    var data = { action: 'Add' };
    this.router.navigateByUrl(navigationDetails, { state: data });
  }

  openDialog(action: any, obj: any, e: any) {
    obj.action = action;
    if (e.target.className.includes('mat-icon') && action == 'Update') {
      return;
    }
    console.log('details opened, ' + JSON.stringify(action));

    const dialogR = this.dialog.open(DtProgramDetailsComponent, {
      width: '50%',
      data: obj,
    });

    dialogR.afterClosed().subscribe((result) => {
      if (action == 'Add') {
        this.addRowDataContent(result.data);
      } else if (action == 'Update') {
        this.updateRowDataContent(result.data);
      } else if (action == 'Delete') {
        this.deleteRowDataContent(result.data);
      }
    });
  }
  addRowDataContent(row_obj: any) {
    // refetch program content after adding
    this.fetchProgramData();
  }

  updateRowDataContent(row_obj: any) {
    /// refetch program after update
    this.fetchProgramData();
  }
  deleteRowDataContent(row_obj: any) {
    console.log('this delete> ' + JSON.stringify(row_obj));
    const data = this.dataSource.data;
    this.dtService.removeProgram(row_obj).subscribe();
    this.dataSource.data = data.filter((value, key) => {
      return value.program_id != row_obj.program_id;
    });
    this._snackBar.open(row_obj.name, 'Deleted', {
      duration: 2000,
    });

    console.log('delete program component');
  }

  applyFilter($event: any) {
    var filterValue = $event.target.value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  fetchProgramData() {
    this.dtService.getAllPrograms().subscribe(
      (res: any) => {
        this.dataSource = new MatTableDataSource(res);
        if (this.paginator) {
          this.dataSource.paginator = this.paginator;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}

const initialData1: DTProgram[] = [];

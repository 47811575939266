import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  PathwayDetails,
  InstitutionService,
  CustomValidators,
  CredentialLevel,
  InboundProgram,
  Institution,
  PathwayFile,
  Pathway,
} from 'src/app/services/institution-service';
import { PathwaysPathwayDetailsComponent } from '../pathways-pathway-details/pathways-pathway-details.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FileContentComponent } from '../file-content/file-content.component';
import { InstitutionPopupComponent } from '../institution-popup/institution-popup.component';
import { PathwaysInboundProgramsComponent } from '../pathways-inbound-programs/pathways-inbound-programs.component';
import { Binary } from '@angular/compiler';

export interface DialogData {
  fileId: string;
  fileType: string;
  fileContent: Binary;
  fileReader: FileReader;
  fileContentBase64: string;
  content: Blob;
}

@Component({
  selector: 'app-pathways-details',
  templateUrl: './pathways-details.component.html',
  styleUrls: ['./pathways-details.component.scss'],
})
export class PathwaysDetailsComponent implements OnInit {
  public elseTemplateDiv: any;
  public TemplateDiv: any;

  formGroup: FormGroup;
  action = '';
  local_data: any;
  fileToUpload: File | null = null;
  fContents: PathwayFile[] = [];
  credential_level: CredentialLevel[] = [];
  pDetail: PathwayDetails | undefined;
  deleted_programs: number[] = [];

  displayedColumnsF = [
    'fileName',
    'semesterJan',
    'semesterMay',
    'semesterSep',
    'archive',
    'delete',
  ];
  displayedColumnsP = [
    'institution_name',
    'program_name',
    'mcu_code',
    'program_cred',
    'delete',
  ];
  dataSourceF = new MatTableDataSource(initialDataFiles);
  dataSourceP = new MatTableDataSource(initialDataPrograms);
  userName = sessionStorage.getItem('user');
  private sortF: MatSort | null = null;
  private paginatorF: MatPaginator | null = null;
  private sortP: MatSort | null = null;
  private paginatorP: MatPaginator | null = null;

  @ViewChild('tableFiles', { read: MatSort, static: false }) set matSortF(
    ms: MatSort
  ) {
    this.sortF = ms;
    setTimeout(() => (this.dataSourceF.sort = this.sortF));
  }

  @ViewChild('matPaginatorF', { read: MatPaginator, static: false })
  set matPaginatorF(mp: MatPaginator) {
    this.paginatorF = mp;
    setTimeout(() => (this.dataSourceF.paginator = this.paginatorF));
  }

  @ViewChild('tablePrograms', { read: MatSort, static: false }) set matSortP(
    ms: MatSort
  ) {
    this.sortP = ms;
    setTimeout(() => (this.dataSourceP.sort = this.sortP));
  }

  @ViewChild('matPaginatorP', { read: MatPaginator, static: false })
  set matPaginatorP(mp: MatPaginator) {
    this.paginatorP = mp;
    setTimeout(() => (this.dataSourceP.paginator = this.paginatorP));
  }

  @ViewChild('myUpload', { static: false })
  myInputVariable: ElementRef | undefined;

  filecontent: any;
  fileId: string | undefined;
  isImageLoading: boolean | undefined;
  imageContent: any;

  // allowedTypes: string[] = ['gif', 'jpg', 'jpeg', 'pdf', 'png', 'tiff'];
  allowedTypes: string[] = ['pdf'];
  allowedTypesString = 'Only pdf file type are Allowed';
  pathwayNotExistsErrorMsg =
    'Please save the pathway before upload files because this pathway is not exist yet';
  // 'Only gif, jpg, jpeg, pdf, png and tiff file types are Allowed';
  validType: boolean | undefined;
  pathwayExists: boolean | undefined;

  constructor(
    public dialogRef: MatDialogRef<PathwaysPathwayDetailsComponent>,
    private cdr: ChangeDetectorRef,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Institution,
    private router: Router,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private institutionService: InstitutionService,
    private customValidators: CustomValidators
  ) {
    this.local_data = { ...data };
    if (!data) {
      this.local_data = this.router.getCurrentNavigation()?.extras.state; // {...data};'/[\u0590-\u05FF]+/g'
    }
    this.action = this.local_data ? this.local_data.action : '';

    this.formGroup = this._formBuilder.group({
      // tslint:disable-next-line:max-line-length
      // "title": [, { validators: [Validators.required,Validators.maxLength(250),this.customValidators.nameValidator], updateOn: "change" }],
      title: [
        ,
        [
          Validators.required,
          Validators.maxLength(250),
          this.customValidators.nameValidator,
        ],
      ],
      name: [
        ,
        [
          Validators.required,
          Validators.maxLength(5),
          this.customValidators.nameValidator,
        ],
      ],
      cred_awarded: [
        null,
        [
          Validators.required,
          Validators.maxLength(100),
          this.customValidators.nameValidator,
        ],
      ],
      cred_level: [null, { validators: [], updateOn: 'change' }],
      credits_granted: [null, [this.customValidators.nameValidator]],
      gpa: [null, [this.customValidators.nameValidator]],
      notes: [
        null,
        [Validators.maxLength(500), this.customValidators.nameValidator],
      ],
      semester_start: [null, [this.customValidators.nameValidator]],
    });
  }

  ngOnDestroy() {
    this.deleted_programs = [];
    initialDataPrograms = [];
    initialDataFiles = [];
    this.validType = true;
    this.pathwayExists = true;
  }

  handleFileInput(e: Event) {
    const clickedEvent = e.target as HTMLInputElement;
    let files: FileList | null = clickedEvent.files;
    if (files) {
      const f = files.item(0);
      this.fileToUpload = f;
    }
  }

  uploadFileToActivity() {
    this.validType = false;
    this.pathwayExists = this.local_data && this.local_data.id > 0;
    const fName = this.fileToUpload?.name;
    if (fName) {
      const i = fName.lastIndexOf('.');
      // var fName_f = req.file.filename.substring(0,i);
      const fType = fName.substring(i + 1);
      for (let i = 0; i < this.allowedTypes.length; i++) {
        if (fType.indexOf(this.allowedTypes[i]) !== -1) {
          this.validType = true;
        }
      }
    }
    if (!this.validType || !this.pathwayExists) {
      return;
    }
    const pathwayId = this.local_data.id;
    this.pathwayExists = this.local_data && this.local_data.id > 0;
    if (this.fileToUpload) {
      this.institutionService.postFile(this.fileToUpload, pathwayId).subscribe(
        (data) => {
          setTimeout(
            () =>
              this.institutionService
                .getPathwayFiles(this.local_data.id)
                .subscribe(
                  (res: any) => {
                    initialDataFiles = res;
                    this.dataSourceF = new MatTableDataSource(initialDataFiles);
                    // this.myInputVariable.nativeElement.value = "";
                  },
                  (err) => {
                    console.log(err);
                  }
                ),
            500
          );
          if (this.fileToUpload) {
            // do something, if upload success
            this._snackBar.open(this.fileToUpload.name, 'Uploaded', {
              duration: 2000,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  ngOnInit() {
    this.deleted_programs = [];
    this.validType = true;
    this.pathwayExists = true;
    initialDataPrograms = [];
    initialDataFiles = [];

    // this.dataSourceF.sort = this.matSortF;
    // this.dataSourceP.sort = this.matSortP;
    this.institutionService.getInstitutionCredentialsList().subscribe(
      (res: any) => {
        this.credential_level = res;
      },
      (err) => {
        console.log(err);
      }
    );

    if (this.local_data.id > 0) {
      this.institutionService.getPathwayDetails(this.local_data.id).subscribe(
        (res: any) => {
          // this.pDetail=res;
          this.local_data.pDetail = res && res[0];
        },
        (err) => {
          console.log(err);
        }
      );
    }

    if (this.local_data.id > 0) {
      this.institutionService.getPathwayInboundProgramsList().subscribe(
        (res: any) => {
          // var content=res;
          initialDataPrograms =
            res &&
            res.filter(
              (x: InboundProgram) => x.pathway_id === this.local_data.id
            );
          this.dataSourceP = new MatTableDataSource(initialDataPrograms);
          if (this.paginatorP) {
            this.dataSourceP.paginator = this.paginatorP;
          }
          setTimeout(() => (this.dataSourceP.sort = this.sortP));
        },
        (err) => {
          console.log(err);
        }
      );
    }

    if (this.local_data.id > 0) {
      this.institutionService.getPathwayFiles(this.local_data.id).subscribe(
        (res: any) => {
          // this.local_data.pDetail=res;
          initialDataFiles =
            res &&
            res.filter((x: PathwayFile) => x.pathwayId === this.local_data.id);
          // filter(x=> ! x.fileStatus || (x.fileStatus && x.fileStatus.trim() != '0') );
          // initialDataFiles.forEach(f => {
          //   // f.fileContent=f.fileContent.toString();//('utf8')
          //   var read = new FileReader();
          //   var arrayBuffer, arrayResult, uint8Array;
          //   var blob = new Blob([f.fileContent]);
          //   // read.readAsBinaryString(blob);
          //   read.readAsArrayBuffer(blob);

          //   read.onloadend = function(){
          //     arrayBuffer = this.result;
          //     arrayResult = read.result;
          //     // uint8Array  = new Uint8Array(arrayBuffer);
          //     // console.log(arrayBuffer);
          //       // console.log(arrayResult);

          //       // console.log(uint8Array);
          //       // let content = JSON.parse(arrayResult);
          //       // console.log(content.data);
          //       f.fileContent = arrayResult.data;
          //   }
          //     // console.log(f.fileContent);
          // });
          this.dataSourceF = new MatTableDataSource(initialDataFiles);
          if (this.paginatorF) {
            this.dataSourceF.paginator = this.paginatorF;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  viewPathwayFile(e: Event, f: PathwayFile): void {
    e.stopPropagation();
    const clickedElement = e.target as HTMLButtonElement;
    if (clickedElement) {
      return;
    }
    const blob = new Blob([f.fileContent]);
    const fileId = f.fileId.toString();
    const fName = f.fileName;
    const i = fName.lastIndexOf('.');
    // var fName_f = req.file.filename.substring(0,i);
    // var pathwayId = req.file.filename.substring(i+1);
    const fileType = f.fileName.substring(i + 1);
    this.getFileContent(fileId, fileType);
  }

  openDialog(
    selectFileId: string,
    filetype: string,
    fileReader: FileReader | null,
    imagecontentBase64: string | null,
    content: Blob
  ) {
    // console.log('openDialog AppId: ' + appId + ', FileID: ' + selectFileId);
    const imageData: any = null;

    const dialogRef = this.dialog.open(FileContentComponent, {
      width: '800px',
      data: {
        fileId: selectFileId,
        fileType: filetype,
        fileContent: this.filecontent,
        fileReader,
        fileContentBase64: this.imageContent,
        content,
      },
    });
  }

  getFileContent(selectedFileId: string, fileType: string) {
    this.isImageLoading = true;
    this.institutionService
      .getPathwayFileContent(selectedFileId, fileType)
      .subscribe((imgData) => {
        if (fileType !== 'pdf') {
          this.createImageFromBlob(selectedFileId, fileType, imgData);
        } else {
          const fileReader = new FileReader();
          fileReader.addEventListener(
            'load',
            () => {
              // this.imageBlobUrl = reader.result;
              this.imageContent = fileReader.result;

              this.openDialog(
                selectedFileId,
                fileType,
                fileReader,
                this.imageContent,
                imgData
              );
            },
            false
          );

          if (imgData) {
            fileReader.readAsDataURL(imgData);
          }

          // var blob = new Blob([imgData], {type: "application/pdf"});
          // var fileURL = URL.createObjectURL(blob);
          // window.open(fileURL, '_blank');
          // window.open(`C:\\Downloads\Get_Started_With_Smallpdf.pdf`, '_blank');
        }
      });
    // }
    // this.isImageLoading = false;
    // }, error => {
    //   // this.isImageLoading = false;
    //   console.log(error);
    // });
  }
  createImageFromBlob(selectedFileId: string, fileType: string, image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        // console.log(reader.result);
        this.filecontent = reader.result; // ).toString();
        // console.log(this.filecontent);
        this.openDialog(selectedFileId, fileType, null, null, image);
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  selectedIndexChange(index: number) {
    if (index === 0) {
      const data = initialDataFiles; // && initialDataFiles.filter(x=> ! x.fileStatus || (x.fileStatus && x.fileStatus.trim() != '0') );
      this.dataSourceF.data = data;
    } else if (index === 1) {
      const data =
        initialDataFiles &&
        initialDataFiles.filter(
          (x) => !x.fileStatus || (x.fileStatus && x.fileStatus.trim() !== '0')
        );
      this.dataSourceF.data = data;
    } else if (index === 2) {
      const data = initialDataFiles.filter(
        (x) => x.fileStatus && x.fileStatus.trim() === '0'
      );
      // this.institutionService.getPathwayFiles(this.local_data.id)
      // .subscribe((res: any) => {
      //   // this.local_data.pDetail=res;
      //   data=res && res.filter(x=>x.pathwayId== this.local_data.id).filter(x=>x.fileStatus && x.fileStatus.trim() == '0');
      // }, err => {
      //   console.log(err);
      // });
      this.dataSourceF.data = data;
    }
  }

  setVisibleChanges(notify: boolean) {
    this.local_data.isVisible = notify; // ?"1":"0";
  }
  setPublishedChanges(notify: boolean) {
    this.local_data.isPublished = notify; // ?"1":"0";
  }
  setSemesterJanChanges(notify: boolean) {
    this.local_data.entry_semester_jan = notify ? '1' : '0';
    if (this.local_data.pDetail) {
      this.local_data.pDetail.action = 'a';
    }
  }
  setSemesterMayChanges(notify: boolean) {
    this.local_data.entry_semester_may = notify ? '1' : '0';
    if (this.local_data.pDetail) {
      this.local_data.pDetail.action = 'a';
    }
  }
  setSemesterSepChanges(notify: boolean) {
    this.local_data.entry_semester_sep = notify ? '1' : '0';
    if (this.local_data.pDetail) {
      this.local_data.pDetail.action = 'a';
    }
  }

  setProgramCompletedChanges() {
    this.local_data.program_completed = '1';
  }
  setProgramCompletedPartiallyChanges() {
    this.local_data.program_completed = '0';
  }

  setSemesterJanFileChanges(obj: any, notify: boolean) {
    obj.entry_semester_jan = notify ? '1' : '0';
    this.pathwayFile_Semester_Update(obj);
  }
  setSemesterMayFileChanges(obj: any, notify: boolean) {
    obj.entry_semester_may = notify ? '1' : '0';
    this.pathwayFile_Semester_Update(obj);
  }
  setSemesterSepFileChanges(obj: any, notify: boolean) {
    obj.entry_semester_sep = notify ? '1' : '0';
    this.pathwayFile_Semester_Update(obj);
  }

  doAction() {
    if (this.action === 'Delete') {
      this.deletePathway('Delete');
    } else if (this.action === 'Archive') {
      this.deletePathway('Archive');
    } else {
      if (this.local_data.pDetail) {
        // tslint:disable-next-line:max-line-length
        this.local_data.pDetail.entry_semester_jan = this.local_data
          .entry_semester_jan
          ? this.local_data.entry_semester_jan
          : this.local_data.pDetail.entry_semester_jan;
        // tslint:disable-next-line:max-line-length
        this.local_data.pDetail.entry_semester_may = this.local_data
          .entry_semester_may
          ? this.local_data.entry_semester_may
          : this.local_data.pDetail.entry_semester_may;
        // tslint:disable-next-line:max-line-length
        this.local_data.pDetail.entry_semester_sep = this.local_data
          .entry_semester_sep
          ? this.local_data.entry_semester_sep
          : this.local_data.pDetail.entry_semester_sep;
      }
      const newPathway: Pathway = {
        name: this.local_data.name,
        semester_start: this.local_data.semester_start,
        program_completed: this.local_data.program_completed,
        id: this.local_data.id,
        status: '1',
        title: this.local_data.title,
        gpa: this.local_data.gpa,
        cred_level: this.local_data.cred_level,
        cred_awarded: this.local_data.cred_awarded,
        type: this.local_data.type,
        credits_granted: this.local_data.credits_granted,
        isPublished: this.local_data.isPublished,
        isVisible: this.local_data.isVisible,
        notes: this.local_data.notes,
        modified_by: this.userName ? this.userName : '',
        modified_date: new Date(),
        publish_date: new Date(),
        pathwayDetails: this.local_data.pDetail, // this.pDetail,
        // contacts:this.dataSource.data,
        // contacts_deleted:this.deleted_contacts,
        inbound_programs: this.dataSourceP.data,
        inbound_program_deleted: this.deleted_programs,
        // program_archived: this.archive_programs,
        action: this.action,
      };
      // console.log(this.local_data.id < 1);
      if (!this.local_data.id) {
        this.institutionService.addPathway(newPathway).subscribe((res) => {
          this._snackBar.open(this.local_data.name, 'Added', {
            duration: 2000,
          });
          const navigationDetails: string[] = ['/Pathway'];
          this.router
            .navigateByUrl('/Pathway', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(navigationDetails);
            });
        });
      } else {
        this.institutionService.updatePathway(newPathway).subscribe((res) => {
          this._snackBar.open(this.local_data.name, 'Updated', {
            duration: 2000,
          });
          this.cleanUpPathwayData_AfterUpdate();
          const navigationDetails: string[] = ['/Pathway'];
          this.router
            .navigateByUrl('/Pathway', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(navigationDetails);
            });
        });
      }
      // if(this.dataSource.data){
      //   console.log(this.dataSource.data);
      // }
      // if(this.deleted_contacts && this.deleted_contacts.length > 0){
      //   console.log(this.deleted_contacts);
      // }
      // const navigationDetails: string[] = ['/Pathway'];
      // this.router.navigateByUrl('/Pathway', { skipLocationChange: true }).then(() => {
      //   this.router.navigate(navigationDetails);
      // });
      // this.router.navigate(navigationDetails);
      // this.dialogRef.close({event:this.action,data:this.local_data});
    }
  }

  cleanUpPathwayData_AfterUpdate() {
    this.deleted_programs = [];
    this.dataSourceP.data.forEach((p) => (p.action = ''));
    this.institutionService.getPathwayInboundProgramsList().subscribe(
      (res: any) => {
        initialDataPrograms =
          res &&
          res.filter(
            (x: InboundProgram) => x.pathway_id === this.local_data.id
          );
        this.dataSourceP = new MatTableDataSource(initialDataPrograms);
        setTimeout(() => (this.dataSourceP.sort = this.sortP));
      },
      (err) => {
        console.log(err);
      }
    );
  }

  deletePathway(name: string) {
    this.dialogRef.close({ event: name, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  cancelAction(): void {
    const navigationDetails: string[] = ['/Pathway'];
    this.router.navigate(navigationDetails);
  }

  // changeCountry(value: string)
  // {
  //   this.local_data.country=value;
  // }

  ngAfterViewInit() {
    this.dataSourceF.sort = this.matSortF;
    this.dataSourceP.sort = this.matSortP;
    this.cdr.detectChanges();
  }

  openDialogDelete_Archive(action: any, e: Event) {
    //   if (e.target.className.includes('mat-icon') && action=='Update') {
    //     return;
    // }
    this.local_data.action = action;
    //  this.local_data.title = 'delete_achive';///  this line changes the pathway title when the user clicks 'archive'
    //  this.deleted_contact= (obj.contact_first_name + ' ' + obj.contact_last_name);
    const dialogR = this.dialog.open(InstitutionPopupComponent, {
      width: '50%',
      data: this.local_data,
    });

    dialogR.afterClosed().subscribe((result) => {
      if (result.event === 'Archive') {
        this.archivePathwayData(result.data);
      } else if (result.event === 'Delete') {
        this.deletePathwayData(result.data);
      } else if (result.event == 'reactivate') {
        this.reactivePathwayData(result.data);
      }
    });
  }

  archivePathwayData(row_obj: any) {
    row_obj.modified_by = this.userName;
    this.institutionService.archivePathway(row_obj).subscribe((res) => {
      this._snackBar.open(row_obj.name, 'Archived', {
        duration: 2000,
      });
      const navigationDetails: string[] = ['/Pathway'];
      this.router
        .navigateByUrl('/Pathway', { skipLocationChange: true })
        .then(() => {
          this.router.navigate(navigationDetails);
        });
    });
  }

  reactivePathwayData(row_obj: any) {
    row_obj.modified_by = this.userName;
    this.institutionService.reactivePathway(row_obj).subscribe((res) => {
      this._snackBar.open(row_obj.name, 'Re-activate', {
        duration: 2000,
      });
      const navigationDetails: string[] = ['/Pathway'];
      this.router
        .navigateByUrl('/Pathway', { skipLocationChange: true })
        .then(() => {
          this.router.navigate(navigationDetails);
        });
    });
  }

  deletePathwayData(row_obj: any) {
    row_obj.modified_by = this.userName;
    this.institutionService.deletePathway(row_obj).subscribe((res) => {
      this._snackBar.open(row_obj.name, 'Delete', {
        duration: 2000,
      });
      const navigationDetails: string[] = ['/Pathway'];
      this.router
        .navigateByUrl('/Pathway', { skipLocationChange: true })
        .then(() => {
          this.router.navigate(navigationDetails);
        });
    });
  }

//save pathway
addPathway(): void {
  const navigationDetails: string = '/AddPathway';
  var data = { action: 'Add' };
  this.router.navigateByUrl(navigationDetails, { state: data });
}

  openDialog_Details(e: Event) {
    // force new pathway because pathwayDetails missing will cause rich text editor error
    this.addPathway();
    // obj.action = action;
    const dialogR = this.dialog.open(PathwaysPathwayDetailsComponent, {
      width: '50%',
      maxHeight: window.innerHeight + 'px',
      data: this.local_data.pDetail, // && this.local_data.pDetail[0]
    });

    dialogR.afterClosed().subscribe((result) => {
      if (result.event === 'Add') {
        this.addDetailData(result.data);
      }
    });
  }

  // tslint:disable-next-line:variable-name
  addDetailData(row_obj: any) {
    const newRecord = {
      pathway_Id: this.local_data.id, // row_obj.pathway_Id,
      eligibility: row_obj.eligibility,
      transfer_credit: row_obj.transfer_credit,
      time_to_complete: row_obj.time_to_complete,
      seneca_credits_to_complete: row_obj.seneca_credits_to_complete,
      how_to_apply: row_obj.how_to_apply,
      Contact_Information: row_obj.Contact_Information,
      additionalInfo: row_obj.additionalInfo,
      entry_semester_jan: this.local_data.entry_semester_jan
        ? this.local_data.entry_semester_jan
        : row_obj.entry_semester_jan,
      entry_semester_may: this.local_data.entry_semester_may
        ? this.local_data.entry_semester_may
        : row_obj.entry_semester_may,
      entry_semester_sep: this.local_data.entry_semester_sep
        ? this.local_data.entry_semester_sep
        : row_obj.entry_semester_sep,
      modified_by: this.userName,
      modified_date: new Date(),
      action: 'a',
    };
    // this.pDetail = newRecord;
    this.local_data.pDetail = newRecord;

    this._snackBar.open('Pathway Details', 'Added', {
      duration: 2000,
    });
  }

  openDialogFile(action: any, obj: any, e: any) {
    if (e.target.className.includes('mat-icon') && action === 'Update') {
      return;
    }
    obj.action = action;
    const dialogR = this.dialog.open(PathwaysInboundProgramsComponent, {
      width: '45%',
      data: obj,
    });

    dialogR.afterClosed().subscribe((result) => {
      if (result.event === 'ArchiveF') {
        // console.log(result.data);
        this.pathwayFile_Archive(result.data);
      } else if (result.event === 'DeleteF') {
        // console.log(result.data);
        this.pathwayFile_Delete(result.data);
      }
    });
  }

  pathwayFile_Semester_Update(data: any) {
    // row_obj.modified_by = this.userName;
    this.institutionService.updatePathwayFile(data).subscribe((data) => {
      setTimeout(
        () =>
          this.institutionService.getPathwayFiles(this.local_data.id).subscribe(
            (res: any) => {
              initialDataFiles = res;
              this.dataSourceF = new MatTableDataSource(initialDataFiles);
            },
            (err) => {
              console.log(err);
            }
          ),
        500
      );
    });

    this._snackBar.open(data.fileName, 'pathwayFile Updated', {
      duration: 2000,
    });
  }
  pathwayFile_Archive(data: any) {
    // row_obj.modified_by = this.userName;
    this.institutionService.archivePathwayFile(data).subscribe((data) => {
      setTimeout(
        () =>
          this.institutionService.getPathwayFiles(this.local_data.id).subscribe(
            (res: any) => {
              initialDataFiles = res;
              this.dataSourceF = new MatTableDataSource(initialDataFiles);
            },
            (err) => {
              console.log(err);
            }
          ),
        500
      );
    });

    this._snackBar.open(data.fileName, 'Archived', {
      duration: 2000,
    });

    //   this.institutionService.getPathwayFiles(this.local_data.id)
    // .subscribe((res: any) => {
    //   initialDataFiles=res;
    //   this.dataSourceF = new MatTableDataSource(initialDataFiles);
    // }, err => {
    //   console.log(err);
    // });
  }

  pathwayFile_Delete(data: any) {
    // row_obj.modified_by = this.userName;
    this.institutionService.deletePathwayFile(data).subscribe((data) => {
      setTimeout(
        () =>
          this.institutionService.getPathwayFiles(this.local_data.id).subscribe(
            (res: any) => {
              initialDataFiles = res;
              this.dataSourceF = new MatTableDataSource(initialDataFiles);
            },
            (err) => {
              console.log(err);
            }
          ),
        500
      );
    });

    this._snackBar.open(data.fileName, 'Delete', {
      duration: 2000,
    });
  }

  openDialogProgram(action: any, obj: any, e: any) {
    if (!e.target.className.includes('mat-icon') && action === 'Update') {
      return;
    }
    obj.action = action;
    const dialogR = this.dialog.open(PathwaysInboundProgramsComponent, {
      width: '55%',
      maxHeight: window.innerHeight + 'px',
      data: obj,
    });

    dialogR.afterClosed().subscribe((result) => {
      if (result.event === 'Add') {
        // this.addRowDataP(result.data[0]);
        this.addRowDataP_New(result.data);
      } else if (result.event === 'Delete') {
        this.deleteRowDataP(result.data);
      }
    });
  }

  addRowDataP_New(row_objs: any) {
    const data = this.dataSourceP.data;
    row_objs.forEach((row_obj: InboundProgram) => {
      // to check if exists in the array already, otherwise insert
      if (data.find((x) => x.program_id === row_obj.program_id)) {
        return;
      }
      const newRecord: InboundProgram = {
        pathway_id: this.local_data.id,
        program_name: row_obj.program_name,
        program_code: row_obj.program_code,
        program_status: row_obj.program_status,
        program_completed: row_obj.program_completed,
        institution_id: row_obj.institution_id,
        institution_name: row_obj.institution_name,
        program_id: row_obj.program_id,
        program_cred: row_obj.program_cred,
        mcu_code: row_obj.mcu_code,
        program_cred_desc: row_obj.program_cred_desc,
        seneca_cred: row_obj.seneca_cred,
        seneca_cred_desc: row_obj.seneca_cred_desc,
        seneca_program: row_obj.seneca_program,
        seneca_program_name: row_obj.seneca_program_name,
        modified_by: row_obj.modified_by,
        modified_date: row_obj.modified_date,
        action: 'a',
      };
      data.push(newRecord);
    });

    this.dataSourceP.data = data;
    // this.tableContacts.renderRows();
    this._snackBar.open('Pathway Inbound Programs', 'Added', {
      duration: 2000,
    });
    return true;
  }

  addRowDataP(row_obj: InboundProgram) {
    const data = this.dataSourceP.data;
    const newRecord = {
      pathway_id: this.local_data.id,
      program_name: row_obj.program_name,
      program_code: row_obj.program_code,
      program_status: row_obj.program_status,
      program_completed: row_obj.program_completed,
      institution_id: row_obj.institution_id,
      institution_name: row_obj.institution_name,
      program_id: row_obj.program_id,
      program_cred: row_obj.program_cred,
      mcu_code: row_obj.mcu_code,
      program_cred_desc: row_obj.program_cred_desc,
      seneca_cred: row_obj.seneca_cred,
      seneca_cred_desc: row_obj.seneca_cred_desc,
      seneca_program: row_obj.seneca_program,
      seneca_program_name: row_obj.seneca_program_name,
      modified_by: row_obj.modified_by,
      modified_date: row_obj.modified_date,
      action: 'a',
    };
    data.push(newRecord);

    this.dataSourceP.data = data;
    // this.tableContacts.renderRows();
    this._snackBar.open(row_obj.program_name, 'Added', {
      duration: 2000,
    });
    return true;
  }

  deleteRowDataP(row_obj: InboundProgram) {
    const data = this.dataSourceP.data;

    data.filter((value, key) => {
      if (row_obj.program_id > 0) {
        if (!this.deleted_programs) {
          this.deleted_programs = [];
        }
        if (!this.deleted_programs.includes(row_obj.program_id)) {
          this.deleted_programs.push(row_obj.program_id);
          row_obj.action = 'd';
        }
        // return value.program_id != row_obj.program_id
      } else {
        if (
          value.program_name === row_obj.program_name &&
          value.institution_name === row_obj.institution_name
        ) {
          row_obj.action = 'd';
          value.action = 'd';
        }
        // return value.program_name != row_obj.program_name && value.program_code != row_obj.program_code;
      }
    });
    this.dataSourceP.data = data.filter(
      (x) => !this.deleted_programs.includes(x.program_id) && x.action !== 'd'
    );
    this._snackBar.open(row_obj.program_name, 'Deleted', {
      duration: 2000,
    });
    return true;
  }
}

let initialDataPrograms: InboundProgram[] = [];
let initialDataFiles: PathwayFile[] = [];

<form [formGroup]="formGroup" class="institution-form">
  <!-- program section -->
  <div
    *ngIf="
      action != 'Delete' && action != 'DeleteF' && action != 'ArchiveF';
      else elseTemplateC
    "
  >
    <h1 mat-dialog-title><strong>Inbound Program</strong></h1>

    <div id="block_container_contact">
      <div id="bloc_contact_1" class="col-md-4">
        <mat-form-field appearance="fill">
          <mat-label>Institution Name </mat-label>
          <mat-select
            name="inst_name"
            [(ngModel)]="local_data.inst_name"
            formControlName="inst_name"
            [required]="true"
          >
            <mat-option
              *ngFor="let c of instList"
              [value]="c.id"
              (click)="InstSelectChange()"
            >
              {{ c.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="formGroup.get('inst_name')?.hasError('required')">
            Instituion Name is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div id="bloc_contact_2" class="col-md-4">
        <!-- <button mat-button (click)="openDialogProgram('Add',{},$event)" mat-flat-button color="warn"> + New Program </button> -->
      </div>
      <div id="bloc_contact_2" class="col-md-4">
        <mat-form-field>
          <input
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Search"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="list-container mat-elevation-z8">
      <div id="block_container_contact">
        <div id="bloc1" class="col-md-3">
          <mat-checkbox
            name="selectAll"
            [checked]="allSelect"
            [disabled]="!anyProgramsExists"
            (change)="selectAllChanges($event.checked)"
            >Select All
          </mat-checkbox>
        </div>
      </div>

      <mat-table #tablePrograms [dataSource]="dataSourceP" matSort>
        <!-- [checked]="primaryContact.id == element.id" -->
        <ng-container matColumnDef="primary">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-checkbox
              name="program_pick"
              [checked]="element.selected"
              (change)="programPick(element, $event.checked)"
            >
            </mat-checkbox>
          </mat-cell>
          <!-- <mat-cell *matCellDef="let element;">
                   <mat-radio-button (click)="primaryClick(element)"
                   ></mat-radio-button>
               </mat-cell> -->
        </ng-container>

        <ng-container matColumnDef="mcu_code">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            MCU Code
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.mcu_code }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="program_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Program Name
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.program_name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="program_code">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Program Code
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.program_code }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="program_cred">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Credential Level
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.program_cred }}
          </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="program_completed">
               <mat-header-cell *matHeaderCellDef mat-sort-header>  Completed </mat-header-cell>
               <mat-cell *matCellDef="let element"> {{element.program_completed == "1"?"Yes":"No"}} </mat-cell>
             </ng-container> -->

        <mat-header-row *matHeaderRowDef="displayedColumnsP"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsP"></mat-row>
        <!-- (click)="openDialogProgram('Update',row,$event)" -->
      </mat-table>
      <mat-paginator
        [length]="dataSourceP.data.length"
        [pageSize]="5"
        [pageSizeOptions]="[5, 10, 25, 100]"
      ></mat-paginator>
    </div>
    <!-- end of program section-->
  </div>
  <div>
    <ng-template #elseTemplateC>
      <div *ngIf="action == 'Delete'">
        Sure to delete <b>{{ local_data.program_name }} </b>?
      </div>
      <div *ngIf="action == 'DeleteF'">
        Sure to delete <b>{{ local_data.fileName }} </b>?
      </div>
      <div *ngIf="action == 'ArchiveF'">
        Sure to archive <b>{{ local_data.fileName }} </b>?
      </div>
    </ng-template>
  </div>

  <div mat-dialog-actions id="block_container" *ngIf="action == 'Add'">
    <div id="bloc_1" class="col-md-8"></div>
    <div id="bloc_1" class="col-md-2">
      <button mat-stroked-button (click)="closeDialog()" color="accent">
        Cancel
      </button>
    </div>
    <div id="bloc_2" class="col-md-2">
      <button
        mat-button
        (click)="doAction()"
        [disabled]="!formGroup.valid"
        mat-flat-button
        color="warn"
      >
        Save
      </button>
    </div>
  </div>

  <div
    mat-dialog-actions
    id="block_container"
    *ngIf="action == 'Delete' || action == 'DeleteF' || action == 'ArchiveF'"
  >
    <div id="bloc_1" class="col-md-8"></div>
    <div id="bloc_1" class="col-md-2">
      <button mat-stroked-button (click)="closeDialog()" color="accent">
        Cancel
      </button>
    </div>
    <div id="bloc_2" class="col-md-2">
      <button mat-button (click)="doAction()" mat-flat-button color="warn">
        Save
      </button>
    </div>
  </div>
</form>

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CredentialLevel,
  InstitutionProgram,
  InstitutionService,
  CustomValidators,
} from 'src/app/services/institution-service';

@Component({
  selector: 'app-institution-program-details',
  templateUrl: './institution-program-details.component.html',
  styleUrls: ['./institution-program-details.component.scss'],
})
export class InstitutionProgramDetailsComponent implements OnInit {
  public elseTemplatePDiv: any;
  public TemplatePDiv: any;

  formGroup: FormGroup;
  action: string;
  local_data: any;
  myDate = new Date();
  lastModifiedDate: Date | undefined;
  credential_level: CredentialLevel[] = [
    { cred_code: 'CER', cred_desc: 'Certificate' },
    { cred_code: 'DIP', cred_desc: 'Diploma' },
    { cred_code: 'ADIP', cred_desc: 'Advanced Diploma' },
  ];
  constructor(
    public dialogRef: MatDialogRef<InstitutionProgramDetailsComponent>,
    //@Optional() is used to prevent error if no data is passed  private datePipe: DatePipe
    @Optional() @Inject(MAT_DIALOG_DATA) public data: InstitutionProgram,
    private _formBuilder: FormBuilder,
    private institutionService: InstitutionService,
    private customValidators: CustomValidators
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
    //this.myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');

    this.formGroup = this._formBuilder.group({
      program_cred: [null, { validators: [], updateOn: 'change' }],
      program_name: [
        null,
        [
          Validators.maxLength(250),
          this.customValidators.nameValidator,
        ],
      ],
      program_code: [
        null,
        [
          Validators.maxLength(10),
          this.customValidators.nameValidator,
        ],
      ],
      mcu_code: [
        null,
        [
          Validators.maxLength(15),
          this.customValidators.nameValidator,
        ],
      ],
    });
  }

  ngOnInit() {
    this.institutionService.getInstitutionCredentialsList().subscribe(
      (res: any) => {
        // this.contents = res;
        this.credential_level = res;
      },
      (err) => {
        console.log(err);
      }
    );

    if (this.local_data.modified_date) {
      this.lastModifiedDate = new Date(this.local_data.modified_date);
      this.lastModifiedDate.setDate(this.lastModifiedDate.getDate() + 1);
    }
  }

  // setComplete(completed: boolean) {
  //   this.local_data.program_completed = completed?"1":"0";
  // }

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  delete() {
    this.dialogRef.close({ event: 'Delete', data: this.local_data });
  }
  archive() {
    this.dialogRef.close({ event: 'Archive', data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}

<div *ngIf="userName" class="div-padding-left">
  <div id="block_container_inst_search">
    <div id="bloc1_search" class="col-md-6">
      <h3>DEGREE TRANSFER INSTITUTIONS</h3>
    </div>
    <div id="bloc2_search" class="col-md-2">
      <button mat-stroked-button (click)="exportData()" color="accent">
        Export Data
      </button>
    </div>
    <div id="bloc2_search" class="col-md-5">
      <button
        mat-button
        routerLink="/Degree-Transfer-Institution-Details"
        (click)="addDTInstition()"
        mat-flat-button
        class="mat-warn"
      >
        + New Degree Transfer Institution
      </button>
    </div>
  </div>
  <div id="block_container_inst_search">
    <div id="bloc2_search" class="col-md-4 offset-8">
      <mat-form-field>
        <input
          #searchValue
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="list-container mat-elevation-z8">
    <mat-table class="mat-table" #table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Institution Name
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.institution_name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Location
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.institution_location }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="delete">
        <mat-header-cell *matHeaderCellDef>
          Delete
        </mat-header-cell>
        <mat-cell *cdkCellDef="let element">
          <mat-icon
            (click)="openDialog('Delete', element, $event)"
            class="cursor_point"
            >delete_outline</mat-icon
          >
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="updateInstitution($event, row)"
      ></mat-row>
    </mat-table>
    <mat-paginator
      #paginator
      [length]="dataSource.data.length"
      [pageSize]="20"
      [pageSizeOptions]="[10, 20, 40, 80]"
      aria-label="Select page"
    ></mat-paginator>
  </div>
</div>

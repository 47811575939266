<div class="div-padding-left">
  <div
    #TemplateDiv
    *ngIf="action != 'Delete' && action != 'Archive'; else elseTemplateDiv"
  >
    <div id="block_container">
      <div id="bloc1" class="col-md-4">
        <span class="mat-dialog-title"
          ><mat-icon (click)="cancelAction()" class="cursor_point"
            >keyboard_backspace</mat-icon
          >
          &nbsp; &nbsp;{{ local_data && local_data.name }}</span
        >
      </div>
      <div id="bloc1" class="col-md-2"></div>
      <div id="block_container">
        <div
          id="bloc1"
          class="col-md-3"
          *ngIf="!(local_data && local_data.status == '0')"
        >
          <button
            mat-button
            (click)="openDialogDelete_Archive('Archive', $event)"
            [disabled]="local_data && local_data.status == '0'"
            color="archive"
          >
            <mat-icon color="archive">archive</mat-icon> Archive
          </button>
          <!-- <span class="mat-archive cursor_point" (click)="openDialogDelete_Archive('Archive',$event)"><mat-icon  color="archive" >archive</mat-icon> Archive</span> -->
        </div>
        <div
          id="bloc1"
          class="col-md-3"
          *ngIf="local_data && local_data.status == '0'"
        >
          <button
            mat-button
            (click)="openDialogDelete_Archive('reactivate', $event)"
            color="archive"
          >
            <mat-icon color="archive">archive</mat-icon> Re-activate
          </button>
          <!-- <span class="mat-archive cursor_point" (click)="openDialogDelete_Archive('Archive',$event)"><mat-icon  color="archive" >archive</mat-icon> Archive</span> -->
        </div>
        <div id="bloc1" class="col-md-3">
          <!-- <span class="mat-archive cursor_point" (click)="openDialogDelete_Archive('Delete',$event)"><mat-icon  color="archive">delete_outline</mat-icon> Delete</span> -->
          <button
            mat-button
            (click)="openDialogDelete_Archive('Delete', $event)"
            color="archive"
          >
            <mat-icon color="archive">delete_outline</mat-icon> Delete
          </button>
        </div>
        <div id="bloc1" class="col-md-3">
          <button mat-stroked-button (click)="cancelAction()" color="accent">
            Cancel
          </button>
        </div>
        <div id="bloc1" class="col-md-3">
          <button
            mat-button
            (click)="doAction()"
            [disabled]="!(local_data.cred_level && local_data.title && local_data.name && local_data.cred_awarded)"
            mat-flat-button
            color="warn"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="formGroup">
    <div
      id="block_container_contact"
      *ngIf="action != 'Delete' && action != 'Archive'"
    >
      <div id="bloc_contact_1" class="col-md-3">
        <h1 mat-dialog-title><strong> Seneca Pathway</strong></h1>
      </div>
    </div>

    <div>
      <div *ngIf="action != 'Delete' && action != 'Archive'; else elseTemplate">
        <div id="block_container_contact">
          <div id="bloc_contact_1" class="col-md-3">
            <mat-checkbox
              name="visible_to_partners"
              [checked]="local_data?.isVisible"
              (change)="setVisibleChanges($event.checked)"
              >Visible to Partners
            </mat-checkbox>
          </div>
          <div id="bloc_contact_2" class="col-md-3">
            <mat-checkbox
              name="publish_to_website"
              [checked]="local_data?.isPublished"
              (change)="setPublishedChanges($event.checked)"
              >Publish to website
            </mat-checkbox>
          </div>
        </div>
        <div id="block_container_contact">
          <div id="bloc_contact_1" class="col-md-8">
            <mat-form-field appearance="fill">
              <mat-label>Pathway Title </mat-label>
              <input
                name="title"
                matInput
                placeholder="{{ local_data?.title }}"
                [(ngModel)]="local_data.title"
                formControlName="title"
                [required]="true"
              />
              <mat-error *ngIf="formGroup.get('title')?.hasError('required')">
                Pathway Title is <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="formGroup.get('title')?.hasError('invalidName')"
              >
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
          <div id="bloc_contact_2" class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>Seneca Program Code </mat-label>
              <input
                name="name"
                matInput
                placeholder="{{ local_data?.name }}"
                [(ngModel)]="local_data.name"
                formControlName="name"
                [required]="true"
              />
              <mat-error *ngIf="formGroup.get('name')?.hasError('required')">
                Seneca Program Code is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="formGroup.get('name')?.hasError('invalidName')">
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div id="block_container_contact">
          <div id="bloc_contact_1" class="col-md-8">
            <mat-form-field appearance="fill">
              <mat-label>Seneca Credential Awarded </mat-label>
              <input
                name="cred_awarded"
                matInput
                placeholder="{{ local_data?.cred_awarded }}"
                [(ngModel)]="local_data.cred_awarded"
                formControlName="cred_awarded"
                [required]="true"
              />
              <mat-error
                *ngIf="formGroup.get('cred_awarded')?.hasError('required')"
              >
                Seneca Credential Awarded is <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="formGroup.get('cred_awarded')?.hasError('invalidName')"
              >
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
          <div id="bloc_contact_2" class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>Seneca Credential Level </mat-label>
              <mat-select
                name="cred_level"
                [(ngModel)]="local_data.cred_level"
                formControlName="cred_level"
                [required]="true"
              >
                <mat-option
                  *ngFor="let c of credential_level"
                  [value]="c.cred_code"
                >
                  {{ c.cred_desc }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="formGroup.get('cred_level')?.hasError('required')"
              >
                Seneca Credential Level is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div id="block_container_contact">
          <div id="bloc_contact_1" class="col-md-8">
            <mat-form-field appearance="fill">
              <mat-label>Number of Credits to Be Granted at Seneca</mat-label>
              <input
                name="credits_granted"
                matInput
                placeholder="{{ local_data?.credits_granted }}"
                [(ngModel)]="local_data.credits_granted"
                formControlName="credits_granted"
              />
              <mat-error
                *ngIf="
                  formGroup.get('credits_granted')?.hasError('invalidName')
                "
              >
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
          <div id="bloc_contact_2" class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>Required GPA </mat-label>
              <input
                name="gpa"
                matInput
                placeholder="{{ local_data?.gpa }}"
                [(ngModel)]="local_data.gpa"
                formControlName="gpa"
              />
              <mat-error *ngIf="formGroup.get('gpa')?.hasError('invalidName')">
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div id="block_container_contact">
          <div id="bloc_contact_1" class="col-md-8">
            <div id="block_container_contact">
              <div id="bloc_contact_1" class="col-md-4">
                <mat-checkbox
                  name="entry_semester_jan"
                  [checked]="local_data?.pDetail?.entry_semester_jan === '1'"
                  (change)="setSemesterJanChanges($event.checked)"
                  >Entry Semester Jan
                </mat-checkbox>
              </div>
              <div id="bloc_contact_2" class="col-md-4">
                <mat-checkbox
                  name="entry_semester_may"
                  [checked]="local_data?.pDetail?.entry_semester_may === '1'"
                  (change)="setSemesterMayChanges($event.checked)"
                  >Entry Semester May
                </mat-checkbox>
              </div>
              <div id="bloc_contact_2" class="col-md-4">
                <mat-checkbox
                  name="entry_semester_sep"
                  [checked]="local_data?.pDetail?.entry_semester_sep === '1'"
                  (change)="setSemesterSepChanges($event.checked)"
                  >Entry Semester Sep
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div id="bloc_contact_2" class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>Semester Start </mat-label>
              <input
                name="semester_start"
                matInput
                placeholder="{{ local_data?.semester_start }}"
                [(ngModel)]="local_data.semester_start"
                formControlName="semester_start"
              />
              <mat-error
                *ngIf="formGroup.get('semester_start')?.hasError('invalidName')"
              >
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div id="block_container_contact">
          <div id="bloc_contact_1" class="col-md-8">
            <div id="block_container_contact">
              <div id="bloc_contact_1" class="col-md-4">
                <mat-radio-button
                  name="program_completed"
                  [checked]="local_data?.program_completed === '1'"
                  (change)="setProgramCompletedChanges()"
                  >Completed
                </mat-radio-button>
              </div>
              <div id="bloc_contact_2" class="col-md-4">
                <mat-radio-button
                  name="program_completed_partial"
                  [checked]="local_data?.program_completed === '0'"
                  (change)="setProgramCompletedPartiallyChanges()"
                  >Partially Completed
                </mat-radio-button>
              </div>
            </div>
          </div>
        </div>

        <!--Pathway Details button-->
        <div class="col-md-3 pathway-detail-button">
          <button
            mat-button
            (click)="openDialog_Details($event)"
            mat-flat-button
            color="warn"
          >
            <mat-icon color="mode">mode</mat-icon> Pathway Details
          </button>
        </div>
      </div>

      <!--Block Map & Completion Plan section -->
      <div *ngIf="action != 'Delete' && action != 'Archive'">
        <div id="block_container">
          <div id="bloc_1" class="col-md-4">
            <h1 mat-dialog-title>
              <strong> Block Map and Completion Plan </strong>
            </h1>
          </div>
          <div id="bloc_1" class="col-md-4">
            <!-- <label for="file">Choose File</label> -->
            <label class="fileUpload">
              <input
                #myUpload
                type="file"
                id="file"
                name="file"
                (change)="handleFileInput($event)"
              />
            </label>
            <!-- <input class="ng-hide" id="input-file-id" type="file" />
<label for="input-file-id" class="md-button md-raised md-primary">Choose Files</label> -->
            <!-- <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
<input hidden (change)="handleFileInput($event.target.files)" #fileInput type="file" id="file"> -->
          </div>
          <div id="bloc_2" class="col-md-2">
            <button
              mat-button
              (click)="uploadFileToActivity()"
              [disabled]="!fileToUpload"
              mat-flat-button
              color="warn"
            >
              Upload
            </button>
            <!-- <button mat-button (click)="openDialogProgram('Add',{},$event)" mat-flat-button color="warn"> + Inbound Program </button> -->
          </div>
          <div id="bloc_2" class="col-md-2">
            <!-- <a href="C:\Downloads\Get_Started_With_Smallpdf.pdf" target="_blank">Click Here</a> -->
            <mat-error *ngIf="!validType">{{ allowedTypesString }}</mat-error>
            <mat-error *ngIf="!pathwayExists">{{
              pathwayNotExistsErrorMsg
            }}</mat-error>
          </div>
        </div>
        <div id="block_container">
          <div id="bloc1" class="col-md-8">
            <mat-tab-group (selectedIndexChange)="selectedIndexChange($event)">
              <mat-tab label="All"> </mat-tab>
              <mat-tab label="Active"> </mat-tab>
              <mat-tab label="Archived"> </mat-tab>
            </mat-tab-group>
          </div>
        </div>

        <div class="list-container mat-elevation-z8">
          <mat-table
            #tableFiles
            #matSortF="matSort"
            [dataSource]="dataSourceF"
            matSort
          >
            <!-- Block Map & Completion Plan Column -->

            <ng-container matColumnDef="fileName">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                File Name
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.fileName }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="semesterJan">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Semester Jan
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-checkbox
                  name="entry_semester_jan_file"
                  [checked]="element.entry_semester_jan === '1'"
                  (change)="setSemesterJanFileChanges(element, $event.checked)"
                >
                </mat-checkbox
              ></mat-cell>
            </ng-container>

            <ng-container matColumnDef="semesterMay">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Semester May
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-checkbox
                  name="entry_semester_may_file"
                  [checked]="element.entry_semester_may === '1'"
                  (change)="setSemesterMayFileChanges(element, $event.checked)"
                >
                </mat-checkbox
              ></mat-cell>
            </ng-container>

            <ng-container matColumnDef="semesterSep">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Semester Sep
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-checkbox
                  name="entry_semester_sep_file"
                  [checked]="element.entry_semester_sep === '1'"
                  (change)="setSemesterSepFileChanges(element, $event.checked)"
                >
                </mat-checkbox
              ></mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="archive">
              <mat-header-cell *matHeaderCellDef> Archive </mat-header-cell>
              <mat-cell *cdkCellDef="let element">
                <mat-icon (click)="openDialogFile('ArchiveF', element, $event)"
                  >archive</mat-icon
                >
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="delete">
              <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
              <mat-cell *cdkCellDef="let element">
                <mat-icon (click)="openDialogFile('DeleteF', element, $event)"
                  >delete_outline</mat-icon
                >
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsF"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumnsF"
              (click)="viewPathwayFile($event, row)"
            ></mat-row>
          </mat-table>
          <mat-paginator
            #matPaginatorF="matPaginator"
            [length]="dataSourceF.data.length"
            [pageSize]="5"
            [pageSizeOptions]="[5, 10, 25, 100]"
          ></mat-paginator>
        </div>
        <!-- end of Block Map & Completion Plan section-->
      </div>

      <!--Inbound program section -->
      <div *ngIf="action != 'Delete' && action != 'Archive'">
        <div id="block_container">
          <div id="bloc_1" class="col-md-3">
            <h1 mat-dialog-title><strong> Inbound Programs </strong></h1>
          </div>
          <div id="bloc_2" class="col-md-2">
            <button
              mat-button
              (click)="openDialogProgram('Add', {}, $event)"
              mat-flat-button
              color="warn"
            >
              + Inbound Program
            </button>
          </div>
        </div>

        <div class="list-container mat-elevation-z8">
          <mat-table
            #tablePrograms
            #matSortP="matSort"
            [dataSource]="dataSourceP"
            matSort
          >
            <!-- Inbound program Column -->
            <ng-container matColumnDef="institution_name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Institution Name
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.institution_name }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="program_name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Program
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.program_name }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mcu_code">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                MCU Code
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.mcu_code }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="program_cred">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Credential Level
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.program_cred_desc }}
              </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="program_completed">
          <mat-header-cell *matHeaderCellDef mat-sort-header>  Completed </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.program_completed == "1"?"Yes":"No"}} </mat-cell>
        </ng-container> -->

            <ng-container cdkColumnDef="delete">
              <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
              <mat-cell *cdkCellDef="let element">
                <mat-icon (click)="openDialogProgram('Delete', element, $event)"
                  >delete_outline</mat-icon
                >
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsP"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsP"></mat-row>
          </mat-table>
          <mat-paginator
            #matPaginatorP="matPaginator"
            [length]="dataSourceP.data.length"
            [pageSize]="5"
            [pageSizeOptions]="[5, 10, 25, 100]"
          ></mat-paginator>
        </div>
        <!-- end of Inbound program section-->
      </div>

      <div style="text-align: center">
        <ng-template #elseTemplate>
          Sure to {{ action }} <b>{{ local_data.name }}</b
          >?
        </ng-template>
      </div>
    </div>

    <!-- Notes section -->
    <div *ngIf="action != 'Delete' && action != 'Archive'">
      <!-- <p>&nbsp; </p>-->
      <div id="block_container_contact">
        <div id="bloc_contact_1" class="col-md-1">
          <h1 mat-dialog-title><strong> Notes </strong></h1>
        </div>
        <div id="bloc_contact_2" class="col-md-1"></div>
      </div>
      <div id="block_container_contactDetails">
        <div id="bloc_contact_1" class="col-md-12">
          <mat-form-field appearance="fill">
            <mat-label>Internal Notes </mat-label>
            <input
              name="notes"
              matInput
              placeholder="{{ local_data?.notes }}"
              [(ngModel)]="local_data.notes"
              formControlName="notes"
            />
            <mat-error *ngIf="formGroup.get('notes')?.hasError('invalidName')">
              Special characters not allowed!
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <p></p>
      <p></p>
      <p></p>
      <!-- <div class="lastMod" *ngIf="action != 'Delete' && action != 'Archive'"> Create by:  {{local_data?.modified_date | date: 'shortDate' }}</div> -->
      <div class="lastMod" *ngIf="action != 'Delete' && action != 'Archive'">
        Last modified by: {{ local_data?.modified_by }} on
        {{ local_data?.modified_date | date : "shortDate" }}
      </div>
      <div class="lastMod" *ngIf="action != 'Delete' && action != 'Archive'">
        Last Published by: {{ local_data?.modified_by }} on
        {{ local_data?.publish_date | date : "shortDate" }}
      </div>
      <!-- <div class="lastMod" *ngIf="action != 'Delete' && action != 'Archive'">Status:  {{local_data?.status=='0'?"Inactive":"Active"}}</div> -->
      <div class="lastMod" *ngIf="action != 'Delete' && action != 'Archive'">
        Pathway ID: {{ local_data?.id }}
      </div>
      <p></p>
    </div>

    <div
      mat-dialog-actions
      class="right-align-btn"
      #elseTemplateDiv
      *ngIf="action == 'Delete' || action == 'Archive'; else TemplateDiv"
    >
      <button mat-button (click)="doAction()" mat-flat-button color="warn">
        {{ action }}
      </button>
      <button mat-stroked-button (click)="closeDialog()" color="accent">
        Cancel
      </button>
    </div>
  </form>
</div>

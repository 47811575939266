import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth_generic/auth.service';
import {
  Institution,
  InstitutionService,
} from 'src/app/services/institution-service';
import { InstitutionDetailsComponent } from '../institution-details/institution-details.component';
import { Title } from '@angular/platform-browser'; 

@Component({
  selector: 'app-institution-list',
  templateUrl: './institution-list.component.html',
  styleUrls: ['./institution-list.component.scss'],
})
export class InstitutionListComponent implements OnInit {
  displayedColumns = ['name', 'status', 'archive', 'delete'];
  dataSource = new MatTableDataSource(initialData1);

  userName = sessionStorage.getItem('user');
  userLogin = sessionStorage.getItem('userLogin');

  tableStart = sessionStorage.getItem('start');
  tableActive = sessionStorage.getItem('active');
  tableDirection = sessionStorage.getItem('direction');
  tablePageSize = sessionStorage.getItem('pageSize');

  private sort: MatSort | null = null;
  private paginator: MatPaginator | null = null;

  @ViewChild(MatTable, { static: true }) table: MatTable<any> | undefined;
  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    setTimeout(() => (this.dataSource.sort = this.sort));
  }

  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    mp: MatPaginator
  ) {
    this.paginator = mp;
    setTimeout(() => (this.dataSource.paginator = this.paginator));
  }

  contents: Institution[] = [];
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router,
    private institutionService: InstitutionService,
    private authService: AuthService,
    private titleService: Title 
  ) {
    if (!this.userName || this.userName.length < 1) {
      if (!this.userLogin) {
        this.authService.login();
      }
    }
  }

  ngOnInit() {
    this.institutionService.getList().subscribe(
      (res: any) => {
        this.contents = res;
        this.dataSource = new MatTableDataSource(res);
        if (this.paginator) {
          this.dataSource.paginator = this.paginator;
        }
        console.log('existing');
        console.log(this.contents);
      },
      (err) => {
        console.log(err);
      }
    );
    this.titleService.setTitle('SenecaPathwaysWeb');
  }

  /**
   * Set the paginator and sort after the view init since this component will
   * be able to query its view for the initialized paginator and sort.
   */
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    // setTimeout(() => this.dataSource.paginator = this.paginator);
    // this.dataSource.sort = this.sort;

    this.institutionService.getList().subscribe(
      (res: any) => {
        this.contents = res;
        this.dataSource = new MatTableDataSource(res);
        if (this.sort) {
          if (this.tableStart) {
            this.sort.start = this.tableStart == 'asc' ? 'asc' : 'desc';
          }
          if (this.tableActive) {
            this.sort.active = this.tableActive;
          }
          if (this.tableDirection) {
            this.sort.direction = this.tableDirection == 'asc' ? 'asc' : 'desc';
          }
        }
        if (this.tablePageSize && this.paginator) {
          this.paginator.pageSize = +this.tablePageSize;
        }
        if (this.sort && this.paginator) {
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  applyFilter($event: any) {
    var filterValue = $event.target.value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  selectedTabIndex: number = 0;
  selectedIndexChange(index: number) {
    // setTimeout(() =>
    // this.selectedTabIndex = index;
    if (index == 0) {
      this.dataSource.data = this.contents;
    } else if (index == 1) {
      var data = this.contents.filter((x) => x.status != '0');
      this.dataSource.data = data;
    } else if (index == 2) {
      var data = this.contents.filter((x) => x.status == '0');
      this.dataSource.data = data;
    }
    // if(index==0){
    //   this.dataSource.data=initialData1;
    // }else if(index==1){
    //   var data =initialData1.filter(x=>x.name!='U of T');
    //   this.dataSource.data = data;

    // }else if(index==2){
    //   var data =initialData1.filter(x=>x.name=='U of T');
    //   this.dataSource.data = data;
    // }
  }

  updateInstitution(e: Event, data: Institution): void {
    e.stopPropagation();
    const clickedElement = e.target as HTMLButtonElement;
    if (clickedElement.className.includes('mat-icon')) {
      return;
    }
    const navigationDetails: string = '/InstitutionDetails';
    data.action = 'Update';
    this.router.navigateByUrl(navigationDetails, { state: data });
  }

  addInstition(): void {
    const navigationDetails: string = '/InstitutionDetails';
    var data = { action: 'Add' };
    this.router.navigateByUrl(navigationDetails, { state: data });
    // const navigationDetails: string[] = ['/InstitutionDetails'];
    // this.router.navigate(navigationDetails);
  }

  addInstitionList(): void {
    if (this.sort && this.paginator) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

      sessionStorage.setItem('start', this.sort.start);
      sessionStorage.setItem('active', this.sort.active);
      sessionStorage.setItem('direction', this.sort.direction);
      sessionStorage.setItem('pageSize', this.paginator.pageSize.toString());
    }
  }

  openDialog(action: string, obj: { action: any }, e: MouseEvent) {
    const clickedElement = e.target as HTMLButtonElement;
    if (clickedElement && action == 'Update') {
      return;
    }
    obj.action = action;
    const dialogRef = this.dialog.open(InstitutionDetailsComponent, {
      width: '40%',
      data: obj,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Add') {
        this.addRowData(result.data);
      } else if (result.event == 'Update') {
        this.updateRowData(result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowData(result.data);
      } else if (result.event == 'Archive') {
        this.archiveRowData(result.data);
      }
    });
  }

  addRowData(row_obj: {
    name: string;
    id: any;
    status: any;
    country: any;
    province_state: any;
    notes: any;
  }) {
    const data = this.dataSource.data;
    const newInst: Institution = {
      name: row_obj.name,
      id: row_obj.id,
      status: row_obj.status,
      modified_by: this.userName ? this.userName : '',
      modified_date: new Date(),
      country: row_obj.country,
      province_state: row_obj.province_state,
      notes: row_obj.notes,
      contacts: [],
      contacts_deleted: [],
      programs: [],
      program_deleted: [],
      program_archived: [],
      action: '',
    };

    data.push(newInst);

    this.dataSource.data = data;
    if (this.table) {
      this.table.renderRows();
    }
    this._snackBar.open(row_obj.name, 'Added', {
      duration: 2000,
    });
  }

  updateRowData(row_obj: {
    name: string;
    status: string;
    country: string;
    province_state: string;
    notes: string;
  }) {
    const data = this.dataSource.data;
    data.filter((value, key) => {
      if (value.name == row_obj.name) {
        value.status = row_obj.status;
        value.modified_by = '';
        value.modified_date = new Date();
        value.country = row_obj.country;
        value.province_state = row_obj.province_state;
        value.notes = row_obj.notes;
      }
      this.dataSource.data = data;
      this._snackBar.open(row_obj.name, 'Updated', {
        duration: 2000,
      });
      return true;
    });
  }

  deleteRowData(row_obj: Institution) {
    console.log('THis is Row Data');
    console.log(row_obj);
    const data = this.dataSource.data;
    this.institutionService.deleteInstitution(row_obj).subscribe();
    this.dataSource.data = data.filter((value, key) => {
      return value.name != row_obj.name;
    });
    this._snackBar.open(row_obj.name, 'Deleted', {
      duration: 2000,
    });
  }

  archiveRowData(row_obj: Institution) {
    const data = this.dataSource.data;
    row_obj.modified_by = this.userName ? this.userName : '';
    this.institutionService.archiveInstitution(row_obj).subscribe();
    data.filter((value, key) => {
      if (value.name == row_obj.name) {
        value.status = '0';
      }
      // return value.name != row_obj.name;
    });
    this.dataSource.data = data;
    this._snackBar.open(row_obj.name, 'Archived', {
      duration: 2000,
    });
    return true;
  }
}

const initialData1: Institution[] = [];

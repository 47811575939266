import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  DTContact,
  DTPathway,
  DTProgram,
  DTInstitution,
  DTInstitutionDetails,
  DegreeTransferService,
} from 'src/app/services/degree-transfer.service';
import {
  Institution,
  InstitutionService,
} from 'src/app/services/institution-service';
import { DtInstitutionDetailsComponent } from '../dt-institution-details/dt-institution-details.component';
import { AuthService } from 'src/app/auth_generic/auth.service';
import { AppComponent } from 'src/app/app.component';
import { MsalService } from '@azure/msal-angular';
import * as XLSX from 'xlsx';
import { Title } from '@angular/platform-browser'; 

const initialData1: DTInstitution[] = [];

@Component({
  selector: 'app-dt-institution-list',
  templateUrl: './dt-institution-list.component.html',
  styleUrls: ['./dt-institution-list.component.scss'],
})
export class DtInstitutionListComponent implements OnInit {
  local_data: any;
  dataSource = new MatTableDataSource(initialData1);

  displayedColumns = ['name', 'location', 'delete'];
  userName = sessionStorage.getItem('user');
  userLogin = sessionStorage.getItem('userLogin');
  private sort: MatSort | null = null;
  private paginator: MatPaginator | null = null;
  contents: Institution[] = [];

  /* used for excel export */
  exportInstitutions: DTInstitution[] | undefined = [];
  exportContacts: DTContact[] | undefined = [];
  exportPathways: DTPathway[] | undefined = [];
  exportPrograms: DTProgram[] | undefined = [];

  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    setTimeout(() => (this.dataSource.sort = this.sort));
  }

  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    mp: MatPaginator
  ) {
    this.paginator = mp;
    setTimeout(() => (this.dataSource.paginator = this.paginator));
  }

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router,
    private institutionService: InstitutionService,
    private dtService: DegreeTransferService,
    private authService: AuthService,
    private msalService: MsalService,
    private appService: AppComponent,
    private titleService: Title 
  ) {
    if (!this.userName || this.userName.length < 1) {
      if (!this.userLogin) {
        this.authService.login();
      }
    }
  }

  ngOnInit() {
    this.dtService.getInstitutionList().subscribe(
      (res: any) => {
        this.contents = res;
        this.dataSource = new MatTableDataSource(res);
        if (this.paginator) {
          this.dataSource.paginator = this.paginator;
        }
       // console.log('existing');
       // console.log(this.contents);
      },
      (err) => {
        console.log(err);
      }
    );
    this.titleService.setTitle('SenecaPathwaysWeb');
  }

  cancelAction(): void {
    const navigationDetails: string[] = ['/Degree-Transfer-Institution'];
    this.router.navigate(navigationDetails);
  }

  openDialog(action: any, obj: { action: any }, e: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(DtInstitutionDetailsComponent, {
      width: '40%',
      data: obj,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Delete') {
        this.deleteRowData(result.data);
      }
    });
  }

  updateInstitution(e: any, data: Institution): void {
    e.stopPropagation();
    if (e.target.className.includes('mat-icon')) {
      return;
    }
    data.action = 'Update';
    const navigationDetails: string = '/Degree-Transfer-Institution-Details';
    this.router.navigateByUrl(navigationDetails, { state: data });
  }

  addDTInstition(): void {
    const navigationDetails: string = '/Degree-Transfer-Institution-Details';
    var data = { action: 'Add' };
    this.router.navigateByUrl(navigationDetails, { state: data });
  }

  deleteRowData(row_obj: any) {
    const data = this.dataSource.data;
    this.dtService.removeInstitution(row_obj).subscribe();
    this.dataSource.data = data.filter((value, key) => {
      return value.institution_name != row_obj.institution_name;
    });
    this._snackBar.open(row_obj.name, 'Deleted', {
      duration: 2000,
    });
  }

  applyFilter($event: any) {
    var filterValue = $event.target.value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  async fetchData() {
    this.exportInstitutions = await this.dtService
      .getInstitutionList()
      .toPromise();
    this.exportContacts = await this.dtService.getContacts().toPromise();
    this.exportPathways = await this.dtService.getPathways().toPromise();
    this.exportPrograms = await this.dtService.getAllPrograms().toPromise();
  }
  async exportData() {
    await this.fetchData();
    let date_ob = new Date();
    let year = date_ob.getFullYear();
    let month = ('0' + (date_ob.getMonth() + 1)).slice(-2);
    let date = ('0' + date_ob.getDate()).slice(-2);
    let hours = date_ob.getHours();
    let minutes = date_ob.getMinutes();

    var wb = XLSX.utils.book_new();

    if (
      this.exportInstitutions &&
      this.exportContacts &&
      this.exportPathways &&
      this.exportPrograms
    ) {
      var ws = XLSX.utils.json_to_sheet(this.exportInstitutions);
      XLSX.utils.book_append_sheet(wb, ws, 'institutions'); //sheet1

      ws = XLSX.utils.json_to_sheet(this.exportContacts);
      XLSX.utils.book_append_sheet(wb, ws, 'contacts'); //sheet2

      ws = XLSX.utils.json_to_sheet(this.exportPathways);
      XLSX.utils.book_append_sheet(wb, ws, 'pathways'); //sheet3

      ws = XLSX.utils.json_to_sheet(this.exportPrograms);
      XLSX.utils.book_append_sheet(wb, ws, 'programs'); //sheet4
    }
    XLSX.writeFile(
      wb,
      'degree-transfer-export_' +
        year +
        month +
        date +
        '_' +
        hours +
        minutes +
        '.xlsx'
    );
  }
}

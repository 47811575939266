import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Email, CustomValidators } from 'src/app/services/institution-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-institution-email',
  templateUrl: './institution-email.component.html',
  styleUrls: ['./institution-email.component.scss'],
})
export class InstitutionEmailComponent implements OnInit {
  formGroup: FormGroup;
  // action:string;
  local_data: any;
  myDate = new Date();
  constructor(
    public dialogRef: MatDialogRef<InstitutionEmailComponent>,
    //@Optional() is used to prevent error if no data is passed  private datePipe: DatePipe
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Email,
    private _formBuilder: FormBuilder,
    private customValidators: CustomValidators
  ) {
    this.local_data = { ...data };
    this.local_data.email_from = environment.emailFrom;
    // this.action = this.local_data.action;
    //Validators.email

    this.formGroup = this._formBuilder.group({
      email_from: [null, { validators: [], updateOn: 'change' }],
      email_from_bcc: [null, { validators: [], updateOn: 'change' }],
      email_to: [null, { validators: [], updateOn: 'change' }],
      email_subject: [
        null,
        [this.customValidators.nameValidator],
      ],
      email_message: [
        null,
        [this.customValidators.nameValidator],
      ],
    });
  }

  ngOnInit() {}

  setFromBccChanges(notify: boolean) {
    this.local_data.From_Bcc = notify ? '1' : '0';
    this.local_data.email_from = environment.emailFrom;
  }
  doAction() {
    this.dialogRef.close({ event: 'Add', data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}


<div class="main-sidebar">
  <!-- The sidebar -->

  <div class="sidebar navbar-dark menu-bkg col-md-2 col-sm-12">
    <p></p>
    <p></p>
    <h1 class="text-align-center">
      <a [routerLink]="['/']">
        <img width="150px" alt="Seneca" src="../assets/seneca-logo-red.svg"
      /></a>
    </h1>

    <ul class="navbar-nav mr-auto col-sm-12">
      <li class="nav-item">
        <a class="nav-link waves-light">ACADEMIC PATHWAYS</a>
      </li>
      <p></p>
      <p></p>
      <p></p>
      <li class="nav-item">
        <a routerLink="/" class="nav-link waves-light">
          Welcome {{ userName }} !</a
        >
      </li>

      <p></p>
      <p></p>
      <p></p>

      <li class="nav-item">
        <a
          routerLink="/Institution"
          [routerLinkActive]="'router-link-active'"
          class="nav-link waves-light"
        >
          <span class="material-icons">business</span> &nbsp;&nbsp; Partner
          Institutions <span class="sr-only">(current)</span></a
        >
      </li>
      <li class="nav-item">
        <a
          routerLink="/Pathway"
          [routerLinkActive]="'router-link-active'"
          class="nav-link waves-light"
          ><span class="material-icons"> insights </span> &nbsp;&nbsp; Seneca
          Pathways</a
        >
      </li>
      <hr />
      <li class="nav-item">
        <a
          routerLink="/Degree-Transfer-Institution"
          [routerLinkActive]="'router-link-active'"
          class="nav-link waves-light"
        >
          <span class="material-icons">school</span> &nbsp;&nbsp; Degree
          Transfer Institutions <span class="sr-only">(current)</span></a
        >
      </li>
      <li class="nav-item">
        <a
          routerLink="/Degree-Transfer-Programs"
          [routerLinkActive]="'router-link-active'"
          class="nav-link waves-light"
        >
          <span class="material-icons">class</span> &nbsp;&nbsp; Degree Transfer
          Programs <span class="sr-only">(current)</span></a
        >
      </li>

      <li class="nav-item" *ngIf="userName && userName.length > 0">
        <!-- <li class="nav-item" *ngIf="loginDisplay"> -->
        <a routerLink="" (click)="logout()" class="nav-link waves-light"
          ><span class="material-icons"> logout </span> &nbsp;&nbsp; Sign Out</a
        >
      </li>
      <li class="nav-item" *ngIf="!userName || userName.length < 1">
        <!-- <li class="nav-item" *ngIf="!loginDisplay"> -->
        <a [routerLink]="" (click)="login()"
          ><span class="material-icons"> login </span> &nbsp;&nbsp; Sign In</a
        >
      </li>
    </ul>
  </div>

 
  <div
    #NoaccessDiv
    class="errorMsg text-danger"
    *ngIf="!userName || userName.length < 1"
  >
    You don't have admin access to this portal
  </div>
  <div *ngIf="userName && userName.length > 0">Login Successful</div>

  <div class="col-md-12">
    <router-outlet></router-outlet>
  </div>
</div>
<app-print-layout></app-print-layout>
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  DTCustomValidators,
  DTProgram,
  DegreeTransferService,
} from 'src/app/services/degree-transfer.service';
import { DtInstitutionContentComponent } from '../dt-institution-content/dt-institution-content.component';

@Component({
  selector: 'app-dt-program-details',
  templateUrl: './dt-program-details.component.html',
  styleUrls: ['./dt-program-details.component.scss'],
})
export class DtProgramDetailsComponent implements OnInit {
  formGroup: FormGroup;
  action: string;
  local_data: any;
  userName = sessionStorage.getItem('user');

  @ViewChild('elseTemplateDiv') elseTemplateDiv: any;
  @ViewChild('TemplateDiv') TemplateDiv: any;
  @ViewChild('elseTemplateC') elseTemplateC: any;
  @ViewChild('elseTemplateCDiv') elseTemplateCDiv: any;
  @ViewChild('TemplateCDiv') TemplateCDiv: any;

  constructor(
    public dialogRef: MatDialogRef<DtInstitutionContentComponent>,
    private cdr: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DtProgramDetailsComponent,
    private router: Router,
    private _formBuilder: FormBuilder,
    private customValidators: DTCustomValidators,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private degreeTransferService: DegreeTransferService
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
    // this.formGroup = this._formBuilder.group({
    //   "program_code": [null, Validators.compose([Validators.maxLength(10), this.customValidators.nameValidator])],
    //   "program_name": [null, Validators.compose([Validators.maxLength(100), this.customValidators.nameValidator])],
    // });

    this.formGroup = this._formBuilder.group({
      program_code: [
        null,
        [Validators.maxLength(10), this.customValidators.nameValidator],
      ],
      program_name: [
        null,
        [Validators.maxLength(100), this.customValidators.nameValidator],
      ],
    });
  }

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  doAction() {
    const modifiedBy = this.userName || '';
    const newProgram: DTProgram = {
      program_id: this.local_data.program_id,
      program_code: this.local_data.program_code,
      program_name: this.local_data.program_name,
      modified_by: modifiedBy,
      modified_date: new Date(),
    };

    if (this.action == 'Delete') {
      this.degreeTransferService.removeProgram(newProgram).subscribe((res) => {
        this._snackBar.open(
          this.local_data.institution_name,
          'Program deleted',
          {
            duration: 2000,
          }
        );
        const navigationDetails: string[] = ['/Degree-Transfer-Programs'];
        this.router
          .navigateByUrl('/Degree-Transfer-Programs', {
            skipLocationChange: true,
          })
          .then(() => {
            this.router.navigate(navigationDetails);
          });
      });
      //close after delete
      this.dialogRef.close({ event: name, data: this.local_data });
    } else {
      // adding new program
      if (!this.local_data.program_id) {
        this.degreeTransferService.addProgram(newProgram).subscribe((res) => {
          this._snackBar.open(this.local_data.program_code, 'Program Added', {
            duration: 2000,
          });
          const navigationDetails: string[] = ['/Degree-Transfer-Programs'];
          this.router
            .navigateByUrl('/Degree-Transfer-Programs', {
              skipLocationChange: true,
            })
            .then(() => {
              this.router.navigate(navigationDetails);
            });
        });
      }
      // updating existing program
      else {
        this.degreeTransferService
          .updateProgram(newProgram)
          .subscribe((res) => {
            this._snackBar.open(this.local_data.program_code, 'Updated', {
              duration: 2000,
            });
            const navigationDetails: string[] = ['/Degree-Transfer-Programs'];
            this.router
              .navigateByUrl('/Degree-Transfer-Programs', {
                skipLocationChange: true,
              })
              .then(() => {
                this.router.navigate(navigationDetails);
              });
          });
      }
      setTimeout(() => {
        this.dialogRef.close({ event: this.action, data: this.local_data });
      }, 0);
    }
  }

  delete() {
    this.dialogRef.close({ event: 'Delete', data: this.local_data });
  }
}

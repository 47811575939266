<div class="block_container">

  <form [formGroup]="formGroup">
    <h1 mat-dialog-title>
      <strong> {{ action }} Institution Content Details</strong>
    </h1>
    <div class="scrollable-form">

      <div *ngIf="action != 'Delete'; else elseTemplateC">
        <div id="block_container">
          <div id="bloc1" class="col-md-6">
            <div id="bloc_contact_detail_3" class="col-md-2">
            </div>
            <div id="bloc_contact_detail_3" class="col-md-2">
              <button mat-stroked-button (click)="closeDialog()" color="accent">
                Cancel
              </button>
            </div>
          </div>
          <div id="bloc1" class="col-md-6">
            <button mat-button (click)="doAction()" mat-flat-button color="warn">
              Save
            </button>
          </div>
        </div>
        <div id="block_container_contact">
          <div id="bloc_contact_1" class="col-md-8">
            <mat-form-field appearance="fill">
              <mat-label>Heading </mat-label>
              <input
                name="heading"
                matInput
                placeholder="{{ local_data?.heading }}"
                [(ngModel)]="local_data.heading"
                formControlName="heading"
                [required]="false"
              />
            </mat-form-field>
          </div>
        </div>
        <div id="block_container_contact">
          <div class="col-md-12 textBlock">
            <mat-label>Institution Content Details </mat-label>
            <!-- <quill-editor [styles]="{ height: '200px' }" [(ngModel)]="local_data.content"
                      formControlName="content"></quill-editor> -->
            <angular-editor
              [(ngModel)]="local_data.content"
              formControlName="content"
              [config]="editorConfig"
            ></angular-editor>

            <mat-error *ngIf="formGroup.get('content')?.hasError('invalidName')">
              Special characters not allowed!
            </mat-error>
          </div>
        </div>
        <div>
          <ng-template #elseTemplateC>
            Sure to delete
            <b
              >{{ local_data.contact_first_name }}
              {{ local_data.contact_last_name }}</b
            >?
          </ng-template>
        </div>
      </div>
      <div
        mat-dialog-actions
        class="right-align"
        #elseTemplateCDiv
        *ngIf="action == 'Delete'; else TemplateCDiv"
      >
        <button mat-button (click)="doAction()" mat-flat-button color="warn">
          {{ action }}
        </button>
        <button mat-stroked-button (click)="closeDialog()" color="accent">
          Cancel
        </button>
      </div>
    </div>
  </form>

</div>
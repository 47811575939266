import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Optional,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  DTInstitution,
  DegreeTransferService,
  DTCustomValidators,
  DTContact,
  DTInstitutionDetails,
  DTPathway,
} from 'src/app/services/degree-transfer.service';
import { DtInstitutionContactsComponent } from '../dt-institution-contacts/dt-institution-contacts.component';
import { DtInstitutionContentComponent } from '../dt-institution-content/dt-institution-content.component';
import { DtPathwayDetailsComponent } from '../dt-pathway-details/dt-pathway-details.component';
import { PrintService } from '../../services/print.service';
import { Title } from '@angular/platform-browser'; 

@Component({
  selector: 'app-dt-institution-details',
  templateUrl: './dt-institution-details.component.html',
  styleUrls: ['./dt-institution-details.component.scss'],
})
export class DtInstitutionDetailsComponent implements AfterViewInit {
  formGroup: FormGroup;
  local_data: any;

  userName = sessionStorage.getItem('user');

  action = '';
  displayedColumns = [
    'title',
    'first_name',
    'last_name',
    'department',
    'phone_number',
    'email',
    'job_title',
    'contact_publish',
    'delete',
  ];
  displayedColumnsContent = ['heading', 'content', 'delete'];
  displayedColumnPathway = [
    'program_code',
    'degree_name',
    'pathway_description',
    'publish',
    'isMasters',
    'delete',
  ];
  dataSource = new MatTableDataSource(initialData);
  dataSourceContent = new MatTableDataSource(initialDataContent);
  dataSourcePathway = new MatTableDataSource(initialDataPathway);
  private sortC: MatSort | null = null;
  private sortContent: MatSort | null = null;
  private paginator: MatPaginator | null = null;
  pathways: DTPathway[] = [];

  //@ViewChild('elseTemplateDiv') elseTemplateDiv: any;
  //@ViewChild('TemplateDiv') TemplateDiv: any;
  public elseTemplateDiv: any;
  public TemplateDiv: any;

  @ViewChild('tableContacts', { read: MatSort, static: false }) set matSortC(
    ms: MatSort
  ) {
    this.sortC = ms;
    setTimeout(() => (this.dataSource.sort = this.sortC));
  }

  @ViewChild('tableContent', { read: MatSort, static: false })
  set matSortContent(ms: MatSort) {
    this.sortContent = ms;
    setTimeout(() => (this.dataSourceContent.sort = this.sortContent));
  }

  @ViewChild('tableContentPathway', { read: MatSort, static: false })
  set matSortPathway(ms: MatSort) {
    this.sortContent = ms;
    setTimeout(() => (this.dataSourcePathway.sort = this.sortContent));
  }

  // @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
  //   this.sortC = ms;
  //   setTimeout(() => this.dataSource.sort = this.sortC);
  // }

  @ViewChild(MatPaginator, { static: false }) set matPaginatorP(
    mp: MatPaginator
  ) {
    this.paginator = mp;
    setTimeout(() => (this.dataSourcePathway.paginator = this.paginator));
  }
  constructor(
    public dialogRef: MatDialogRef<DtInstitutionDetailsComponent>,
    private cdr: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DTInstitution,
    private router: Router,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private degreeTransferService: DegreeTransferService,
    private customValidators: DTCustomValidators,
    public printService: PrintService,
    private titleService: Title 
  ) {
    this.local_data = { ...data };
    if (!data) {
      const navigation = this.router.getCurrentNavigation();
      if (navigation) {
        this.local_data = navigation.extras.state;
      }
    }
    this.action = this.local_data ? this.local_data.action : '';

    // this.formGroup = this._formBuilder.group({
    //   institution_name: [, Validators.compose([Validators.required, Validators.maxLength(150), this.customValidators.nameValidator])],
    //   institution_location: [, Validators.compose([Validators.required])]
    // })

    this.formGroup = this._formBuilder.group({
      institution_name: [
        ,
        [
          Validators.required,
          Validators.maxLength(150),
          this.customValidators.nameValidator,
        ],
      ],
      institution_location: [, [Validators.required]],
    });
  }

  ngAfterViewInit() {
    this.degreeTransferService.getInstitutionList().subscribe((res: any) => {
      //console.log('finished loading inst-details')
    });
    this.cdr.detectChanges();
  }

  ngOnInit() {
    // data doesnt refresh after saving
    this.degreeTransferService
      .getContactListFromInstitution(this.local_data.institution_id)
      .subscribe(
        (res: any) => {
          this.dataSource = new MatTableDataSource(
            res.filter((x: { institution_id: any }) => {
              return (x.institution_id = this.local_data.institution_id);
            })
          );
          if (this.paginator) {
            this.dataSourcePathway.paginator = this.paginator;
          }
        },
        (err) => {
          console.log(err);
        }
      );

    this.degreeTransferService
      .getInstitutionDetails(this.local_data.institution_id)
      .subscribe(
        (res: any) => {
          this.dataSourceContent = new MatTableDataSource(
            res.filter((x: { institution_id: any }) => {
              return (x.institution_id = this.local_data.institution_id);
            })
          );
        },
        (err) => {
          console.log(err);
        }
      );

    this.degreeTransferService
      .getPathwaysForInstitution(this.local_data.institution_id)
      .subscribe(
        (res: any) => {
          this.pathways = res;
          this.dataSourcePathway = new MatTableDataSource(
            res.filter((x: { institution_id: any }) => {
              return (x.institution_id = this.local_data.institution_id);
            })
          );
          if (this.paginator) {
            this.dataSourcePathway.paginator = this.paginator;
          }
        },
        (err) => {
          console.log(err);
        }
      );
      this.titleService.setTitle(this.local_data.institution_name);
  }

  /* For updating contact information.  Front end button triggers this action */
  openDialog(action: string, obj: any, e: Event) {
    const clickedElement = e.target as HTMLButtonElement;
    if (clickedElement)
      if (clickedElement.className.includes('mat-icon') && action == 'Update') {
        return;
      }
    obj.action = action;
    obj.instId = this.local_data.institution_id;
    const dialogR = this.dialog.open(DtInstitutionContactsComponent, {
      width: '50%',
      data: obj,
    });

    dialogR.afterClosed().subscribe((result) => {
      if (result.event == 'Add') {
        this.addRowData(result.data);
      } else if (result.event == 'Update') {
        this.updateRowData(result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowData(result.data);
      }
    });
  }

  openDialogPathway(action: string, obj: any, e: Event) {
    //console.log('dt inst details openDialogPathway> ' + JSON.stringify(this.local_data));
    const clickedElement = e.target as HTMLButtonElement;
    if (clickedElement.className.includes('mat-icon') && action == 'Update') {
      return;
    }
    obj.action = action;
    obj.instId = this.local_data.institution_id;

    const dialogR = this.dialog.open(DtPathwayDetailsComponent, {
      width: '50%',
      data: obj,
    });
    //console.log('openDialogPathway data> ' + JSON.stringify(obj));
    dialogR.afterClosed().subscribe((result) => {
      console.log('result> ' + result);
      if (result.event == 'Add') {
        this.addRowDataPathway(result.data);
      } else if (result.event == 'Update') {
        this.updateRowDataPathway(result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowDataPathway(result.data);
      }
    });
  }

  /* Fetch contact list after user adds new contact */
  addRowData(row_obj: any) {
    console.log(row_obj);
    const data = this.dataSource.data;
    console.log(data);
    const newRecord: DTContact = {
      contact_id: row_obj.contact_id,
      title: row_obj.title,
      first_name: row_obj.first_name,
      last_name: row_obj.last_name,
      department: row_obj.department,
      phone_number: row_obj.phone_number,
      email: row_obj.email,
      job_title: row_obj.job_title,
      institution_id: this.local_data.institution_id,
      modified_by: this.userName ? this.userName : '',
      modified_date: row_obj.modified_date,
      contact_publish: row_obj.contact_publish,
    };
    data.push(newRecord);

    this.dataSource.data = data;

    this.degreeTransferService.addContact(newRecord).subscribe((result) => {
      console.log(result);
    });
    //this.tableContacts.renderRows();
    this._snackBar.open(row_obj.first_name, 'Added', {
      duration: 2000,
    });
  }

  deleteRowData(row_obj: any) {
    const data = this.dataSource.data;
    this.degreeTransferService.removeContact(row_obj).subscribe();
    this.dataSource.data = data.filter((value, key) => {
      return value.contact_id != row_obj.contact_id;
    });
    this._snackBar.open(row_obj.name, 'Deleted', {
      duration: 2000,
    });
  }

  deleteRowDataPathway(row_obj: { pathway_id: number; name: string }) {
    const data = this.dataSourcePathway.data;
    console.log('dt inst details> deleteRowDataPathway> ');

    // console.log('dt inst details >' + JSON.stringify(data));

    this.dataSourcePathway.data = data.filter((value, key) => {
      return value.pathway_id != row_obj.pathway_id;
    });
    this._snackBar.open(row_obj.name, 'Deleted', {
      duration: 2000,
    });
  }

  addRowDataPathway(row_obj: any) {
    this.degreeTransferService
      .getPathwaysForInstitution(this.local_data.institution_id)
      .subscribe(
        (res: any) => {
          this.dataSourcePathway = new MatTableDataSource(
            res.filter((x: { institution_id: any }) => {
              return (x.institution_id = this.local_data.institution_id);
            })
          );
        },
        (err) => {
          console.log(err);
        }
      );
  }
  updateRowDataPathway(row_obj: any) {
    // need to pre-fill current program_name and program_code
    // also need to disable from editing program_name and program_code-- read_only
    this.degreeTransferService
      .getPathwaysForInstitution(this.local_data.institution_id)
      .subscribe(
        (res: any) => {
          this.dataSourcePathway = new MatTableDataSource(
            res.filter((x: { institution_id: any }) => {
              return (x.institution_id = this.local_data.institution_id);
            })
          );
        },
        (err) => {
          console.log(err);
        }
      );
  }

  /* Re-fetch contact list after updating contact list */
  updateRowData(row_obj: any) {
    console.log(row_obj);
    const data = this.dataSource.data;
    console.log(data);
    const newRecord: DTContact = {
      contact_id: row_obj.contact_id,
      title: row_obj.title,
      first_name: row_obj.first_name,
      last_name: row_obj.last_name,
      department: row_obj.department,
      phone_number: row_obj.phone_number,
      email: row_obj.email,
      job_title: row_obj.job_title,
      institution_id: this.local_data.institution_id,
      modified_by: this.userName ? this.userName : '',
      modified_date: row_obj.modified_date,
      contact_publish: row_obj.contact_publish,
    };
    let contactIndex = data.findIndex(
      (element) => element.contact_id === newRecord.contact_id
    );
    data[contactIndex] = newRecord;
    this.dataSource.data = data;
    // dont need to refresh institution detials
    this.degreeTransferService.updateContact(newRecord).subscribe();

    this._snackBar.open(row_obj.name, 'Updated', {
      duration: 2000,
    });
  }

  /* For updating institution content. */
  openDialogContent(action: string, obj: any, e: Event) {
    // console.log('open dialog for content> ' + JSON.stringify(this.local_data));
    const clickedElement = e.target as HTMLButtonElement;
    if (clickedElement.className.includes('mat-icon') && action == 'Update') {
      return;
    }

    obj.action = action;
    obj.instId = this.local_data.institution_id;

    const dialogR = this.dialog.open(DtInstitutionContentComponent, {
      width: '50%',
      data: obj,
    });
    console.log('data> ' + JSON.stringify(obj));
    dialogR.afterClosed().subscribe((result) => {
      if (result.event == 'Add') {
        this.addRowDataContent(result.data);
      } else if (result.event == 'Update') {
        this.updateRowDataContent(result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowDataContent(result.data);
      }
    });
  }

  addRowDataContent(row_obj: any) {
    // force update of content
    this.degreeTransferService
      .getInstitutionDetails(this.local_data.institution_id)
      .subscribe(
        (res: any) => {
          this.dataSourceContent = new MatTableDataSource(
            res.filter((x: { institution_id: any }) => {
              return (x.institution_id = this.local_data.institution_id);
            })
          );
        },
        (err) => {
          console.log(err);
        }
      );
  }

  updateRowDataContent(row_obj: any) {
    const data = this.dataSourceContent.data;
    this.degreeTransferService
      .getInstitutionDetails(this.local_data.institution_id)
      .subscribe(
        (res: any) => {
          this.dataSourceContent = new MatTableDataSource(
            res.filter((x: { institution_id: any }) => {
              return (x.institution_id = this.local_data.institution_id);
            })
          );
        },
        (err) => {
          console.log(err);
        }
      );
  }

  deleteRowDataContent(row_obj: {
    institution_detail_id: number;
    name: string;
  }) {
    const data = this.dataSourceContent.data;
    this.dataSourceContent.data = data.filter((value, key) => {
      return value.institution_detail_id != row_obj.institution_detail_id;
    });
    this._snackBar.open(row_obj.name, 'Deleted', {
      duration: 2000,
    });
  }

  cancelAction(): void {
    const navigationDetails: string[] = ['/Degree-Transfer-Institution'];
    this.router.navigate(navigationDetails);
  }

  // deleteInsData(row_obj: { modified_by: string | null; name: string }) {
  //   row_obj.modified_by = this.userName;

  //   this._snackBar.open(row_obj.name, 'Delete', {
  //     duration: 2000,
  //   });
  //   const navigationDetails: string[] = ['/Degree-Transfer-Institution'];
  //   this.router
  //     .navigateByUrl('/Degree-Transfer-Institution', {
  //       skipLocationChange: true,
  //     })
  //     .then(() => {
  //       this.router.navigate(navigationDetails);
  //     });
  // }

  deleteInsData(row_obj: { modified_by: string | null; name: string }) {
    row_obj.modified_by = this.userName;

    this._snackBar.open(row_obj.name, 'Delete', {
      duration: 2000,
    });
    const navigationDetails: string[] = ['/Degree-Transfer-Institution'];
    this.router
      .navigateByUrl('/Degree-Transfer-Institution', {
        skipLocationChange: true,
      })
      .then(() => {
        this.router.navigate(navigationDetails);
      });
  }

  doAction() {
    const newInstitution = {
      institution_id: this.local_data.institution_id,
      institution_name: this.local_data.institution_name,
      institution_location: this.local_data.institution_location,
    };
    if (this.action == 'Delete') {
      console.log('This is delete');
      this.degreeTransferService
        .removeInstitution(newInstitution)
        .subscribe((res) => {
          this._snackBar.open(this.local_data.institution_name, 'Deleted', {
            duration: 2000,
          });
          const navigationDetails: string[] = ['/Degree-Transfer-Institution'];
          this.router
            .navigateByUrl('/Degree-Transfer-Institution', {
              skipLocationChange: true,
            })
            .then(() => {
              this.router.navigate(navigationDetails);
            });
        });
      //close after delete
      this.dialogRef.close({ event: name, data: this.local_data });
    } else {
      // trying to insert new institution
      if (!this.local_data.institution_id) {
        this.degreeTransferService
          .addInstitution(newInstitution)
          .subscribe((res) => {
            this._snackBar.open(this.local_data.institution_name, 'Added', {
              duration: 2000,
            });
            const navigationDetails: string[] = [
              '/Degree-Transfer-Institution',
            ];
            this.router
              .navigateByUrl('/Degree-Transfer-Institution', {
                skipLocationChange: true,
              })
              .then(() => {
                this.router.navigate(navigationDetails);
              });
          });
      } else {
        // updating institution name
        this.degreeTransferService
          .updateInstitution(newInstitution)
          .subscribe((res) => {
            this._snackBar.open(this.local_data.institution_name, 'Updated', {
              duration: 2000,
            });
            const navigationDetails: string[] = [
              '/Degree-Transfer-Institution',
            ];
            this.router
              .navigateByUrl('/Degree-Transfer-Institution', {
                skipLocationChange: true,
              })
              .then(() => {
                this.router.navigate(navigationDetails);
              });
          });
      }
    }
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  deleteInstitution(name: string) {
    this.dialogRef.close({ event: name, data: this.local_data });
  }

  // call service to print the current page by passing the instituion id
  async onPrintInstitution() {
    await this.printService.changeInstitutionId(this.local_data.institution_id);
    await this.printService.setIsPrinting(true);
    setTimeout(()=> {
      window.print();
    }, 1000);
    setTimeout(()=> {
      location.reload();  // force page reload because the print layout is still visible
    }, 1000);
  }}

var initialData: DTContact[] = [];
var initialDataContent: DTInstitutionDetails[] = [];
var initialDataPathway: DTPathway[] = [];

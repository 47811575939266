<form [formGroup]="formGroup" class="institution-form">
  <h1 mat-dialog-title>
    <strong>{{ action }} Institution Program</strong>
  </h1>
  <mat-dialog-content class="mat-typography">
    <div>
      <div
        *ngIf="action != 'Delete' && action != 'Archive'; else elseTemplateP"
      >
        <div id="block_container_progDetails">
          <div id="bloc_prog_detail_1" class="col-md-8">
            <mat-form-field appearance="fill">
              <mat-label>Program Name </mat-label>
              <input
                name="program_name"
                matInput
                placeholder="{{ local_data?.program_name }}"
                [(ngModel)]="local_data.program_name"
                formControlName="program_name"
                [required]="true"
              />
              <mat-error
                *ngIf="formGroup.get('program_name')?.hasError('required')"
              >
                name is <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="formGroup.get('program_name')?.hasError('invalidName')"
              >
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
          <div id="bloc_prog_detail_1" class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>Credential Level </mat-label>
              <mat-select
                name="program_cred"
                [(ngModel)]="local_data.program_cred"
                formControlName="program_cred"
                [required]="true"
              >
                <mat-option
                  *ngFor="let c of credential_level"
                  [value]="c.cred_code"
                >
                  {{ c.cred_desc }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="formGroup.get('program_cred')?.hasError('required')"
              >
                credential level is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div id="block_container_progDetails">
          <div id="bloc_prog_detail_1" class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>Program Code </mat-label>
              <input
                name="program_code"
                matInput
                placeholder="{{ local_data?.program_code }}"
                [(ngModel)]="local_data.program_code"
                formControlName="program_code"
              />
              <mat-error
                *ngIf="formGroup.get('program_code')?.hasError('invalidName')"
              >
                Special characters not allowed!
              </mat-error>
              <!-- <mat-error   *ngIf="formGroup.get('program_code').hasError('required')">   code is <strong>required</strong>   </mat-error> -->
            </mat-form-field>
          </div>
          <div id="bloc_prog_detail_1" class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>MCU Code </mat-label>
              <input
                name="mcu_code"
                matInput
                placeholder="{{ local_data?.mcu_code }}"
                [(ngModel)]="local_data.mcu_code"
                formControlName="mcu_code"
              />
              <mat-error
                *ngIf="formGroup.get('mcu_code')?.hasError('invalidName')"
              >
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>

          <div id="bloc_prog_detail_2" class="col-md-4">
            <!-- <mat-form-field appearance="fill" > -->
            <!-- <mat-checkbox name="program_completed" [checked]="local_data?.program_completed"
                (change)="setComplete($event.checked)">Program Completed
                </mat-checkbox> -->
            <!-- </mat-form-field> mat-dialog-title-->
          </div>
        </div>
      </div>
      <div class="lastMod" *ngIf="action != 'Delete' && action != 'Archive'">
        Last modified by: {{ local_data?.modified_by }} on
        {{ lastModifiedDate | date }}
      </div>
      <div class="lastMod" *ngIf="action != 'Delete' && action != 'Archive'">
        Status: {{ local_data?.program_status == "0" ? "Inactive" : "Active" }}
      </div>
      <div class="lastMod" *ngIf="action != 'Delete' && action != 'Archive'">
        Program ID: {{ local_data?.program_id }}
      </div>
      <div>
        <ng-template #elseTemplateP>
          Sure to {{ action }} <b>{{ local_data.program_name }} </b>?
        </ng-template>
      </div>
    </div>
  </mat-dialog-content>
  <!-- <div class="col-md-6"></div> -->
  <div
    mat-dialog-actions
    id="block_container_progDetails3"
    #TemplatePDiv
    *ngIf="action != 'Delete' && action != 'Archive'; else elseTemplatePDiv"
  >
    <!-- <span *ngIf="action=='Update'" class="mat-archive cursor_point" (click)="archive()" ><mat-icon  color="archive"  >archive</mat-icon> Archive</span> -->
    <div class="col-md-4"></div>
    <div id="bloc_prog_detail_3" class="col-md-2">
      <button
        mat-button
        (click)="archive()"
        [disabled]="action != 'Update'"
        color="archive"
      >
        <mat-icon color="archive">archive</mat-icon> Archive
      </button>
    </div>
    <div id="bloc_prog_detail_3" class="col-md-2">
      <!-- <span *ngIf="action=='Update'" class="mat-archive cursor_point" (click)="delete()" ><mat-icon  color="archive"  >delete_outline</mat-icon> Delete</span> -->
      <button
        mat-button
        (click)="delete()"
        [disabled]="action != 'Update'"
        color="archive"
      >
        <mat-icon color="archive">delete_outline</mat-icon> Delete
      </button>
    </div>
    <div id="bloc_prog_detail_3" class="col-md-2">
      <button mat-stroked-button (click)="closeDialog()" color="accent">
        Cancel
      </button>
    </div>
    <div id="bloc_prog_detail_3" class="col-md-2">
      <button
        mat-button
        (click)="doAction()"
        [disabled]="!formGroup.valid"
        mat-flat-button
        color="warn"
      >
        Save
      </button>
    </div>
  </div>

  <div
    mat-dialog-actions
    class="right-align-2"
    #elseTemplatePDiv
    *ngIf="action == 'Delete' || action == 'Archive'; else TemplatePDiv"
  >
    <button mat-button (click)="doAction()" mat-flat-button color="warn">
      {{ action }}
    </button>
    <button mat-stroked-button (click)="closeDialog()" color="accent">
      Cancel
    </button>
  </div>
  <p></p>
  <p></p>
</form>

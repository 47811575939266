import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  Institution,
  InboundProgram,
  InstitutionService,
  InstitutionProgram,
} from 'src/app/services/institution-service';

@Component({
  selector: 'app-pathways-inbound-programs',
  templateUrl: './pathways-inbound-programs.component.html',
  styleUrls: ['./pathways-inbound-programs.component.scss'],
})
export class PathwaysInboundProgramsComponent implements OnInit {
  instList: Institution[] = [];
  progList: InstitutionProgram[] = [];
  selectPrograms: InboundProgram[] = [];
  formGroup: FormGroup;
  action: string = 'Add';
  local_data: any;
  displayedColumnsP = [
    'primary',
    'program_code',
    'program_name',
    'mcu_code',
    'program_cred',
  ];
  dataSourceP = new MatTableDataSource(initialDataPrograms);
  allSelect: boolean = false;
  anyProgramsExists: boolean = false;

  private sort: MatSort | null = null;
  private paginator: MatPaginator | null = null;

  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    setTimeout(() => (this.dataSourceP.sort = this.sort));
  }

  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    mp: MatPaginator
  ) {
    this.paginator = mp;
    setTimeout(() => (this.dataSourceP.paginator = this.paginator));
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: InboundProgram,
    public dialogRef: MatDialogRef<PathwaysInboundProgramsComponent>,
    private institutionService: InstitutionService,
    private _formBuilder: FormBuilder
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;

    this.formGroup = this._formBuilder.group({
      inst_name: [, { validators: [], updateOn: 'change' }],
    });
  }

  ngOnInit() {
    this.institutionService.getList().subscribe(
      (res: any) => {
        this.instList = res.filter((x: Institution) => x.status != '0');
        // this.dataSource=new MatTableDataSource(res);
      },
      (err) => {
        console.log(err);
      }
    );

    this.institutionService.getInboundProgramsList().subscribe(
      (res: any) => {
        this.progList = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  applyFilter(e: Event) {
    const clickedEvent = e.target as HTMLButtonElement;
    let filterValue = clickedEvent.value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSourceP.filter = filterValue;
  }

  InstSelectChange() {
    var programs = this.progList.filter(
      (x) =>
        x.institution_id == this.local_data.inst_name && x.program_status != '0'
    );
    this.dataSourceP = new MatTableDataSource(programs);
    this.anyProgramsExists = programs.length > 0;
  }

  selectAllChanges(allSelected: boolean) {
    this.allSelect = allSelected;
    if (!this.dataSourceP || !this.dataSourceP.data) {
      return;
    }
    this.dataSourceP.data.forEach((t) => {
      t.selected = allSelected;
      return this.programPick(t, allSelected);
    });
  }

  programPick(obj: any, notify: boolean) {
    if (notify) {
      this.primaryClick(obj);
    }
  }

  primaryClick(element: InboundProgram) {
    if (!this.selectPrograms) {
      this.selectPrograms = [];
    }
    // this.selectPrograms = [];
    this.selectPrograms.push(element);
    // console.log(this.selectPrograms);
  }

  doAction() {
    if (
      this.action == 'Delete' ||
      this.action == 'DeleteF' ||
      this.action == 'ArchiveF'
    ) {
      this.dialogRef.close({ event: this.action, data: this.local_data });
    } else {
      this.dialogRef.close({ event: this.action, data: this.selectPrograms });
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}

var initialDataPrograms: InstitutionProgram[] = [];

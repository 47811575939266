import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  DTCustomValidators,
  DTPathway,
  DTProgram,
  DegreeTransferService,
} from 'src/app/services/degree-transfer.service';
import { DtInstitutionContentComponent } from '../dt-institution-content/dt-institution-content.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-dt-pathway-details',
  templateUrl: './dt-pathway-details.component.html',
  styleUrls: ['./dt-pathway-details.component.scss'],
})
export class DtPathwayDetailsComponent implements OnInit {
  userName = sessionStorage.getItem('user');
  formGroup: FormGroup;
  action: string;
  local_data: any;
  instId: any;
  search: any;
  selectedProgram: any;

  programs: DTProgram[] = []; // all programs
  programs_filtered: DTProgram[] = []; // programs after the search term
  program_code_populate: DTProgram[] = [];
  program_code: any;
  display_program_name: any;
  csvList!: string;
  selectedPrograms = [];
  programs_currently_set!: DTProgram[]; // use this to exclude from the drop down
  programForm!: FormControl;

  @ViewChild('elseTemplateC') elseTemplateC: any;
  @ViewChild('TemplateCDiv') TemplateCDiv: any;

  constructor(
    public dialogRef: MatDialogRef<DtInstitutionContentComponent>,
    private cdr: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DtPathwayDetailsComponent,
    private router: Router,
    private _formBuilder: FormBuilder,
    private customValidators: DTCustomValidators,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private degreeTransferService: DegreeTransferService,
    private titleService: Title
  ) {
    this.local_data = { ...data };
    var tmp = this.local_data.program_name;
    this.local_data.program_name = [tmp];

    this.csvList = this.local_data.program_code; // testing this search field popiulation
    this.action = this.local_data ? this.local_data.action : '';
    this.instId = this.local_data.instId;
    console.log(
      'dt pathway details> constructor> ' + JSON.stringify(this.local_data)
    );
    if (!data) {
      this.local_data = this.router.getCurrentNavigation()?.extras.state;
    }

    // this.degreeTransferService.getAllPrograms()
    //   .subscribe((res: any) => {
    //     this.programs = res;
    //     this.programs_filtered = this.programs;
    //   }, err => {
    //     console.log(err);
    //   })

    this.formGroup = this._formBuilder.group({
      degree_name: [null, Validators.compose([])],
      program_code: [
        null,
        { validators: [Validators.maxLength(100)], updateOn: 'change' },
      ],
      display_program_name: [
        null,
        { validators: [Validators.maxLength(100)], updateOn: 'change' },
      ],

      program_name: [
        null,
        { validators: [Validators.maxLength(100)], updateOn: 'change' },
      ],
      search: [null, Validators.compose([])],
      pathway_description: [null, Validators.compose([])],
    });

    if (this.action == 'Update') {
      this.formGroup.get('program_code')?.disable();
    }

    if (this.action == 'Add') {
      this.formGroup.get('program_code')?.disable(); //never let user change program code
    }
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    // height: '15rem',
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    // minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    showToolbar: true,
    sanitize: false,
    toolbarHiddenButtons: [],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  ngOnInit() {
    //console.log('dt pathway details ngOnit');

    this.degreeTransferService.getAllPrograms().subscribe(
      (res: any) => {
        this.programs = res;
        this.programs_filtered = this.programs;

        // prepopulate the drop down if user selected previously
        if (this.local_data.program_code != null) {
          this.local_data.program_name =
            this.local_data.program_code.split(','); // THIS WORKS
        }

        this.degreeTransferService
          .getPathwaysForInstitution(this.instId)
          .subscribe(
            (res: any) => {
              this.programs_currently_set = res;
            },
            (err) => {
              console.log(err);
            }
          );
      },
      (err) => {
        console.log(err);
      }
    );

    //  fetch only offered programs to filter through master list

    // see if we can get the current offered programs somewhere./...sajklfjlaksjdflkajsdlk
    //bomb on purpose here
    // console.log('local2> ' + JSON.stringify(this.programs_currently_set));

    // this.programs_filtered = this.programs_filtered.filter(item => {
    //   !this.programs_currently_set.includes(item);
    // })

    this.local_data.display_program_name = this.local_data.program_name;
    //this.local_data.degree_name =
    this.titleService.setTitle(this.local_data.institution_name); // set the title of the page for convenient naming of PDF printing

  }
  ngAFterViewInit() {
    this.cdr.detectChanges();
  }

  checkIfUpdate() {
    if (this.local_data.action == 'Update') {
      return true;
    } else {
      return false;
    }
  }

  clearSearchInput() {
    this.local_data.search = '';
  }

  /*
  Hide programs if not matching search term by user
   */
  onKey(event: any) {
    // don't filter programs already selected
    var programList = (this.csvList + '').split(',');

    var programListFiltered = this.programs_filtered.filter((x) => {
      return programList.includes(x.program_code);
    });

    this.programs_filtered = this.programs.filter((x) => {
      return (
        x.program_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        x.program_code.toLowerCase().includes(event.target.value.toLowerCase())
      );
    });

    // take elements out before adding it in the next step
    this.programs_filtered = this.programs_filtered.filter((element) => {
      return !programListFiltered.some(
        (item) => item.program_id === element.program_id
      );
    });

    for (var i = 0; i < programListFiltered.length; i++) {
      // see if the program exists.  if exist, remove it
      this.programs_filtered.unshift({
        program_id: programListFiltered[i].program_id,
        program_code: programListFiltered[i].program_code,
        program_name: programListFiltered[i].program_name,
        modified_by: '',
        modified_date: null,
      });
    }
  }

  /*
  Change program code if user switches the program name drop down
   */
  changeProgramCode(program_code: string) {
    this.program_code_populate = this.programs.filter((x) => {
      return x.program_code == program_code;
    });
    //this.local_data.program_code = this.program_code_populate[0].program_code;

    this.local_data.program_code = this.formGroup.get('program_name')?.value;
    this.csvList = this.local_data.program_code; // use this to repopulate the search field after user types in the field
  }

  doAction() {
    var pathway: DTPathway = {
      pathway_id: this.local_data.pathway_id,
      institution_id: this.instId, //this.local_data.institution_id,
      program_id: this.local_data.program_id,
      program_code: this.local_data.program_code,
      degree_name: this.local_data.degree_name,
      program_name: this.local_data.program_name,
      pathway_description: this.local_data.pathway_description,
      publish: this.local_data.publish ? true : false,
      modified_by: JSON.stringify(this.userName),
      modified_date: new Date(),
      isMasters: this.local_data.isMasters ? true : false,
    };

    if (this.action == 'Delete') {
      // this.closeDialog();
      this.degreeTransferService.removePathway(pathway).subscribe((res) => {
        this._snackBar.open(
          this.local_data.institution_name,
          'Pathway deleted',
          {
            duration: 2000,
          }
        );
        const navigationDetails: string[] = [
          '/Degree-Transfer-Institution-Details',
        ];
        this.router
          .navigateByUrl('/Degree-Transfer-Institution-Details', {
            skipLocationChange: true,
          })
          .then(() => {
            this.router.navigate(navigationDetails);
          });
      });
    } else {
      this.selectedProgram = this.programs.filter((x) => {
        return x.program_code == this.local_data.program_name;
      });

      if (this.action == 'Add') {
        if (this.formGroup.get('program_name')?.value != null) {
          pathway.program_id = this.formGroup.get('program_name')?.value;
        }

        // check to make sure we are inside an institution and that the user selected a program to add
        if (
          this.instId &&
          this.selectedProgram &&
          this.selectedProgram[0].program_id
        ) {
          // try to insert here
          // try to insert here
          this.degreeTransferService.addPathway(pathway).subscribe((res) => {
            this._snackBar.open(
              this.local_data.institution_name,
              'Pathway inserted',
              {
                duration: 2000,
              }
            );
            const navigationDetails: string[] = [
              '/Degree-Transfer-Institution-Details',
            ];
            this.router
              .navigateByUrl('/Degree-Transfer-Institution-Details', {
                skipLocationChange: true,
              })
              .then(() => {
                this.router.navigate(navigationDetails);
              });
          });
        }
      } else {
        if (this.action == 'Update') {
          this.degreeTransferService.updatePathway(pathway).subscribe((res) => {
            this._snackBar.open(
              this.local_data.institution_name,
              'Pathway updated',
              {
                duration: 2000,
              }
            );
            const navigationDetails: string[] = [
              '/Degree-Transfer-Institution-Details',
            ];
            this.router
              .navigateByUrl('/Degree-Transfer-Institution-Details', {
                skipLocationChange: true,
              })
              .then(() => {
                this.router.navigate(navigationDetails);
              });
          });
        }
      }
    }

    setTimeout(() => {
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }, 0);
  }

  setPublish(flag: boolean) {
    this.local_data.publish = flag;
  }
  setMasters(flag: boolean) {
    this.local_data.isMasters = flag;
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  delete() {
    this.dialogRef.close({ event: 'Delete', data: this.local_data });
  }
}

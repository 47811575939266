import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  DTInstitutionDetails,
  DTCustomValidators,
  DegreeTransferService,
} from 'src/app/services/degree-transfer.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-dt-institution-content',
  templateUrl: './dt-institution-content.component.html',
  styleUrls: ['./dt-institution-content.component.scss'],
})
export class DtInstitutionContentComponent implements OnInit {
  formGroup: FormGroup;
  action: string;
  local_data: any;
  instId: any;
  userName = sessionStorage.getItem('user');

  @ViewChild('elseTemplateC') elseTemplateC: any;
  @ViewChild('TemplateCDiv') TemplateCDiv: any;

  constructor(
    public dialogRef: MatDialogRef<DtInstitutionContentComponent>,
    private cdr: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DTInstitutionDetails,
    private router: Router,
    private _formBuilder: FormBuilder,
    private customValidators: DTCustomValidators,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private degreeTransferService: DegreeTransferService
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
    this.instId = this.local_data.instId;
    this.formGroup = this._formBuilder.group({
      heading: [
        ,
        [Validators.maxLength(100), this.customValidators.nameValidator],
      ],
      content: [
        ,
        [Validators.maxLength(2000), this.customValidators.nameValidator],
      ],
    });
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    // height: '15rem',
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    // minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    showToolbar: true,
    sanitize: false,
    toolbarHiddenButtons: [],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  ngOnInit() {}
  ngAFterViewInit() {
    this.cdr.detectChanges();
  }

  doAction() {
    const newContent: DTInstitutionDetails = {
      institution_detail_id: this.local_data.institution_detail_id,
      institution_id: this.instId,
      heading: this.local_data.heading,
      content: this.local_data.content,
      modified_by: JSON.stringify(this.userName),
      modified_date: new Date(),
    };

    if (this.action == 'Delete') {
      // create dummy for deletion.  only need contactId to delete
      this.degreeTransferService
        .removeInstitutionDetails(newContent)
        .subscribe((res) => {
          this._snackBar.open(
            this.local_data.institution_name,
            'Content deleted',
            {
              duration: 2000,
            }
          );
          const navigationDetails: string[] = [
            '/Degree-Transfer-Institution-Details',
          ];
          this.router
            .navigateByUrl('/Degree-Transfer-Institution-Details', {
              skipLocationChange: true,
            })
            .then(() => {
              this.router.navigate(navigationDetails);
            });
        });
    } else {
      // adding new content
      if (!this.local_data.institution_detail_id) {
        //
        this.degreeTransferService
          .addInstitutionDetails(newContent)
          .subscribe((res) => {
            this._snackBar.open(
              this.local_data.institution_name,
              'Content added',
              {
                duration: 2000,
              }
            );
            const navigationDetails: string[] = [
              '/Degree-Transfer-Institution-Details',
            ];
            this.router
              .navigateByUrl('/Degree-Transfer-Institution-Details', {
                skipLocationChange: true,
              })
              .then(() => {
                this.router.navigate(navigationDetails);
              });
          });
      } else {
        // updating content
        this.degreeTransferService
          .updateInstitutionDetails(newContent)
          .subscribe((res) => {
            this._snackBar.open(
              this.local_data.institution_name,
              'Content updated',
              {
                duration: 2000,
              }
            );
            const navigationDetails: string[] = [
              '/Degree-Transfer-Institution-Details',
            ];
            this.router
              .navigateByUrl('/Degree-Transfer-Institution-Details', {
                skipLocationChange: true,
              })
              .then(() => {
                this.router.navigate(navigationDetails);
              });
          });
      }
    }
    setTimeout(() => {
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }, 0);
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  delete() {
    this.dialogRef.close({ event: 'Delete' });
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { DtInstitutionDetailsComponent } from './components/dt-institution-details/dt-institution-details.component';
import { DtInstitutionListComponent } from './components/dt-institution-list/dt-institution-list.component';
import { DtProgramDetailsComponent } from './components/dt-program-details/dt-program-details.component';
import { DtProgramComponent } from './components/dt-program/dt-program.component';
import { InstitutionDetailsComponent } from './components/institution-details/institution-details.component';
import { InstitutionListComponent } from './components/institution-list/institution-list.component';
import { InstitutionPopupComponent } from './components/institution-popup/institution-popup.component';
import { PathwaysDetailsComponent } from './components/pathways-details/pathways-details.component';
import { PathwaysListComponent } from './components/pathways-list/pathways-list.component';
import { PrintLayoutComponent } from './components/print-layout/print-layout.component';

const routes: Routes = [
  { path: '', component: InstitutionPopupComponent },
  {
    path: 'Institution',
    component: InstitutionListComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'InstitutionDetails',
    component: InstitutionDetailsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'InstitutionPop',
    component: InstitutionPopupComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'Pathway',
    component: PathwaysListComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'PathwayDetails',
    component: PathwaysDetailsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'Degree-Transfer-Institution',
    component: DtInstitutionListComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'Degree-Transfer-Institution-Details',
    component: DtInstitutionDetailsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'Degree-Transfer-Programs',
    component: DtProgramComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'Degree-Transfer-Programs-Details',
    component: DtProgramDetailsComponent,
    canActivate: [MsalGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<div *ngIf="isPrinting">
    <h1>Degree Transfer Guide - Preview</h1>
    <div class="cmp-container">
        <div class="degree-transfer-outbound-landing">
            <h1 class="title text-left"> {{institutionName}} </h1>
        </div>
    </div>
    <div class="university-contact-tile image">
        <div class="">
            <div *ngFor="let detail of institutionDetails">
                <div class="cmp-title">
                    <h1>{{detail.heading}}</h1>
                </div>
                <div>
                    <p [innerHTML]="detail.content"></p>
                </div> 
            </div>
        </div>
    </div>
    <div class="title">
        <div class="cmp-title">
            <div *ngFor="let contact of contacts">
                <div class="cmp-title">
                    <b>Program contact:</b>
                  (Contact Publish = {{contact.contact_publish}}) <br/>
                    <b>{{contact.title}} {{contact.first_name}} {{contact.last_name}} <span *ngIf="contact.department">{{contact.department}},</span><span *ngIf="!contact.department"></span> </b>
                </div>
                <span class="icon fa fa-phone"></span>
                <p>{{contact.phone_number}}</p>
            </div>
        </div>
    </div>
    <div class="parsys">
        <div class="text">
            <div class="cmp-title">
                <div *ngFor="let program of programs">
                    <p> <b>Program publish = {{program.publish}}</b><br/>
                        <strong>Seneca Program: </strong> 
                        <span *ngIf="program.program_code === 'diploma'">
                            <a href="https://www.senecapolytechnic.ca/programs/bycredential/diploma.html">{{program.program_name}}</a>
                        </span>
                        <span *ngIf="program.program_code === 'graduate'">
                            <a href="https://www.senecapolytechnic.ca/programs/bycredential/certificate.html">{{program.program_name}}</a>
                        </span>
                        <span *ngIf="program.program_code ==='GRADCERT'">
                            <a href="https://www.senecapolytechnic.ca/programs/bycredential/graduate-certificate.html">{{program.program_name}}</a>
                        </span>
                        <span *ngIf="program.program_code != 'diploma' && program.program_code != 'graduate' && program.program_code != 'GRADCERT'">
                            <ng-container *ngFor="let name of program.program_name.split(','); let i = index">
                                <a [href]="'https://www.senecapolytechnic.ca/programs/fulltime/' + program.program_code.split(',')[i] + '.html'">{{name.trim()}}</a>
                                <span *ngIf="i < program.program_name.split(',').length - 1">, </span>
                            </ng-container>
                        </span>
                    </p>
                    <p>
                        <strong>Degree: </strong> {{ program.degree_name}}
                    </p>
                    <div>
                       <p [innerHTML] = "program.pathway_description"></p>
                    </div>
                    <br/>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isPrinting">
    <div></div>
</div>
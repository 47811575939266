// tslint:disable-next-line:max-line-length
import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  Inject,
  Optional,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InstitutionContactsComponent } from '../institution-contacts/institution-contacts.component';
import { InstitutionProgramDetailsComponent } from '../institution-program-details/institution-program-details.component';
// tslint:disable-next-line:max-line-length
import {
  InstitutionService,
  CustomValidators,
  Institution,
  InstitutionContact,
  InstitutionProgram,
  Country,
  State,
  Email,
  ContactEmail_Notify,
} from '../../services/institution-service';
import { InstitutionPopupComponent } from '../institution-popup/institution-popup.component';
import { InstitutionEmailComponent } from '../institution-email/institution-email.component';

@Component({
  selector: 'app-institution-details',
  templateUrl: './institution-details.component.html',
  styleUrls: ['./institution-details.component.scss'],
})
export class InstitutionDetailsComponent implements AfterViewInit {
  public elseTemplateDiv: any;
  public TemplateDiv: any;
  formGroup: FormGroup;
  action = '';
  // tslint:disable-next-line:variable-name
  local_data: any;
  // tslint:disable-next-line:variable-name
  deleted_contacts: number[] = [];
  // tslint:disable-next-line:variable-name
  deleted_programs: number[] = [];
  // tslint:disable-next-line:variable-name
  archive_programs: number[] = [];
  private sort: MatSort | null = null;
  private paginator: MatPaginator | null = null;
  displayedColumns = [
    'contact_first_name',
    'contact_last_name',
    'contact_email',
    'contact_phone',
    'contact_phone_ext',
    'contact_notes',
    'delete',
  ];
  displayedColumnsP = [
    'program_name',
    'program_code',
    'mcu_code',
    'program_cred',
    'program_status',
    'archive',
    'delete',
  ];
  dataSource = new MatTableDataSource(initialData);
  dataSourceP = new MatTableDataSource(initialDataPrograms);
  userName = sessionStorage.getItem('user');
  email_notify: ContactEmail_Notify[] = [];
  email: Email | undefined;
  lastModifiedDate: Date | undefined;
  contries: Country[] = [];
  provinces: State[] = [];
  provincesAll: State[] = [];

  @ViewChild(MatTable, { static: true }) table: MatTable<any> | undefined;
  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    setTimeout(() => (this.dataSourceP.sort = this.sort));
  }

  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    mp: MatPaginator
  ) {
    this.paginator = mp;
    setTimeout(() => (this.dataSourceP.paginator = this.paginator));
  }

  constructor(
    public dialogRef: MatDialogRef<InstitutionDetailsComponent>,
    private cdr: ChangeDetectorRef,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Institution,
    private router: Router,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private institutionService: InstitutionService,
    private customValidators: CustomValidators
  ) {
    this.local_data = { ...data };
    if (!data) {
      this.local_data = this.router.getCurrentNavigation()?.extras.state; //{...data};
    }
    this.action = this.local_data ? this.local_data.action : '';

    this.formGroup = this._formBuilder.group({
      name: [
        ,
        [
          Validators.required,
          Validators.maxLength(150),
          this.customValidators.nameValidator,
        ],
      ],
      country: [
        null,
        { validators: [Validators.maxLength(3)], updateOn: 'change' },
      ],
      province_state: [
        null,
        { validators: [Validators.maxLength(50)], updateOn: 'change' },
      ],
      notes: [
        null,
        [Validators.maxLength(500), this.customValidators.nameValidator],
      ],
    });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit() {
    this.deleted_contacts = [];
    this.deleted_programs = [];
    this.archive_programs = [];

    this.institutionService.getContactsList().subscribe(
      (res: any) => {
        this.dataSource = new MatTableDataSource(
          res.filter((x: InstitutionContact) => {
            return x.institution_id == this.local_data.id;
            // console.log(x);
            // console.log(x.institution_id);
            // console.log(this.local_data.id);
            // console.log("===============");
          })
        );
        // console.log(this.dataSource);
      },
      (err) => {
        console.log(err);
      }
    );

    this.institutionService.getProgramsList().subscribe(
      (res: any) => {
        // this.contents = res;

        this.dataSourceP = new MatTableDataSource(
          res.filter(
            (x: InstitutionProgram) => x.institution_id == this.local_data.id
          )
        );
        if (this.paginator) {
          this.dataSourceP.paginator = this.paginator;
        }
      },
      (err) => {
        console.log(err);
      }
    );

    this.institutionService.getCountryList().subscribe(
      (res: any) => {
        // console.log(res.QasData);
        let content = JSON.parse(res);
        this.contries = content.QasData;
        // console.log(this.contries);
      },
      (err) => {
        console.log(err);
      }
    );

    this.institutionService.getStatesList().subscribe(
      (res: any) => {
        let content = JSON.parse(res);
        this.provincesAll = content.QasData;
        if (this.local_data.country) {
          this.provinces = this.provincesAll.filter(
            (x) => x.Country == this.local_data.country
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );

    this.institutionService.getNotifyContactEmailsList().subscribe(
      (res: any) => {
        // this.contents = res;
        this.email_notify =
          res &&
          res.filter(
            (x: ContactEmail_Notify) => x.instituionId == this.local_data.id
          ); //&& x.notify == '1');
      },
      (err) => {
        console.log(err);
      }
    );
    // by default canada
    if (!this.local_data.id) {
      this.local_data.country = 'CAN';
    }
    if (this.local_data.modified_date) {
      this.lastModifiedDate = new Date(this.local_data.modified_date);
      this.lastModifiedDate.setDate(this.lastModifiedDate.getDate() + 1);
    }
    // console.log(this.lastModifiedDate);
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  deleteInstitution(name: string) {
    this.dialogRef.close({ event: name, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  CountrySelectChange() {
    this.provinces = this.provincesAll.filter(
      (x) => x.Country == this.local_data.country
    );
  }

  cancelAction(): void {
    const navigationDetails: string[] = ['/Institution'];
    this.router.navigate(navigationDetails);
  }

  doAction() {
    console.log('inst details > ' + this.action);
    if (this.action == 'Delete') {
      this.deleteInstitution('Delete');
    } else if (this.action == 'Archive') {
      this.deleteInstitution('Archive');
    } else {
      const newInst: Institution = {
        name: this.local_data.name,
        id: this.local_data.id,
        status: 'a',
        country: this.local_data.country,
        province_state: this.local_data.province_state,
        notes: this.local_data.notes,
        modified_by: this.userName ? this.userName : '',
        modified_date: new Date(),
        contacts: this.dataSource.data,
        contacts_deleted: this.deleted_contacts,
        programs: this.dataSourceP.data,
        program_deleted: this.deleted_programs,
        program_archived: this.archive_programs,
        action: this.action,
      };

      // console.log(this.local_data.id < 1);
      console.log('original> ' + JSON.stringify(this.local_data));
      if (!this.local_data.id) {
        this.institutionService.addInstitution(newInst).subscribe((res) => {
          this._snackBar.open(this.local_data.name, 'Added', {
            duration: 2000,
          });
          const navigationDetails: string[] = ['/Institution'];
          this.router
            .navigateByUrl('/Institution', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(navigationDetails);
            });
        });
      } else {
        this.institutionService.updateInstitution(newInst).subscribe((res) => {
          this._snackBar.open(this.local_data.name, 'Updated', {
            duration: 2000,
          });
          this.cleanUpInstitutionData_AfterUpdate();
          const navigationDetails: string[] = ['/Institution'];
          this.router
            .navigateByUrl('/Institution', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(navigationDetails);
            });
        });
      }
      if (this.dataSource.data) {
        // console.log(this.dataSource.data);
      }
      if (this.deleted_contacts && this.deleted_contacts.length > 0) {
        // console.log(this.deleted_contacts);
      }

      // const navigationDetails: string[] = ['/Institution'];
      //   this.router.navigateByUrl('/Institution', { skipLocationChange: true }).then(() => {
      //     this.router.navigate(navigationDetails);
      //   });
    }
  }

  cleanUpInstitutionData_AfterUpdate() {
    this.deleted_contacts = [];
    this.deleted_programs = [];
    this.archive_programs = [];
    this.dataSourceP.data.forEach((p) => (p.action = ''));
    this.dataSource.data.forEach((p) => (p.action = ''));
    this.institutionService.getContactsList().subscribe(
      (res: any) => {
        this.dataSource = new MatTableDataSource(
          res.filter((x: InstitutionContact) => {
            return x.institution_id == this.local_data.id;
          })
        );
      },
      (err) => {
        console.log(err);
      }
    );

    this.institutionService.getProgramsList().subscribe(
      (res: any) => {
        this.dataSourceP = new MatTableDataSource(
          res.filter(
            (x: InstitutionProgram) => x.institution_id == this.local_data.id
          )
        );
      },
      (err) => {
        console.log(err);
      }
    );
    this.institutionService.getNotifyContactEmailsList().subscribe(
      (res: any) => {
        // this.contents = res;
        this.email_notify =
          res &&
          res.filter(
            (x: ContactEmail_Notify) => x.instituionId == this.local_data.id
          ); //&& x.notify == '1');
      },
      (err) => {
        console.log(err);
      }
    );
  }

  openDialogProgram(action: string, obj: { action: any }, e: any) {
    if (e.target.className.includes('mat-icon') && action == 'Update') {
      return;
    }
    obj.action = action;
    const dialogR = this.dialog.open(InstitutionProgramDetailsComponent, {
      width: '50%',
      data: obj,
    });

    dialogR.afterClosed().subscribe((result) => {
      if (result.event == 'Add') {
        this.addRowDataP(result.data);
      } else if (result.event == 'Update') {
        this.updateRowDataP(result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowDataP(result.data);
      } else if (result.event == 'Archive') {
        this.archiveRowDataP(result.data);
      }
    });
  }

  addRowDataP(row_obj: {
    program_name: string;
    program_code: any;
    program_cred: any;
    mcu_code: any;
  }) {
    const data = this.dataSourceP.data;
    const newRecord: InstitutionProgram = {
      program_name: row_obj.program_name,
      program_code: row_obj.program_code,
      program_status: '1',
      // program_completed: row_obj.program_completed,
      institution_id: this.local_data.id,
      program_id: 0,
      program_cred: row_obj.program_cred,
      mcu_code: row_obj.mcu_code,
      modified_by: this.userName ? this.userName : '',
      modified_date: new Date(),
      action: 'a',
    };
    if (data.findIndex((d) => d.program_name == newRecord.program_name) > -1) {
      this._snackBar.open(
        row_obj.program_name,
        ' is duplicate, please have it checked before save',
        {
          duration: 5000,
        }
      );
      return;
    }
    data.push(newRecord);

    this.dataSourceP.data = data;
    //this.tableContacts.renderRows();
    this._snackBar.open(row_obj.program_name, 'Added', {
      duration: 2000,
    });
  }

  updateRowDataP(row_obj: {
    program_id: number;
    program_name: string;
    program_code: string;
    program_status: string;
    institution_id: number;
    program_cred: string;
    mcu_code: string;
  }) {
    const data = this.dataSourceP.data;

    data.filter((value, key) => {
      if (row_obj.program_id == 0) {
        if (value.program_name == row_obj.program_name) {
          value.program_code = row_obj.program_code;
          value.program_status = row_obj.program_status;
          value.program_name = row_obj.program_name;
          // value.program_completed = row_obj.program_completed;
          (value.institution_id = row_obj.institution_id),
            (value.program_id = row_obj.program_id),
            (value.program_cred = row_obj.program_cred),
            (value.mcu_code = row_obj.mcu_code),
            (value.modified_by = this.userName ? this.userName : ''),
            (value.modified_date = new Date()),
            (value.action = row_obj.program_id > 0 ? 'u' : 'a');
        }
      } //(value.program_id == row_obj.program_id && value.program_id !=0)
      else {
        if (value.program_id == row_obj.program_id) {
          value.program_code = row_obj.program_code;
          value.program_status = row_obj.program_status;
          value.program_name = row_obj.program_name;
          // value.program_completed = row_obj.program_completed;
          (value.institution_id = row_obj.institution_id),
            (value.program_id = row_obj.program_id),
            (value.program_cred = row_obj.program_cred),
            (value.mcu_code = row_obj.mcu_code),
            (value.modified_by = this.userName ? this.userName : ''),
            (value.modified_date = new Date()),
            (value.action = row_obj.program_id > 0 ? 'u' : 'a');
        }
      }
      this.dataSourceP.data = data;
      // initialData=data;

      this._snackBar.open(row_obj.program_name, 'Updated', {
        duration: 2000,
      });
      return true;
    });
  }

  deleteRowDataP(row_obj: {
    program_id: number;
    action: string;
    program_name: string;
    program_code: string;
  }) {
    const data = this.dataSourceP.data;

    data.filter((value, key) => {
      if (row_obj.program_id > 0) {
        if (!this.deleted_programs.includes(row_obj.program_id)) {
          this.deleted_programs.push(row_obj.program_id);
          row_obj.action = 'd';
        }
        // return value.program_id != row_obj.program_id
      } else {
        if (
          value.program_name == row_obj.program_name &&
          value.program_code == row_obj.program_code
        ) {
          row_obj.action = 'd';
          value.action = 'd';
        }
        // return value.program_name != row_obj.program_name && value.program_code != row_obj.program_code;
      }
    });
    this.dataSourceP.data = data.filter(
      (x) => !this.deleted_programs.includes(x.program_id) && x.action != 'd'
    );
    this._snackBar.open(row_obj.program_name, 'Deleted', {
      duration: 2000,
    });
    return true;
  }

  archiveRowDataP(row_obj: {
    modified_by: string | null;
    program_id: number;
    action: string;
    program_status: string;
    program_name: string;
    program_code: string;
  }) {
    const data = this.dataSourceP.data;
    row_obj.modified_by = this.userName;
    // this.institutionService.archiveInstitutionProgram(row_obj)
    //   .subscribe();
    data.filter((value, key) => {
      if (row_obj.program_id > 0) {
        if (value.program_id == row_obj.program_id) {
          value.program_status = '0';
          if (row_obj.program_id > 0) {
            if (!this.archive_programs.includes(row_obj.program_id)) {
              this.archive_programs.push(row_obj.program_id);
              row_obj.action = 'e';
              row_obj.program_status = '0';
            }
          }
        }
      } else {
        // value.program_status = '0';
        if (
          value.program_name == row_obj.program_name &&
          value.program_code == row_obj.program_code
        ) {
          value.program_status = '0';
          value.action = 'a';
        }
        row_obj.program_status = '0';
        row_obj.action = 'a';
      }
    });
    this.dataSourceP.data = data;

    this._snackBar.open(row_obj.program_name, 'Archived', {
      duration: 2000,
    });
    return true;
  }

  openDialogDelete_Archive(action: any, e: Event) {
    //   if (e.target.className.includes('mat-icon') && action=='Update') {
    //     return;
    // }
    this.local_data.action = action;
    //  this.deleted_contact= (obj.contact_first_name + ' ' + obj.contact_last_name);
    const dialogR = this.dialog.open(InstitutionPopupComponent, {
      width: '50%',
      data: this.local_data,
    });

    dialogR.afterClosed().subscribe((result) => {
      if (result.event == 'Archive') {
        this.archiveInsData(result.data);
      } else if (result.event == 'Delete') {
        this.deleteInsData(result.data);
      } else if (result.event == 'reactivate') {
        this.reactiveInsData(result.data);
      }
    });
  }

  archiveInsData(row_obj: Institution) {
    row_obj.modified_by = this.userName ? this.userName : '';
    this.institutionService.archiveInstitution(row_obj).subscribe();

    this._snackBar.open(row_obj.name, 'Archived', {
      duration: 2000,
    });
    const navigationDetails: string[] = ['/Institution'];
    this.router
      .navigateByUrl('/Institution', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(navigationDetails);
      });
  }

  reactiveInsData(row_obj: Institution) {
    row_obj.modified_by = this.userName ? this.userName : '';
    this.institutionService.reactiveInstitution(row_obj).subscribe();

    this._snackBar.open(row_obj.name, 'Re-activate', {
      duration: 2000,
    });
    const navigationDetails: string[] = ['/Institution'];
    this.router
      .navigateByUrl('/Institution', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(navigationDetails);
      });
  }

  deleteInsData(row_obj: Institution) {
    row_obj.modified_by = this.userName ? this.userName : '';
    this.institutionService.deleteInstitution(row_obj).subscribe();

    this._snackBar.open(row_obj.name, 'Delete', {
      duration: 2000,
    });
    const navigationDetails: string[] = ['/Institution'];
    this.router
      .navigateByUrl('/Institution', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(navigationDetails);
      });
  }

  openDialog_Email(e: Event) {
    //   if (e.target.className.includes('mat-icon') && action=='Update') {
    //     return;
    // }
    this.setEmail();

    // obj.action = action;
    const dialogR = this.dialog.open(InstitutionEmailComponent, {
      width: '50%',
      data: this.email,
    });

    dialogR.afterClosed().subscribe((result) => {
      if (result.event == 'Add') {
        this.sendEmail(result.data);
      }
    });
  }

  setEmail() {
    let to = '';
    this.email_notify.forEach((e) => {
      to += e.contact_email + '; ';
    });
    const email_set = {
      email_from: '',
      email_to: to.substring(0, to.length - 2),
      email_subject: 'Update from Seneca College Pathways',
      email_message: `Link: https//www.senecacollege.ca/institutionPrograms.loremipsum.html`,
      From_Bcc: '',
    };
    this.email = email_set;

    // this.email_notify.forEach(e => {
    //   this.email.email_to += e + ', ';
    // });
    // this.email.email_to= this.email.email_to.substr(0,this.email.email_to.length-1);
    // this.email.email_subject ='Update from Seneca College Pathways';
    // this.email.email_message ='Link: https//www.senecacollege.ca/institutionPrograms.loremipsum.html';
  }

  sendEmail(data: Email) {
    this.institutionService.sendEmailContacts(data).subscribe();
    this._snackBar.open('Email', 'Sent', {
      duration: 2000,
    });
  }

  openDialog(action: string, obj: { action: any }, e: Event) {
    const clickedElement = e.target as HTMLButtonElement;
    if (clickedElement && action == 'Update') {
      return;
    }
    obj.action = action;
    //  this.deleted_contact= (obj.contact_first_name + ' ' + obj.contact_last_name);
    const dialogR = this.dialog.open(InstitutionContactsComponent, {
      width: '50%',
      data: obj,
    });

    dialogR.afterClosed().subscribe((result) => {
      if (result.event == 'Add') {
        this.addRowData(result.data);
      } else if (result.event == 'Update') {
        this.updateRowData(result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowData(result.data);
      }
    });
  }

  addRowData(row_obj: {
    contact_phone: any;
    contact_phone_ext: any;
    contact_first_name: string;
    contact_last_name: any;
    contact_email: any;
    contact_notes: any;
    notify_on_change: any;
  }) {
    const data = this.dataSource.data;
    const newRecord: InstitutionContact = {
      contact_phone: row_obj.contact_phone,
      contact_phone_ext: row_obj.contact_phone_ext,
      contact_first_name: row_obj.contact_first_name,
      contact_last_name: row_obj.contact_last_name,
      contact_email: row_obj.contact_email,
      institution_id: this.local_data.id,
      contact_id: 0,
      contact_notes: row_obj.contact_notes,
      notify_on_change: row_obj.notify_on_change,
      modified_by: this.userName ? this.userName : '',
      modified_date: new Date(),
      action: 'a',
    };
    data.push(newRecord);

    this.dataSource.data = data;
    //this.tableContacts.renderRows();
    this._snackBar.open(row_obj.contact_first_name, 'Added', {
      duration: 2000,
    });
  }
  updateRowData(row_obj: {
    contact_id: number;
    contact_phone: string;
    contact_phone_ext: string;
    contact_first_name: string;
    contact_last_name: string;
    contact_email: string;
    institution_id: number;
    contact_notes: string;
    notify_on_change: string;
    contact_name: string;
  }) {
    const data = this.dataSource.data;

    data.filter((value, key) => {
      // if(value.contact_first_name == row_obj.contact_first_name){
      if (value.contact_id == row_obj.contact_id) {
        value.contact_phone = row_obj.contact_phone;
        value.contact_phone_ext = row_obj.contact_phone_ext;
        value.contact_first_name = row_obj.contact_first_name;
        value.contact_last_name = row_obj.contact_last_name;
        value.contact_email = row_obj.contact_email;
        (value.institution_id = row_obj.institution_id),
          (value.contact_id = row_obj.contact_id),
          (value.contact_notes = row_obj.contact_notes),
          (value.notify_on_change = row_obj.notify_on_change),
          (value.modified_by = this.userName ? this.userName : ''),
          (value.modified_date = new Date()),
          (value.action = row_obj.contact_id > 0 ? 'u' : 'a');
      }
      this.dataSource.data = data;
      // initialData=data;

      this._snackBar.open(row_obj.contact_name, 'Updated', {
        duration: 2000,
      });
      return true;
    });
  }
  deleteRowData(row_obj: {
    contact_id: number;
    action: string;
    contact_first_name: string;
    contact_last_name: string;
  }) {
    const data = this.dataSource.data;

    data.filter((value, key) => {
      if (row_obj.contact_id > 0) {
        if (!this.deleted_contacts.includes(row_obj.contact_id)) {
          this.deleted_contacts.push(row_obj.contact_id);
          row_obj.action = 'd';
        }
        // return value.contact_id != row_obj.contact_id;
      } else {
        if (
          value.contact_first_name == row_obj.contact_first_name &&
          value.contact_last_name == row_obj.contact_last_name
        ) {
          row_obj.action = 'd';
          value.action = 'd';
        }
        // return value.program_name != row_obj.program_name && value.program_code != row_obj.program_code;
      }
    });
    this.dataSource.data = data.filter(
      (x) => !this.deleted_contacts.includes(x.contact_id) && x.action != 'd'
    );

    this._snackBar.open(row_obj.contact_first_name, 'Deleted', {
      duration: 2000,
    });
    return true;
  }
}

export interface Country2 {
  value: string;
  viewValue: string;
}

export interface Province {
  value: string;
  viewValue: string;
}

var initialData: InstitutionContact[] = [];
var initialDataPrograms: InstitutionProgram[] = [];

import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  PathwayDetails,
  InstitutionService,
  CustomValidators,
} from 'src/app/services/institution-service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-pathways-pathway-details',
  templateUrl: './pathways-pathway-details.component.html',
  styleUrls: ['./pathways-pathway-details.component.scss'],
})
export class PathwaysPathwayDetailsComponent implements OnInit {
  formGroup: FormGroup;
  action = '';
  local_data: any;

  constructor(
    public dialogRef: MatDialogRef<PathwaysPathwayDetailsComponent>,
    private cdr: ChangeDetectorRef,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: PathwayDetails,
    private router: Router,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private institutionService: InstitutionService,
    private customValidators: CustomValidators
  ) {
    this.local_data = { ...data };
    if (!data) {
      this.local_data = this.router.getCurrentNavigation()?.extras.state; //{...data};
    }
    this.action = 'Add'; // (this.local_data)?this.local_data.action:'';

    this.formGroup = this._formBuilder.group({
      eligibility: [, [this.customValidators.nameValidator]],
      transfer_credit: [, [this.customValidators.nameValidator]],
      time_to_complete: [, [this.customValidators.nameValidator]],
      seneca_credits_to_complete: [, [this.customValidators.nameValidator]],
      how_to_apply: [, [this.customValidators.nameValidator]],
      Contact_Information: [, [this.customValidators.nameValidator]],
      additionalInfo: [, [this.customValidators.nameValidator]],
    });
  }
  
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    // height: '15rem',
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    // minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    showToolbar: true,
    sanitize: false,
    toolbarHiddenButtons: [],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  ngOnInit() {}

  doAction() {
    // var plainText = this.local_data.contact_notes && this.local_data.contact_notes.replace(/<[^>]*>/g, '');
    // this.local_data.contact_notes = plainText;
    console.log(this.local_data);
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}

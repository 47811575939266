<div class="div-padding-left">
  <div
    #TemplateDiv
    *ngIf="action != 'Delete' && action != 'Archive'; else elseTemplateDiv"
  >
    <div id="block_container">
      <div id="bloc1" class="col-md-4">
        <span class="mat-dialog-title"
          ><mat-icon (click)="cancelAction()" class="cursor_point"
            >keyboard_backspace</mat-icon
          >
          &nbsp; &nbsp;{{ local_data && local_data.name }}</span
        >
      </div>
      <div id="bloc1" class="col-md-3">
        <button
          mat-button
          (click)="doAction()"
          [disabled]="!formGroup.valid"
          mat-flat-button
          color="warn"
        >
          Save
        </button>
      </div>
    </div>
  </div>

  <form [formGroup]="formGroup" class="institution-form">
    <div
      id="block_container_contact"
      *ngIf="action != 'Delete' && action != 'Archive'"
    >
      <div id="bloc_contact_1" class="col-md-3">
        <h1 mat-dialog-title>
          <strong> Degree Transfer Institution Details</strong>
        </h1>
      </div>
      <div>
        <button
          mat-button
          (click)="onPrintInstitution()"
          mat-flat-button
          color="warn"
        >
          Print Institution Page
        </button>
      </div>
    </div>
    <div>
      <div
        *ngIf="action != 'Delete' && action != 'Archive'; else elseTemplate"
        class="d-flex"
      >
        <mat-form-field appearance="fill" class="col-md-8">
          <mat-label>Degree Transfer Institution Name</mat-label>
          <input
            name="institution_name"
            matInput
            placeholder="{{ local_data?.name }}"
            [(ngModel)]="local_data.institution_name"
            formControlName="institution_name"
            [required]="true"
          />
          <mat-error
            *ngIf="formGroup.get('institution_name')?.hasError('required')"
          >
            name is
            <strong>required</strong>
          </mat-error>
          <mat-error
            *ngIf="formGroup.get('institution_name')?.hasError('invalidName')"
          >
            Special characters not allowed!
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-md-4">
          <mat-label>Location</mat-label>
          <mat-select
            name="institution_location"
            placeholder="{{ local_data?.location }}"
            [(ngModel)]="local_data.institution_location"
            formControlName="institution_location"
          >
            <mat-option value="Toronto">Toronto</mat-option>
            <mat-option value="Ontario">Ontario</mat-option>
            <mat-option value="Canada">Canada</mat-option>
            <mat-option value="U.S.">U.S.</mat-option>
            <mat-option value="International">International</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- institution content section-->
      <div *ngIf="action != 'Delete' && action != 'Archive'">
        <div id="block_container_contact">
          <div id="bloc_contact_1" class="col-md-2">
            <h1 mat-dialog-title><strong> Institution Content</strong></h1>
          </div>
          <div id="bloc_contact_2" class="col-md-2">
            <button
              mat-button
              (click)="openDialogContent('Add', {}, $event)"
              mat-flat-button
              color="warn"
            >
              + New Institution Content
            </button>
          </div>
        </div>
        <div class="list-container mat-elevation-z8">
          <mat-table
            #tableContent
            #matSortContent="matSort"
            [dataSource]="dataSourceContent"
            matSort
          >
            <ng-container matColumnDef="heading">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Heading
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.heading }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="content">
              <mat-header-cell
                class="justify-content-center"
                *matHeaderCellDef
                mat-sort-header
              >
                Content
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.content | hidehtml }}
              </mat-cell>
            </ng-container>
            <ng-container cdkColumnDef="delete">
              <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
              <mat-cell *cdkCellDef="let element">
                <mat-icon
                  (click)="openDialogContent('Delete', element, $event)"
                  class="cursor_point"
                  >delete_outline</mat-icon
                >
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedColumnsContent; sticky: true"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumnsContent"
              (click)="openDialogContent('Update', row, $event)"
            ></mat-row>
          </mat-table>
        </div>
      </div>

      <!-- contacts section -->
      <div *ngIf="action != 'Delete' && action != 'Archive'">
        <div id="block_container_contact">
          <div id="bloc_contact_1" class="col-md-2">
            <h1 mat-dialog-title><strong> Contacts </strong></h1>
          </div>
          <div id="bloc_contact_2" class="col-md-2">
            <button
              mat-button
              (click)="openDialog('Add', {}, $event)"
              mat-flat-button
              color="warn"
            >
              + New Contact
            </button>
          </div>
        </div>
        <div class="list-container mat-elevation-z8">
          <mat-table
            #tableContacts
            #matSortC="matSort"
            [dataSource]="dataSource"
            matSort
          >
            <ng-container matColumnDef="title">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Title
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.title }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="first_name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                First Name
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.first_name }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="last_name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Last Name
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.last_name }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="department">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Department
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.department }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Email
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.email }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="phone_number">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Phone Number
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.phone_number }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="job_title">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Job Title
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.job_title }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="contact_publish">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Publish
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.contact_publish == "1" ? "Yes" : "No" }}
              </mat-cell>
            </ng-container>
            <ng-container cdkColumnDef="delete">
              <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
              <mat-cell *cdkCellDef="let element">
                <mat-icon
                  (click)="openDialog('Delete', element, $event)"
                  class="cursor_point"
                  >delete_outline</mat-icon
                >
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></mat-header-row>

            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              (click)="openDialog('Update', row, $event)"
            ></mat-row>
          </mat-table>
        </div>
      </div>

      <!-- pathway section-->
      <div *ngIf="action != 'Delete' && action != 'Archive'">
        <div id="block_container_contact">
          <div id="bloc_contact_1" class="col-md-2">
            <h1 mat-dialog-title><strong> Pathways </strong></h1>
          </div>
          <div id="bloc_contact_2" class="col-md-2">
            <button
              mat-button
              (click)="openDialogPathway('Add', {}, $event)"
              mat-flat-button
              color="warn"
            >
              + New Pathway
            </button>
          </div>
        </div>
        <div class="list-container-pathway mat-elevation-z8">
          <mat-table
            class="mat-table-pathway"
            #tableContentPathway
            #matSortPathway="matSort"
            [dataSource]="dataSourcePathway"
            matSort
          >
            <ng-container matColumnDef="program_code">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Program Code
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.program_code }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="degree_name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Degree Name
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.degree_name }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="pathway_description">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Pathway Description
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.pathway_description | hidehtml }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="publish">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Publish
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.publish == "1" ? "Yes" : "No" }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="isMasters">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Masters
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.isMasters == "1" ? "Yes" : "No" }}
              </mat-cell>
            </ng-container>
            <ng-container cdkColumnDef="delete">
              <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
              <mat-cell *cdkCellDef="let element">
                <mat-icon
                  (click)="openDialogPathway('Delete', element, $event)"
                  class="cursor_point"
                  >delete_outline</mat-icon
                >
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedColumnPathway; sticky: true"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumnPathway"
              (click)="openDialogPathway('Update', row, $event)"
            ></mat-row>
          </mat-table>
          <mat-paginator
            #paginator
            [length]="dataSourcePathway.data.length"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 20, 40]"
          ></mat-paginator>
        </div>
      </div>

      <div style="text-align: center">
        <ng-template #elseTemplate>
          <h1 mat-dialog-title>
            <strong> {{ action }} Degree Transfer Insitution</strong>
          </h1>
          Sure to {{ action }} <b>{{ local_data.institution_name }}</b
          >?
        </ng-template>
      </div>

      <div
        mat-dialog-actions
        class="d-flex justify-content-center align-items-start"
        #elseTemplateDiv
        *ngIf="action == 'Delete' || action == 'Archive'; else TemplateDiv"
      >
        <br />
        <button mat-button (click)="doAction()" mat-flat-button color="warn">
          {{ action }}
        </button>
        <button mat-stroked-button (click)="closeDialog()" color="accent">
          Cancel</button
        ><br />
      </div>
    </div>
  </form>
</div>
<app-print-layout></app-print-layout>

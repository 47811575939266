<form [formGroup]="formGroup" class="institution-form">
  <h1 mat-dialog-title>
    <strong>{{ action }} Degree Transfer Institution Contact</strong>
  </h1>
  <mat-dialog-content class="mat-typography">
    <div>
      <div *ngIf="action != 'Delete'; else elseTemplateCDiv">
        <div id="block_container_contactDetails">
          <div id="bloc_contact_detail_1" class="col-md-2">
            <mat-form-field appearance="fill">
              <mat-label>Title </mat-label>
              <input
                name="title"
                matInput
                placeholder="{{ local_data?.title }}"
                [(ngModel)]="local_data.title"
                formControlName="contact_title"
              />
              <mat-error
                *ngIf="formGroup.get('contact_title')?.hasError('invalidName')"
              >
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
          <div id="bloc_contact_detail_1" class="col-md-5">
            <mat-form-field appearance="fill">
              <mat-label>First Name </mat-label>
              <input
                name="first_name"
                matInput
                placeholder="{{ local_data?.first_name }}"
                [(ngModel)]="local_data.first_name"
                formControlName="first_name"
                [required]="true"
              />
              <mat-error
                *ngIf="formGroup.get('first_name')?.hasError('required')"
              >
                name is <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="formGroup.get('first_name')?.hasError('invalidName')"
              >
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
          <div id="bloc_contact_detail_1" class="col-md-5">
            <mat-form-field appearance="fill">
              <mat-label>Last Name </mat-label>
              <input
                name="last_name"
                matInput
                placeholder="{{ local_data?.last_name }}"
                [(ngModel)]="local_data.last_name"
                formControlName="last_name"
                [required]="true"
              />
              <mat-error
                *ngIf="formGroup.get('last_name')?.hasError('required')"
              >
                name is <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="formGroup.get('last_name')?.hasError('invalidName')"
              >
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div id="block_container_contactDetails">
          <div id="bloc_contact_detail_1" class="col-md-5">
            <mat-form-field appearance="fill">
              <mat-label>Department Name </mat-label>
              <input
                name="department"
                matInput
                placeholder="{{ local_data?.department }}"
                [(ngModel)]="local_data.department"
                formControlName="department"
                [required]="true"
              />
              <mat-error
                *ngIf="formGroup.get('department')?.hasError('required')"
              >
                name is <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="formGroup.get('department')?.hasError('invalidName')"
              >
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
          <div id="bloc_contact_detail_1" class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>Contact Phone </mat-label>
              <input
                name="phone_number"
                matInput
                placeholder="{{ local_data?.phone_number }}"
                [(ngModel)]="local_data.phone_number"
                formControlName="phone_number"
              />
              <mat-error *ngIf="formGroup.get('phone')?.hasError('pattern')">
                Please enter a valid phone number
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div id="block_container_contactDetails">
          <div id="bloc_contact_detail_1" class="col-md-5">
            <mat-form-field appearance="fill">
              <mat-label>Email </mat-label>
              <input
                name="email"
                matInput
                placeholder="{{ local_data?.email }}"
                [(ngModel)]="local_data.email"
                formControlName="email"
                [required]="true"
              />
              <mat-error *ngIf="formGroup.get('email')?.hasError('required')">
                name is <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="formGroup.get('email')?.hasError('invalidName')"
              >
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
          <div id="bloc_contact_detail_1" class="col-md-5">
            <mat-form-field appearance="fill">
              <mat-label>Job Title </mat-label>
              <input
                name="contact_email"
                matInput
                placeholder="{{ local_data?.job_title }}"
                [(ngModel)]="local_data.job_title"
                formControlName="job_title"
                [required]="true"
              />
              <mat-error
                *ngIf="formGroup.get('job_title')?.hasError('invalidName')"
              >
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div id="block_container_contactDetails">
          <div id="bloc_contact_detail_1" class="col-md-5">
            <mat-checkbox
              name="contact_publish"
              [checked]="local_data?.contact_publish"
              (change)="setPublish($event.checked)"
              >Publish&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div
    mat-dialog-actions
    id="block_container_contactDetails3"
    *ngIf="action != 'Delete'; else elseTemplateCDiv"
  >
    <div class="col-md-6"></div>
    <div id="bloc_contact_detail_3" class="col-md-2">
      <button
        mat-button
        (click)="delete()"
        [disabled]="action != 'Update'"
        color="archive"
      >
        <mat-icon color="archive">delete_outline</mat-icon> Delete
      </button>
    </div>
    <div id="bloc_contact_detail_3" class="col-md-2">
      <button mat-stroked-button (click)="closeDialog()" color="accent">
        Cancel
      </button>
    </div>
    <div id="bloc_contact_detail_3" class="col-md-2">
      <button
        mat-button
        (click)="doAction()"
        [disabled]="!formGroup.valid"
        mat-flat-button
        color="warn"
      >
        Save
      </button>
    </div>
  </div>
  <div
    mat-dialog-actions
    class="right-align"
    #elseTemplateCDiv
    *ngIf="action == 'Delete'; else TemplateCDiv"
  >
    <button mat-button (click)="doAction()" mat-flat-button color="warn">
      {{ action }}
    </button>
    <button mat-stroked-button (click)="closeDialog()" color="accent">
      Cancel
    </button>
  </div>
</form>

import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PathwaysMaterialModule } from './modules/material-module';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { HeaderService } from './shared/api/header.service';
import { InstitutionPopupComponent } from './components/institution-popup/institution-popup.component';
import { InstitutionProgramDetailsComponent } from './components/institution-program-details/institution-program-details.component';
import { PathwaysDetailsComponent } from './components/pathways-details/pathways-details.component';
import { PathwaysInboundProgramsComponent } from './components/pathways-inbound-programs/pathways-inbound-programs.component';
import { PathwaysListComponent } from './components/pathways-list/pathways-list.component';
import { PathwaysPathwayDetailsComponent } from './components/pathways-pathway-details/pathways-pathway-details.component';
import { InstitutionListComponent } from './components/institution-list/institution-list.component';
import { InstitutionEmailComponent } from './components/institution-email/institution-email.component';
import { InstitutionDetailsComponent } from './components/institution-details/institution-details.component';
import { InstitutionContactsComponent } from './components/institution-contacts/institution-contacts.component';
import { FileContentComponent } from './components/file-content/file-content.component';
import { DtProgramDetailsComponent } from './components/dt-program-details/dt-program-details.component';
import { DtProgramComponent } from './components/dt-program/dt-program.component';
import { DtPathwayDetailsComponent } from './components/dt-pathway-details/dt-pathway-details.component';
import { DtInstitutionPopupComponent } from './components/dt-institution-popup/dt-institution-popup.component';
import { DtInstitutionListComponent } from './components/dt-institution-list/dt-institution-list.component';
import { DtInstitutionDetailsComponent } from './components/dt-institution-details/dt-institution-details.component';
import { DtInstitutionContentComponent } from './components/dt-institution-content/dt-institution-content.component';
import { DtInstitutionContactsComponent } from './components/dt-institution-contacts/dt-institution-contacts.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  InstitutionService,
  CustomValidators,
} from './services/institution-service';
import { BnNgIdleService } from 'bn-ng-idle';
import { QuillModule } from 'ngx-quill';
import {
  DTCustomValidators,
  DegreeTransferService,
} from './services/degree-transfer.service';
import { HidehtmlPipe } from './hidehtml.pipe';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PrintLayoutComponent } from './components/print-layout/print-layout.component';

export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(
    environment.apiConfig.uri,
    environment.apiConfig.scopes
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes],
    },
    loginFailedRoute: '/login-failed',
  };
}

@NgModule({
  declarations: [
    AppComponent,
    InstitutionPopupComponent,
    InstitutionProgramDetailsComponent,
    PathwaysDetailsComponent,
    PathwaysInboundProgramsComponent,
    PathwaysListComponent,
    PathwaysPathwayDetailsComponent,
    InstitutionListComponent,
    InstitutionEmailComponent,
    InstitutionDetailsComponent,
    InstitutionContactsComponent,
    FileContentComponent,
    DtProgramDetailsComponent,
    DtProgramComponent,
    DtPathwayDetailsComponent,
    DtInstitutionPopupComponent,
    DtInstitutionListComponent,
    DtInstitutionDetailsComponent,
    DtInstitutionContentComponent,
    DtInstitutionContactsComponent,
    HidehtmlPipe,
    PrintLayoutComponent,
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule, // Animations cause delay which interfere with E2E tests
    AppRoutingModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    HttpClientModule,
    MsalModule,
    MatDialogModule,
    PathwaysMaterialModule,
    FormsModule,
    NgxExtendedPdfViewerModule,
    ReactiveFormsModule,
    AngularEditorModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link'],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
        ],
      },
    }),
  ],
  providers: [
    BnNgIdleService,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    CustomValidators,
    InstitutionService,
    DTCustomValidators,
    DegreeTransferService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderService, multi: true },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}

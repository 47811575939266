import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PrintService } from '../../services/print.service';
import {
  DTInstitution,
  DegreeTransferService,
  DTCustomValidators,
  DTContact,
  DTInstitutionDetails,
  DTPathway,
  DTProgram
} from 'src/app/services/degree-transfer.service';
import { map } from 'rxjs';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.css']
})
export class PrintLayoutComponent implements OnInit {
  isPrinting: Boolean =false;
  institutionId: number | null = null;
  contacts: DTContact[] = [];
  institutionName = '';
  private subscriptions: Subscription = new Subscription();
  institutionDetails: DTInstitutionDetails[] = [];
  programs: DTPathway[] = [];

  constructor(
    private printService: PrintService,
    private degreeTransferService: DegreeTransferService,
    private cdr: ChangeDetectorRef,
  ) { 
    this.printService.currentInstitutionId.subscribe(id => {
      if (id !== null) {
        this.degreeTransferService.getInstitutionList()
        .subscribe({
          next: (institutions) => {
            this.institutionName = institutions.filter(inst => inst.institution_id === id)[0].institution_name;
          },
          error: (error) => console.error('Error:', error)
        });

        this.degreeTransferService.getInstitutionDetails(id.toString())
        .subscribe({
          next: (result) => {
            this.institutionDetails = result;
          },
          error: (error) => console.error('Error:', error)
        });

    
        this.degreeTransferService.getContactListFromInstitution(id.toString())
        .subscribe({
          next: (result) => {
            this.contacts = result;
          },
          error: (error) => console.error('Error:', error)
        });

        this.degreeTransferService.getPathwaysForInstitution(id.toString())
        .subscribe({
          next: (result) => {
            this.programs = result;
          },
          error: (error) => console.error('Error:', error)
        });
      }
    });
  }

  ngOnInit() {
   
    this.subscriptions.add(this.printService.isPrinting$.subscribe(isPrinting => {
      this.isPrinting = isPrinting;
      this.cdr.markForCheck(); // Manually mark for check
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe(); // Clean up subscriptions
  }
}

<div *ngIf="userName" class="div-padding-left">
  <div id="block_container_inst_search">
    <div id="bloc1_search" class="col-md-7">
      <h3>DEGREE TRANSFER PROGRAMS</h3>
    </div>
    <div id="bloc2_search" class="col-md-5">
      <button
        mat-button
        (click)="openDialog('Add', {}, $event)"
        mat-flat-button
        color="warn"
      >
        + New Degree Transfer Program
      </button>
    </div>
  </div>
  <div id="block_container_inst_search">
    <div id="bloc2_search" class="col-md-4 offset-8">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search" />
      </mat-form-field>
    </div>
  </div>
  <div class="list-container mat-elevation-z8">
    <mat-table class="mat-table" #table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="program_code">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Program Code</mat-header-cell
        >
        <mat-cell *matCellDef="let element">
          {{ element.program_code }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="program_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Program Name</mat-header-cell
        >
        <mat-cell *matCellDef="let element">
          {{ element.program_name }}
        </mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="delete">
        <mat-header-cell *matHeaderCellDef>Delete</mat-header-cell>
        <mat-cell *cdkCellDef="let element">
          <mat-icon
            (click)="openDialog('Delete', element, $event)"
            class="cursor_point"
            >delete_outline</mat-icon
          >
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="openDialog('Update', row, $event)"
      ></mat-row>
    </mat-table>
    <mat-paginator
      [length]="dataSource.data.length"
      [pageSize]="20"
      [pageSizeOptions]="[10, 20, 40, 80]"
    ></mat-paginator>
  </div>
</div>

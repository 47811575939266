<div>
  Sure to {{ action }} <b>{{ local_data.name }} </b>?
</div>

<div
  mat-dialog-actions
  class="right-align-2"
  #elseTemplatePDiv
  *ngIf="action == 'Delete' || action == 'Archive' || action == 'reactivate'"
>
  <button mat-button (click)="doAction()" mat-flat-button color="warn">
    {{ action }}
  </button>
  <button mat-stroked-button (click)="closeDialog()" color="accent">
    Cancel
  </button>
</div>

import { environment } from '../../environments/environment';

/**
 * Model for MSAL Returned Information (Not used for implementation)
 * Only use this model in the auth service for transforming
 * the Msal keys into the readable IUser interface
 */
export interface IMSALInfo {
  oid: string;
  family_name: string;
  given_name: string;
  verified_primary_email: string[];
  name: string;
  preferred_username: string;
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  type: UserType;
}

export interface IUser2 {
  id: string;
  name: string;
  email: string;
  type: UserType;
}

export interface IAdminUser extends IUser {
  permission: string;
}

export interface IAdmin {
  email: IUser['email'];
  permission: IAdminUser['permission'];
}

export interface IMsalStoredToken {
  accessToken: string;
  idToken: string;
  expiresIn: string;
  clientInfo: string;
}

export enum PermissionList {
  SUPERADMIN = 'Super Admin',
  FACULTYADMIN = 'Faculty Admin',
}

export enum UserType {
  ADMIN = 'admin',
  STUDENT = 'student',
  NULL = '',
}

export const EmailDomainType = {
  ADMIN: environment.adminEmailDomain,
  STUDENT: environment.studentEmailDomain,
};

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DTInstitution } from 'src/app/services/degree-transfer.service';

@Component({
  selector: 'app-dt-institution-popup',
  templateUrl: './dt-institution-popup.component.html',
  styleUrls: ['./dt-institution-popup.component.scss'],
})
export class DtInstitutionPopupComponent implements OnInit {
  action: string;
  local_data: any;
  constructor(
    public dialogRef: MatDialogRef<DtInstitutionPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DTInstitution,
    private router: Router
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  ngOnInit() {}

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}

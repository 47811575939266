import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { ExportToCsv } from 'export-to-csv';
import { AuthService } from 'src/app/auth_generic/auth.service';
import {
  Pathway,
  InstitutionService,
} from 'src/app/services/institution-service';
import { Title } from '@angular/platform-browser'; 

@Component({
  selector: 'app-pathways-list',
  templateUrl: './pathways-list.component.html',
  styleUrls: ['./pathways-list.component.scss'],
})
export class PathwaysListComponent implements OnInit {
  displayedColumns = [
    'name',
    'title',
    'status',
    'credits_granted',
    'isVisible',
    'isPublished',
  ];
  dataSource = new MatTableDataSource(initialData1);
  tableStart = sessionStorage.getItem('startP');
  tableActive = sessionStorage.getItem('activeP');
  tableDirection = sessionStorage.getItem('directionP');
  tablePageSize = sessionStorage.getItem('pageSizeP');

  @ViewChild(MatTable, { static: true }) table: MatTable<any> | undefined;
  // @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  // @ViewChild(MatSort,{static:true}) sort: MatSort;
  private sort: MatSort | null = null;
  private paginator: MatPaginator | null = null;

  //  userName: string = localStorage.getItem('user');
  userName: string | null = sessionStorage.getItem('user');
  userLogin = sessionStorage.getItem('userLogin');

  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    setTimeout(() => (this.dataSource.sort = this.sort));
  }

  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    mp: MatPaginator
  ) {
    this.paginator = mp;
    setTimeout(() => (this.dataSource.paginator = this.paginator));
  }
  exportContent: any[] = [];
  contents: Pathway[] = [];
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router,
    private institutionService: InstitutionService,
    private authService: AuthService,
    private titleService: Title 
  ) {
    if (!this.userName || this.userName.length < 1) {
      if (!this.userLogin) {
        this.authService.login();
      }
    }
  }

  ngOnInit() {
    if (!this.userName || this.userName.length < 1) {
    } else {
      if (this.userName.length < 1) {
      }

      this.institutionService.getPathwayList().subscribe(
        (res: any) => {
          this.contents = res;
          this.dataSource = new MatTableDataSource(res);
          if (this.paginator) {
            this.dataSource.paginator = this.paginator;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
    this.titleService.setTitle('SenecaPathwaysWeb');
  }

  selectedTabIndex: number = 0;
  selectedIndexChange(index: number) {
    if (index == 0) {
      this.dataSource.data = this.contents;
    } else if (index == 1) {
      var data = this.contents.filter((x) => x.status != '0');
      this.dataSource.data = data;
    } else if (index == 2) {
      var data = this.contents.filter((x) => x.status == '0');
      this.dataSource.data = data;
    }
  }

  // tabClick(event: MouseEvent) {
  //   let el = event.srcElement;
  //   const attr = el.attributes.getNamedItem('class');
  //   if (attr.value.indexOf('mat-tab-label-content') >= 0) {
  //     el = el.parentElement;
  //   }
  //   const tabIndex = el.id.substring(el.id.length - 1);
  //   if (parseInt(tabIndex) === this.selectedTabIndex) {
  //     // active tab clicked
  //     console.log(tabIndex);
  //   }
  // }

  /**
   * Set the paginator and sort after the view init since this component will
   * be able to query its view for the initialized paginator and sort.
   */
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    // setTimeout(() => this.dataSource.paginator = this.paginator);
    // this.dataSource.sort = this.sort;

    this.institutionService.getPathwayList().subscribe(
      (res: any) => {
        this.contents = res;
        this.dataSource = new MatTableDataSource(res);
        if (this.sort) {
          if (this.tableStart) {
            this.sort.start = this.tableStart == 'asc' ? 'asc' : 'desc';
          }
          if (this.tableActive) {
            this.sort.active = this.tableActive;
          }
          if (this.tableDirection) {
            this.sort.direction = this.tableDirection == 'asc' ? 'asc' : 'desc';
          }
          this.dataSource.sort = this.sort;
        }
        if (this.paginator) {
          if (this.tablePageSize) {
            this.paginator.pageSize = +this.tablePageSize;
          }
          this.dataSource.paginator = this.paginator;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  applyFilter($event: any) {
    var filterValue = $event.target.value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  addPathway(): void {
    const navigationDetails: string = '/PathwayDetails';
    var data = { action: 'Add' };
    this.router.navigateByUrl(navigationDetails, { state: data });
    // const navigationDetails: string[] = ['/InstitutionDetails'];
    // this.router.navigate(navigationDetails);
  }

  updatePathway(e: Event, data: Pathway): void {
    e.stopPropagation();
    const clickedElement = e.target as HTMLButtonElement;
    if (clickedElement.className.includes('mat-icon')) {
      return;
    }
    const navigationDetails: string = '/PathwayDetails';
    data.action = 'Update';
    this.router.navigateByUrl(navigationDetails, { state: data });
  }

  addPathwayList(): void {
    if (this.sort && this.paginator) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      sessionStorage.setItem('startP', this.sort.start);
      sessionStorage.setItem('activeP', this.sort.active);
      sessionStorage.setItem('directionP', this.sort.direction);
      sessionStorage.setItem('pageSizeP', this.paginator.pageSize.toString());
    }
  }

  reportToExcel(): void {
    this.institutionService.getlistPathwaysTrackingReporting().subscribe(
      (res: any) => {
        this.exportContent = res;
        const options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          //showTitle: true,
        //  title: 'tracking reporting CSV',
          useTextFile: false,
          useBom: true,
          // useKeysAsHeaders: true,
          headers: [
            'Sending_institution',
            'Sending_program',
            'MCU_Code',
            'Receiving_Program',
            'Entry_Semester',
            'Number_of_TCs',
            'Semester_Start_Option',
            'Pathway_Notes',
            'isPublished'
          ], //<-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(this.exportContent);
      },
      (err) => {
        console.log(err);
      }
    );
  }
}

const initialData1: Pathway[] = [];

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

export interface Institution {
  name: string; // Partner_Institution_Name
  id: number; // Institution_Id
  status: string; // Conact_Phone
  // contact_name: string;//Conact_Name
  // contact_email: string;//Conact_Email
  country: string; // Country
  province_state: string; // Province_State
  notes: string;
  modified_by: string;
  modified_date: Date;
  contacts: InstitutionContact[];
  contacts_deleted: number[];
  programs: InstitutionProgram[];
  program_deleted: number[];
  program_archived: number[];
  action: string;
}

export interface InstitutionContact {
  contact_first_name: string; // Conact_Name
  contact_last_name: string;
  contact_phone: string; // Conact_Phone
  contact_phone_ext: string;
  contact_email: string; // Conact_Email
  institution_id: number;
  contact_id: number;
  contact_notes: string;
  notify_on_change: string;
  modified_by: string;
  modified_date: Date;
  action: string;
}

export interface InstitutionProgram {
  program_name: string;
  program_id: number;
  program_code: string;
  mcu_code: string;
  program_status: string;
  // program_completed: string;
  institution_id: number;
  program_cred: string;
  modified_by: string;
  modified_date: Date;
  action: string;
  selected?: boolean;
}

export interface CredentialLevel {
  cred_code: string;
  cred_desc: string;
}

export interface Country {
  Country: string;
  Descr: string;
}

export interface State {
  Country: string;
  State: string;
  Descr: string;
}

export interface Email {
  email_from: string;
  email_to: string;
  email_subject: string;
  email_message: string;
  From_Bcc: string;
}

// tslint:disable-next-line:class-name
export interface ContactEmail_Notify {
  contact_email: string;
  instituionId: string;
  notify: string;
}

// tslint:disable-next-line:class-name
export interface Admin_Access {
  loginId: string;
  accessLevel: string;
}

export interface Pathway {
  name: string; // program_name
  semester_start: string;
  id: number;
  status: string;
  title: string;
  gpa: string;
  cred_level: string;
  cred_awarded: string;
  type: string;
  credits_granted: string;
  notes: string;
  modified_by: string;
  modified_date: Date;
  isPublished: boolean;
  isVisible: boolean;
  publish_date: Date;
  program_completed?: string;
  total_inbound?: number;
  pathwayDetails: PathwayDetails;
  // contacts:InstitutionContact[];
  // contacts_deleted: number[];
  inbound_programs: InboundProgram[];
  inbound_program_deleted: number[];
  // program_archived: number[];
  action: string;
}

export interface PathwayDetails {
  pathway_Id: string;
  eligibility: string;
  transfer_credit: string;
  time_to_complete: string;
  seneca_credits_to_complete: string;
  how_to_apply: string;
  additionalInfo: string;
  contactInformation: string;
  entry_semester_jan: string;
  entry_semester_may: string;
  entry_semester_sep: string;
  modified_by: string;
  modified_date: Date;
}

export interface InboundProgram {
  pathway_id: number;
  program_name: string;
  program_id: number;
  program_code: string;
  mcu_code: string;
  program_status: string;
  program_completed: string;
  institution_id: number;
  institution_name: string;
  program_cred: string;
  modified_by: string;
  modified_date: Date;
  action: string;
  program_cred_desc: string;
  seneca_cred: string;
  seneca_cred_desc: string;
  seneca_program: string;
  seneca_program_name: string;
}

export interface PathwayFile {
  fileId: number;
  pathwayId: number;
  fileName: string;
  fileContent: any;
  fileStatus: string;
  entry_semester_jan: string;
  entry_semester_may: string;
  entry_semester_sep: string;
}

@Injectable({
  providedIn: 'root',
})
export class InstitutionService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
    }),
  };

  getlistUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPartnerInstitution +
    'getinstitutionlist/'; //'listInstitutions';
  addInstitutionUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPartnerInstitution +
    'addInstitution/';
  updateInstitutionUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPartnerInstitution +
    'updateInstitution/';
  deleteInstitutionUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPartnerInstitution +
    'deleteInstitution/';
  archiveInstitutionUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPartnerInstitution +
    'archiveInstitution/';
  reactiveInstitutionUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPartnerInstitution +
    'reactiveInstitution/';
  getContactslistUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPartnerInstitution +
    'listInstitutionContacts';
  getProgramslistUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPartnerInstitution +
    'listInstitutionProgrms';
  getInstitutionCredentialUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPartnerInstitution +
    'listInstitutionCredentials';
  getCountryUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPartnerInstitution +
    'listCountries'; // 'http://mw-dev.dcm.senecacollege.ca:8011/peoplesoft/qasV2/call-qas-ws?PsQueryName=S3_IWA_COUNTRIES&qasTarget=CSDEV';
  getStatesUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPartnerInstitution +
    'listStates';
  sendEmailContactsCoUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPartnerInstitution +
    'emailContacts/';
  getNotifyContactEmailsUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPartnerInstitution +
    'listInstitutionNotifyEmails';
  getListAdminAccessUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPartnerInstitution +
    'listAdminAccess';
  getlistPathwaysTrackingReportingUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPathway +
    'listPathwaysTrackingReporting';
  getPathwaylistUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPathway +
    'listPathways';
  addPathwayUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPathway +
    'addPathway/';
  updatePathwayUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPathway +
    'updatePathway/';
  deletePathwayUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPathway +
    'deletePathway/';
  archivePathwayUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPathway +
    'archivePathway/';
  reactivePathwayUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPathway +
    'reactivePathway/';
  getPathwayDetailsUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPathway +
    'listPathwayDetails';
  getInboundProgramsListUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPathway +
    'listInboundProgrms';
  getPathwayInboundProgramsListUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPathway +
    'listPathwayInboundProgrms';
  uploadUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPathway +
    'upload';
  getPathwayFilesUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPathway +
    'listPathwayFiles';
  archivePathwayFileUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPathway +
    'archivePathwayFile/';
  deletePathwayFileUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPathway +
    'deletePathwayFile/';
  getPathwayFileContentUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPathway +
    'getPathwayFileContent';
  updatePathwayFileUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerPathway +
    'updatePathwayFile/';

  constructor(private http: HttpClient) {}
  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      // return of(result as T);
      var errorMessage = '';
      if (operation.indexOf('get') > -1) {
        // get api catch error
        errorMessage =
          'ERROR - API service ' +
          operation +
          ' was unable to get requested data';
      } else {
        // post api catch error
        errorMessage =
          'ERROR - Unfortunately data you have sent  may have not be saved -- ' +
          operation;
      }
      // errorMessage = result[0];
      // var errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      window.alert(errorMessage);
      throw new Error(errorMessage);
    };
  }

  changeFile(file: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  postFile(fileToUpload: File, pathwayId: string): Observable<any> {
    const endpoint = this.uploadUrl;
    const formData: FormData = new FormData();

    let fName = fileToUpload.name;
    let i = fName.lastIndexOf('.');
    let resContentType = '';
    let fileType = fName.substring(i + 1);
    if (fileType === 'jpg' || fileType === 'jpeg') {
      resContentType = 'image/jpeg';
    } else if (fileType === 'png') {
      resContentType = 'image/png';
    } else if (fileType === 'tif' || fileType === 'tiff') {
      resContentType = 'image/tiff';
    } else if (fileType === 'gif') {
      resContentType = 'image/gif';
    } else if (fileType === 'pdf') {
      resContentType = 'application/pdf';
    }

    var fileBlob = new Blob([fileToUpload], { type: resContentType });

    formData.append('file', fileBlob, fileToUpload.name);
    formData.append('pathwayId', pathwayId);
    return this.http
      .post(endpoint, formData) // {fileConent:fileContent, pathwayId: pathwayId} )//formData)//{formData,pathwayId})
      .pipe(
        // tap(product => console.log('upload')),
        catchError(this.handleError('upload', []))
      );
  }

  getPathwayFileContent(fileId: string, fileType: string): Observable<any> {
    let resContentType = 'application/octet-stream';
    if (fileType) {
      if (fileType === 'jpg' || fileType === 'jpeg') {
        resContentType = 'image/jpeg';
      } else if (fileType === 'png') {
        resContentType = 'image/png';
      } else if (fileType === 'tif' || fileType === 'tiff') {
        resContentType = 'image/tiff';
      } else if (fileType === 'gif') {
        resContentType = 'image/gif';
      } else if (fileType === 'pdf') {
        resContentType = 'application/pdf';
        // res.set('Content-Type', 'application/octet-stream');
      }
      // res.set('Content-Type', resContentType);
    }
    return this.http
      .get(
        this.getPathwayFileContentUrl +
          '/id/' +
          fileId +
          '/fileType/' +
          fileType,
        { headers: { Accept: resContentType }, responseType: 'blob' }
      ) // as 'json'
      .pipe(
        // tap(product => console.log('fetched PathwayDetailsDB')),
        catchError(this.handleError('getPathwayDetails', []))
      );
  }

  getPathwayFiles(pathwayId: string): Observable<any> {
    return this.http
      .get<any>(this.getPathwayFilesUrl + '/id/' + pathwayId) // ,this.httpOptions)
      .pipe(
        // tap(product => console.log('fetched PathwayDetailsDB')),
        catchError(this.handleError('getPathwayDetails', []))
      );
  }

  getPathwayList(): Observable<any> {
    return this.http
      .get<any>(this.getPathwaylistUrl) // ,this.httpOptions)
      .pipe(
        // tap(product => console.log('fetched PathwaysDB')),
        catchError(this.handleError('getPathwaysList', []))
      );
  }

  getlistPathwaysTrackingReporting(): Observable<any> {
    return this.http
      .get<any>(this.getlistPathwaysTrackingReportingUrl) // ,this.httpOptions)
      .pipe(
        // tap(product => console.log('fetched PathwaysDB')),
        catchError(this.handleError('getPathwaysList', []))
      );
  }

  getPathwayDetails(pathwayId: string): Observable<any> {
    return this.http
      .get<any>(this.getPathwayDetailsUrl + '/id/' + pathwayId) // ,this.httpOptions)
      .pipe(
        // tap(product => console.log('fetched PathwayDetailsDB')),
        catchError(this.handleError('getPathwayDetails', []))
      );
  }

  getCountryList(): Observable<any> {
    return this.http
      .get<any>(this.getCountryUrl) // ,this.httpOptions)
      .pipe(
        // tap(product => console.log('fetched CountryDB')),
        catchError(this.handleError('getCountryList', []))
      );
  }

  getStatesList(): Observable<any> {
    return this.http.get<any>(this.getStatesUrl).pipe(
      // tap(product => console.log('fetched StatesDB')),
      catchError(this.handleError('getStatesList', []))
    );
  }

  getList(): Observable<Institution[]> {
    return this.http.get<Institution[]>(this.getlistUrl).pipe(
      // tap(product => console.log('fetched InstitutionDB')),
      catchError(this.handleError('getList', []))
    );
  }

  getContactsList(): Observable<InstitutionContact[]> {
    return this.http
      .get<InstitutionContact[]>(this.getContactslistUrl) // + '/' + id)
      .pipe(
        // tap(product => console.log('fetched InstitutionContactsDB')),
        catchError(this.handleError('getContactList', []))
      );
  }

  getNotifyContactEmailsList(): Observable<ContactEmail_Notify[]> {
    return this.http
      .get<ContactEmail_Notify[]>(this.getNotifyContactEmailsUrl)
      .pipe(
        // tap(product => console.log('fetched InstitutionContactNotifyEmailDB')),
        catchError(this.handleError('getContactNotifyEmailList', []))
      );
  }

  getListAdminAccess(): Observable<Admin_Access[]> {
    return this.http
      .get<Admin_Access[]>(this.getListAdminAccessUrl)
      .pipe
      // catchError(this.handleError('getContactNotifyEmailList', []))
      ();
  }

  getInstitutionCredentialsList(): Observable<InstitutionContact[]> {
    return this.http
      .get<InstitutionContact[]>(this.getInstitutionCredentialUrl)
      .pipe(
        // tap(product => console.log('fetched InstitutionCredentialDB')),
        catchError(this.handleError('getInstitutionCredentialsList', []))
      );
  }

  getProgramsList(): Observable<InstitutionProgram[]> {
    return this.http.get<InstitutionProgram[]>(this.getProgramslistUrl).pipe(
      // tap(product => console.log('fetched InstitutionProgramsDB')),
      catchError(this.handleError('getPrograList', []))
    );
  }

  /** POST: add a new Instituion to the database */
  addInstitution(inst: Institution): Observable<Institution> {
    return this.http
      .post<Institution>(this.addInstitutionUrl, inst)
      .pipe(catchError(this.handleError('addInstituion', inst)));
  }

  updateInstitution(inst: Institution): Observable<Institution> {
    console.log('This is HTTP POST');
    console.log(inst);
    return this.http
      .post<Institution>(this.updateInstitutionUrl, inst)
      .pipe(catchError(this.handleError('updateInstituion', inst)));
  }

  deleteInstitution(inst: Institution): Observable<Institution> {
    return this.http
      .post<Institution>(this.deleteInstitutionUrl, inst)
      .pipe(catchError(this.handleError('deleteInstituion', inst)));
  }

  archiveInstitution(inst: Institution): Observable<Institution> {
    return this.http
      .post<Institution>(this.archiveInstitutionUrl, inst)
      .pipe(catchError(this.handleError('archiveInstituion', inst)));
  }

  reactiveInstitution(inst: Institution): Observable<Institution> {
    return this.http
      .post<Institution>(this.reactiveInstitutionUrl, inst)
      .pipe(catchError(this.handleError('archiveInstituion', inst)));
  }

  sendEmailContacts(em: Email): Observable<Email> {
    return this.http
      .post<Email>(this.sendEmailContactsCoUrl, em)
      .pipe(catchError(this.handleError('sendEmail', em)));
  }

  /** POST: add a new Pathway to the database */
  addPathway(inst: Pathway): Observable<Pathway> {
    console.log('addPathway URL: ' + this.addPathwayUrl);
    return this.http
      .post<Pathway>(this.addPathwayUrl, inst)
      .pipe(catchError(this.handleError('addPathway', inst)));
  }

  updatePathway(inst: Pathway): Observable<Pathway> {
    console.log('updatePathway URL: ' + this.updatePathwayUrl);
    return this.http
      .post<Pathway>(this.updatePathwayUrl, inst)
      .pipe(catchError(this.handleError('updatePathway', inst)));
  }

  deletePathway(inst: Pathway): Observable<Pathway> {
    return this.http
      .post<Pathway>(this.deletePathwayUrl, inst)
      .pipe(catchError(this.handleError('deletePathway', inst)));
  }

  archivePathway(inst: Pathway): Observable<Pathway> {
    return this.http
      .post<Pathway>(this.archivePathwayUrl, inst)
      .pipe(catchError(this.handleError('archivePathway', inst)));
  }

  reactivePathway(inst: Pathway): Observable<Pathway> {
    return this.http
      .post<Pathway>(this.reactivePathwayUrl, inst)
      .pipe(catchError(this.handleError('reactivePathway', inst)));
  }

  deletePathwayFile(file: PathwayFile): Observable<PathwayFile> {
    return this.http
      .post<PathwayFile>(this.deletePathwayFileUrl, file)
      .pipe(catchError(this.handleError('deletePathwayFile', file)));
  }

  updatePathwayFile(file: PathwayFile): Observable<PathwayFile> {
    return this.http
      .post<PathwayFile>(this.updatePathwayFileUrl, file)
      .pipe(catchError(this.handleError('updatePathwayFile', file)));
  }

  archivePathwayFile(file: PathwayFile): Observable<PathwayFile> {
    return this.http
      .post<PathwayFile>(this.archivePathwayFileUrl, file)
      .pipe(catchError(this.handleError('archivePathwayFile', file)));
  }

  getInboundProgramsList(): Observable<InboundProgram[]> {
    return this.http.get<InboundProgram[]>(this.getInboundProgramsListUrl).pipe(
      // tap(product => console.log('fetched InboundProgramDB')),
      catchError(this.handleError('getInboundProgramList', []))
    );
  }

  getPathwayInboundProgramsList(): Observable<InboundProgram[]> {
    return this.http
      .get<InboundProgram[]>(this.getPathwayInboundProgramsListUrl)
      .pipe(
        // tap(product => console.log('fetched PathwayInboundProgramDB')),
        catchError(this.handleError('getPathwayInboundProgramList', []))
      );
  }
}

export class CustomValidators {
  nameValidator(control: FormControl): { [key: string]: boolean } | null {
    // const nameRegexp: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    const nameRegexp: RegExp = /[\u0590-\u05FF]+/g;

    if (control.value && nameRegexp.test(control.value)) {
      return { invalidName: true };
    }
    return null;
  }
}

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  InstitutionContact,
  CustomValidators,
} from 'src/app/services/institution-service';

@Component({
  selector: 'app-institution-contacts',
  templateUrl: './institution-contacts.component.html',
  styleUrls: ['./institution-contacts.component.scss'],
})
export class InstitutionContactsComponent implements OnInit {
  public elseTemplateCDiv: any;
  public TemplateCDiv: any;
  formGroup: FormGroup;
  action: string;
  local_data: any;
  myDate = new Date();
  lastModifiedDate: Date | undefined;
  constructor(
    public dialogRef: MatDialogRef<InstitutionContactsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: InstitutionContact,
    private _formBuilder: FormBuilder,
    private customValidators: CustomValidators
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
    //this.myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');

    this.formGroup = this._formBuilder.group({
      // "contact_phone": [null, { validators: [Validators.pattern("/^[+#*\(\)\[\]]*([0-9][ ext+-pw#*\(\)\[\]]*){6,45}$/"),Validators.maxLength(16)],updateOn: "change" }],
      contact_phone: [
        null,
        {
          validators: [
            Validators.pattern('^[0-9]*$'),
            Validators.maxLength(12),
          ],
          updateOn: 'change',
        },
      ],
      contact_phone_ext: [
        null,
        {
          validators: [
            Validators.pattern('^[0-9]*$'),
            Validators.maxLength(10),
          ],
          updateOn: 'change',
        },
      ],
      contact_first_name: [
        null,
        [Validators.maxLength(50), this.customValidators.nameValidator],
      ],
      contact_last_name: [
        null,
        [Validators.maxLength(50), this.customValidators.nameValidator],
      ],
      contact_email: [
        null,
        { validators: [Validators.email], updateOn: 'change' },
      ],
      contact_notes: [
        null,
        [Validators.maxLength(500), this.customValidators.nameValidator],
      ],
    });
  }

  config = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    showToolbar: false,
    sanitize: false,
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  ngOnInit() {
    if (this.local_data.modified_date) {
      this.lastModifiedDate = new Date(this.local_data.modified_date);
      this.lastModifiedDate.setDate(this.lastModifiedDate.getDate() + 1);
    }
  }

  setNotifyChanges(notify: boolean) {
    this.local_data.notify_on_change = notify ? '1' : '0';
  }

  doAction() {
    // var plainText = this.local_data.contact_notes && this.local_data.contact_notes.replace(/<[^>]*>/g, '');
    // this.local_data.contact_notes = plainText;
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }
  delete() {
    this.dialogRef.close({ event: 'Delete', data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}

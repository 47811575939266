// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { commonMsalConfig } from './environment.common';

const appUrl = 'https://academicpathways.senecapolytechnic.ca';
//const apiUrl = 'https://seneapi-apims-dv.azure-api.net/acadpathways';
const apiUrl = 'https://seneapi-apims-pd.azure-api.net/acadpathways';

const clientID = 'd25bbd45-72ec-421c-a9dc-21fa8e548119'; // 'a8f2984a-4717-42dd-9b92-6a2cf4cc4f20';
const azureInstance = commonMsalConfig.azureInstance;
const tenant = 'eb34f74a-58e7-4a8b-9e59-433e4c412757';
const authority = `${azureInstance}/${tenant}`;
const scopes = ['api://' + clientID + '/read'];
// const emailFrom = 'kevin.shi@sencollege.ca';

export const environment = {
  adminEmailDomain: 'senecapolytechnic.ca',
  studentEmailDomain: 'myseneca.ca',
  emailFrom: 'academicpathways.sa@senecacollege.ca',
  production: true,
  apiSubscriptionKeyName: 'acadPath-Apim-Subscription-Key',
  apiSubscriptionKey: '0ae0a82396e64e92852ad7dc6d86d6ba',
  appUrl: appUrl,
  sessionExpiry: 14400,
  msalConfig: {
    auth: {
      clientId: clientID,
      authority: authority,
    },
  },
  apiConfig: {
    scopes: scopes,
    uri: apiUrl,
    apiEndPoint: apiUrl + '/',
    apiEndPointControllerPartnerInstitution:
      'public/controllers/api/partner_institutions/',
    apiEndPointControllerPathway: 'public/controllers/api/pathways/',
    apiEndPointControllerDegreeTransfer:
      'public/controllers/api/degree-transfer/',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { Inject, Injectable } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
// import { Observable } from 'rxjs';
import { environment } from '../../environments/environment'; // ' @environments/environment';
import {
  IUser,
  IUser2,
  UserType,
  EmailDomainType,
  IMsalStoredToken,
} from './auth.models';
import { RedirectRequest } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService
  ) {}

  private userType: UserType = UserType.NULL;

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  logout = () => {
    this.msalService.logoutRedirect();
  };

  accountInfo() {
    return this.msalService.instance.getActiveAccount();
  }

  isAuthenticated() {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  getUserType = (): UserType => {
    return this.userType;
  };

  getCacheAccessToken = (): IMsalStoredToken => {
    const key = {
      authority: environment.msalConfig.auth.authority,
      clientId: environment.msalConfig.auth.clientId,
      scopes: environment.apiConfig.scopes,
      userIdentifier: this.msalService.getLogger(),
    };

    const storageValue = sessionStorage.getItem(JSON.stringify(key));

    return storageValue ? JSON.parse(storageValue) : null;
  };
}

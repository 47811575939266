<form [formGroup]="formGroup" class="institution-form">
  <h1 mat-dialog-title>
    <strong>{{ action }} Institution Contact</strong>
  </h1>
  <mat-dialog-content class="mat-typography">
    <div>
      <div *ngIf="action != 'Delete'; else elseTemplateC">
        <div id="block_container_contactDetails">
          <div id="bloc_contact_detail_1" class="col-md-6">
            <mat-form-field appearance="fill">
              <mat-label>First Name </mat-label>
              <!-- <mat-hint align="begin"> First Name</mat-hint> -->
              <input
                name="contact_first_name"
                matInput
                placeholder="{{ local_data?.contact_first_name }}"
                [(ngModel)]="local_data.contact_first_name"
                formControlName="contact_first_name"
                [required]="true"
              />
              <mat-error
                *ngIf="
                  formGroup.get('contact_first_name')?.hasError('required')
                "
              >
                name is <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="
                  formGroup.get('contact_first_name')?.hasError('invalidName')
                "
              >
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
          <div id="bloc_contact_detail_1" class="col-md-6">
            <mat-form-field appearance="fill">
              <mat-label>Last Name </mat-label>
              <!-- <mat-hint align="begin"> Last Name</mat-hint> -->
              <input
                name="contact_last_name"
                matInput
                placeholder="{{ local_data?.contact_last_name }}"
                [(ngModel)]="local_data.contact_last_name"
                formControlName="contact_last_name"
                [required]="true"
              />
              <mat-error
                *ngIf="formGroup.get('contact_last_name')?.hasError('required')"
              >
                name is <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="
                  formGroup.get('contact_last_name')?.hasError('invalidName')
                "
              >
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div id="block_container_contactDetails">
          <div id="bloc_contact_detail_1" class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>Contact Phone </mat-label>
              <!-- <mat-hint align="begin"> Contact Phone</mat-hint> -->
              <input
                name="contact_phone"
                matInput
                placeholder="{{ local_data?.contact_phone }}"
                [(ngModel)]="local_data.contact_phone"
                formControlName="contact_phone"
              />
              <mat-error
                *ngIf="formGroup.get('contact_phone')?.hasError('pattern')"
              >
                Please enter a valid phone number
              </mat-error>
            </mat-form-field>
          </div>
          <div id="bloc_contact_detail_1" class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>Phone Extension </mat-label>
              <!-- <mat-hint align="begin"> Contact Phone</mat-hint> -->
              <input
                name="contact_phone_ext"
                matInput
                placeholder="{{ local_data?.contact_phone_ext }}"
                [(ngModel)]="local_data.contact_phone_ext"
                formControlName="contact_phone_ext"
              />
              <mat-error
                *ngIf="formGroup.get('contact_phone_ext')?.hasError('pattern')"
              >
                Please enter a valid phone number extension
              </mat-error>
            </mat-form-field>
          </div>

          <div id="bloc_contact_detail_2" class="col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>Contact Email </mat-label>
              <!-- <mat-hint align="begin"> Contact Email</mat-hint> -->
              <input
                name="contact_email"
                matInput
                placeholder="{{ local_data?.contact_email }}"
                [(ngModel)]="local_data.contact_email"
                formControlName="contact_email"
              />
              <mat-error
                *ngIf="formGroup.get('contact_email')?.hasError('email')"
              >
                Please enter a valid email address
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div id="block_container_contactDetails">
          <div id="bloc_contact_detail_1" class="col-md-6">
            <mat-form-field appearance="fill">
              <mat-label>Notes </mat-label>
              <!-- <mat-hint align="begin"> Notes</mat-hint> -->
              <!-- <angular-editor name='contact_notes' [(ngModel)]="local_data.contact_notes" [config]="config" formControlName="contact_notes"></angular-editor> -->
              <input
                name="contact_notes"
                matInput
                placeholder="{{ local_data?.contact_notes }}"
                [(ngModel)]="local_data.contact_notes"
                formControlName="contact_notes"
              />
              <mat-error
                *ngIf="formGroup.get('contact_notes')?.hasError('invalidName')"
              >
                Special characters not allowed!
              </mat-error>
            </mat-form-field>
          </div>
          <div id="bloc_contact_detail_1" class="col-md-6">
            <mat-checkbox
              name="notify_on_change"
              [checked]="local_data?.notify_on_change"
              (change)="setNotifyChanges($event.checked)"
              >Notify Changes
            </mat-checkbox>
          </div>
        </div>
      </div>
      <div class="lastMod" *ngIf="action != 'Delete'">
        Last modified by: {{ local_data?.modified_by }} on
        {{ lastModifiedDate | date }}
      </div>
      <div>
        <ng-template #elseTemplateC>
          Sure to delete
          <b
            >{{ local_data.contact_first_name }}
            {{ local_data.contact_last_name }}</b
          >?
        </ng-template>
      </div>
    </div>
  </mat-dialog-content>
  <!-- <div class="col-md-6"></div> -->
  <div
    mat-dialog-actions
    id="block_container_contactDetails3"
    #TemplateCDiv
    *ngIf="action != 'Delete'; else elseTemplateCDiv"
  >
    <!-- <button mat-button (click)="delete()" class="right-align " [disabled]="action!='Update'"  color="warn"><mat-icon  color="archive"  >delete_outline</mat-icon>Delete</button> -->
    <div class="col-md-6"></div>
    <div id="bloc_contact_detail_3" class="col-md-2">
      <button
        mat-button
        (click)="delete()"
        [disabled]="action != 'Update'"
        color="archive"
      >
        <mat-icon color="archive">delete_outline</mat-icon> Delete
      </button>
      <!-- <span *ngIf="action=='Update'" class="mat-archive cursor_point" (click)="delete()" ><mat-icon  color="archive"  >delete_outline</mat-icon> Delete</span> -->
    </div>
    <div id="bloc_contact_detail_3" class="col-md-2">
      <button mat-stroked-button (click)="closeDialog()" color="accent">
        Cancel
      </button>
    </div>
    <div id="bloc_contact_detail_3" class="col-md-2">
      <button
        mat-button
        (click)="doAction()"
        [disabled]="!(local_data.contact_first_name && local_data.contact_last_name)"
        mat-flat-button
        color="warn"
      >
        Save
      </button>
    </div>
  </div>

  <div
    mat-dialog-actions
    class="right-align"
    #elseTemplateCDiv
    *ngIf="action == 'Delete'; else TemplateCDiv"
  >
    <button mat-button (click)="doAction()" mat-flat-button color="warn">
      {{ action }}
    </button>
    <button mat-stroked-button (click)="closeDialog()" color="accent">
      Cancel
    </button>
  </div>
</form>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FormControl } from '@angular/forms';

@Injectable()
export class DegreeTransferService {
  // institutions
  getInstitutionListUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'getInstitutionList/';
  getInstitutionByIdUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'getInstitutionById/';
  removeInstitutionUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'removeInstitution/';
  addInstitutionUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'addInstitution/';
  updateInstitutionUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'updateInstitution/';

  // contact
  getContactListFromInstitutionUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'getContactListFromInstitution/';
  updateContactUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'updateContact/';
  addContactUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'addContact/';
  removeContactUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'removeContact/';
  getContactsUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'getContacts/';

  // institution-details
  getInstitutionDetailsUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'getInstitutionDetails/';
  addInstitutionDetailsUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'addInstitutionDetails/';
  updateInstitutionDetailsUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'updateInstitutionDetails/';
  removeInstitutionDetailsUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'removeInstitutionDetails/';

  // program
  addProgramUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'addProgram/';
  removeProgramUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'removeProgram/';
  updateProgramUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'updateProgram/';
  getAllProgramsUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'getAllPrograms/';

  // pathway
  addPathwayUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'addPathway/';
  removePathwayUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'removePathway/';
  updatePathwayUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'updatePathway/';
  getPathwaysForInstitutionUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'getPathwaysForInstitution/';
  getPathwaysForInstitutionPublishedUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'getPathwaysForInstitutionPublished/';
  getPathwaysForInstitutionMastersUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'getPathwaysForInstitutionMasters/';
  getPathwaysForInstitutionMastersPublishedUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'getPathwaysForInstitutionMastersPublished/';
  getPathwaysUrl =
    environment.apiConfig.apiEndPoint +
    environment.apiConfig.apiEndPointControllerDegreeTransfer +
    'getPathways/';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      //This should be removed once was have access to Seneca Azure AD
      'acadPath-Apim-Subscription-Key': '47bdcd70ae234130a0e6867dfbc8c45e',
    }),
  };

  constructor(private http: HttpClient) {}
  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // Let the app keep running by returning an empty result.
      // return of(result as T);
      var errorMessage = '';
      if (operation.indexOf('get') > -1) {
        errorMessage =
          'ERROR - API service ' +
          operation +
          ' was unable to get requested data';
      } else {
        errorMessage =
          'ERROR - Unfortunately data you have sent  may have not be saved -- ' +
          operation;
      }
      window.alert(errorMessage);
      return throwError(errorMessage);
    };
  }

  addContact(contact: DTContact): Observable<DTContact> {
    console.log('front end>' + this.addContactUrl);
    return this.http
      .post<DTContact>(this.addContactUrl, contact)
      .pipe(catchError(this.handleError('addContact', contact)));
  }

  removeContact(contact: DTContact): Observable<DTContact> {
    return this.http
      .post<DTContact>(this.removeContactUrl, contact)
      .pipe(catchError(this.handleError('removeContact', contact)));
  }

  getContacts(): Observable<DTContact[]> {
    return this.http
      .get<DTContact[]>(this.getContactsUrl)
      .pipe(catchError(this.handleError('getContactsUrl', [])));
  }

  getContactListFromInstitution(
    institutionId: string
  ): Observable<DTContact[]> {
    // console.log('instid> ' + institutionId);
    return this.http
      .get<DTContact[]>(
        this.getContactListFromInstitutionUrl + 'id/' + institutionId
      )
      .pipe(catchError(this.handleError('getContactListFromInstitution', [])));
  }

  updateContact(contact: DTContact): Observable<DTContact> {
    console.log('data> ' + JSON.stringify(contact));
    return this.http
      .post<DTContact>(this.updateContactUrl, contact)
      .pipe(catchError(this.handleError('updateContact', contact)));
  }

  getInstitutionList(): Observable<DTInstitution[]> {
    return this.http
      .get<DTInstitution[]>(this.getInstitutionListUrl)
      .pipe(catchError(this.handleError('getInstitutionList', [])));
  }

  getInstitutionById(): Observable<DTInstitution[]> {
    return this.http
      .get<DTInstitution[]>(this.getInstitutionByIdUrl)
      .pipe(catchError(this.handleError('getInstitutionById', [])));
  }

  addInstitution(institution: DTInstitution): Observable<DTInstitution> {
    return this.http
      .post<DTInstitution>(this.addInstitutionUrl, institution)
      .pipe(catchError(this.handleError('addInstitution', institution)));
  }

  removeInstitution(institution: DTInstitution): Observable<DTInstitution> {
    return this.http
      .post<DTInstitution>(this.removeInstitutionUrl, institution)
      .pipe(catchError(this.handleError('removeInstitution', institution)));
  }

  updateInstitution(institution: DTInstitution): Observable<DTInstitution> {
    return this.http
      .post<DTInstitution>(this.updateInstitutionUrl, institution)
      .pipe(catchError(this.handleError('updateInstitution', institution)));
  }

  getInstitutionDetails(
    institutionId: string
  ): Observable<DTInstitutionDetails[]> {
    console.log(
      'this> ' + (this.getInstitutionDetailsUrl + 'id/' + institutionId)
    );
    return this.http
      .get<DTInstitutionDetails[]>(
        this.getInstitutionDetailsUrl + 'id/' + institutionId
      )
      .pipe(catchError(this.handleError('getInstitutionDetails', [])));
  }

  removeInstitutionDetails(
    institutionDetails: DTInstitutionDetails
  ): Observable<DTInstitutionDetails> {
    return this.http
      .post<DTInstitutionDetails>(
        this.removeInstitutionDetailsUrl,
        institutionDetails
      )
      .pipe(
        catchError(
          this.handleError('removeInstitutionDetails', institutionDetails)
        )
      );
  }

  addInstitutionDetails(
    institutionDetails: DTInstitutionDetails
  ): Observable<DTInstitutionDetails> {
    return this.http
      .post<DTInstitutionDetails>(
        this.addInstitutionDetailsUrl,
        institutionDetails
      )
      .pipe(
        catchError(
          this.handleError('addInstitutionDetails', institutionDetails)
        )
      );
  }

  updateInstitutionDetails(
    institutionDetails: DTInstitutionDetails
  ): Observable<DTInstitutionDetails> {
    return this.http
      .post<DTInstitutionDetails>(
        this.updateInstitutionDetailsUrl,
        institutionDetails
      )
      .pipe(
        catchError(
          this.handleError('updateInstitutionDetails', institutionDetails)
        )
      );
  }

  addPathway(pathway: DTPathway): Observable<DTPathway> {
    return this.http
      .post<DTPathway>(this.addPathwayUrl, pathway)
      .pipe(catchError(this.handleError('addPathway', pathway)));
  }

  removePathway(pathway: DTPathway): Observable<DTPathway> {
    return this.http
      .post<DTPathway>(this.removePathwayUrl, pathway)
      .pipe(catchError(this.handleError('removePathway', pathway)));
  }

  updatePathway(pathway: DTPathway): Observable<DTPathway> {
    return this.http
      .post<DTPathway>(this.updatePathwayUrl, pathway)
      .pipe(catchError(this.handleError('updatePathway', pathway)));
  }

  getPathwaysForInstitution(institutionId: string): Observable<DTPathway[]> {
    return this.http
      .get<DTPathway[]>(
        this.getPathwaysForInstitutionUrl + 'id/' + institutionId
      )
      .pipe(catchError(this.handleError('getPathwaysForInstitution', [])));
  }

  getPathwaysForInstitutionPublished(
    pathway: DTPathway
  ): Observable<DTPathway[]> {
    return this.http
      .get<DTPathway[]>(this.getPathwaysForInstitutionPublishedUrl)
      .pipe(
        catchError(this.handleError('getPathwaysForInstitutionPublished', []))
      );
  }

  getPathwaysForInstitutionMasters(
    pathway: DTPathway
  ): Observable<DTPathway[]> {
    return this.http
      .get<DTPathway[]>(this.getPathwaysForInstitutionMastersUrl)
      .pipe(
        catchError(this.handleError('getPathwaysForInstitutionMasters', []))
      );
  }

  getPathwaysForInstitutionMastersPublished(
    pathway: DTPathway
  ): Observable<DTPathway[]> {
    return this.http
      .get<DTPathway[]>(this.getPathwaysForInstitutionMastersPublishedUrl)
      .pipe(
        catchError(
          this.handleError('getPathwaysForInstitutionMastersPublished', [])
        )
      );
  }

  getPathways(): Observable<DTPathway[]> {
    return this.http
      .get<DTPathway[]>(this.getPathwaysUrl)
      .pipe(catchError(this.handleError('getPathways', [])));
  }

  addProgram(program: DTProgram): Observable<DTProgram> {
    return this.http
      .post<DTProgram>(this.addProgramUrl, program)
      .pipe(catchError(this.handleError('addProgram', program)));
  }

  removeProgram(program: DTProgram): Observable<DTProgram> {
    return this.http
      .post<DTProgram>(this.removeProgramUrl, program)
      .pipe(catchError(this.handleError('removeProgram', program)));
  }

  updateProgram(program: DTProgram): Observable<DTProgram> {
    return this.http
      .post<DTProgram>(this.updateProgramUrl, program)
      .pipe(catchError(this.handleError('updateProgram', program)));
  }

  getAllPrograms(): Observable<DTProgram[]> {
    return this.http
      .get<DTProgram[]>(this.getAllProgramsUrl)
      .pipe(catchError(this.handleError('getAllPrograms', [])));
  }
}

export interface DTContact {
  contact_id: string;
  title: string;
  first_name: string;
  last_name: string;
  department: string;
  phone_number: string;
  email: string;
  job_title: string;
  institution_id: string;
  modified_by: string;
  modified_date: Date;
  contact_publish: boolean
}

export interface DTInstitutionDetails {
  institution_detail_id: number;
  institution_id: number;
  heading: string;
  content: string;
  modified_by: string;
  modified_date: Date;
}

export interface DTInstitution {
  institution_id: number;
  institution_name: string;
  institution_location: string;
}

export interface DTPathway {
  institution_id: number;
  pathway_id: number;
  program_id: number;
  program_code: string; // from Degree_Transfer_Program table
  degree_name: string; // from Degree_Transfer_Program table
  program_name: string;
  pathway_description: string;
  publish: boolean;
  modified_by: string;
  modified_date: Date;
  isMasters: boolean;
}

export interface DTProgram {
  program_id: number;
  program_code: string;
  program_name: string;
  modified_by: string;
  modified_date: Date | null;
}

/*export interface Admin_Access {
  loginId: string;
  accessLevel: string;
}*/

export class DTCustomValidators {
  nameValidator(control: FormControl): { [key: string]: boolean } | null {
    const nameRegexp: RegExp = /[\u0590-\u05FF]+/g;

    if (control.value && nameRegexp.test(control.value)) {
      return { invalidName: true };
    }

    return null; // Return null when the condition is not met
  }
}

import { Component, Inject, OnInit, PipeTransform } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl, SafeHtml } from '@angular/platform-browser';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { DialogData } from '../pathways-details/pathways-details.component';
import { ScrollModeType, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-file-content',
  templateUrl: './file-content.component.html',
  styleUrls: ['./file-content.component.scss'],
})
export class FileContentComponent implements OnInit, PipeTransform {
  public pdfDiv: any;
  scrollMode: ScrollModeType | undefined;
  fileId: string | null = null;
  isImageLoading: boolean | null = null;
  pdfUrl: any;
  documentType: string | null = null;
  urlTrust: any;
  selectedFileBLOB: any;

  constructor(
    public dialogRef: MatDialogRef<FileContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private sanitizer: DomSanitizer
  ) {}

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  sanitizeImageHtml(imageUrl: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(imageUrl);
  }
  transform(value: any, ...args: any[]) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  ngOnInit() {
    console.log('FileContentComponent ngOnInit()');
    if (this.data.fileType !== 'pdf') {
      this.transform(this.data.fileContent);
    } else {
      this.documentType = FileMimeType.PDF;
    }
  }
}
